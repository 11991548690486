<template>
  <AppPage>
    <AppPageHeader :backlink-route="{ name: 'wc-assets-upload' }" backlink-text="Back to Upload Assets">Review assets</AppPageHeader>
    <AppPageContent>
      <ReviewAssetUploadViewForm @show-uploaded-assets="handleShowUploadedAssets" @show-asset-upload="handleShowAssetUpload" />
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import ReviewAssetUploadViewForm from "./ReviewAssetUploadViewForm.vue"

const router = useRouter()

const handleShowAssetUpload = () => {
  router.push({ name: "wc-assets-upload" })
}
const handleShowUploadedAssets = () => {
  router.push({ name: "wc-assets" })
}
</script>
