<template>
  <form class="flex flex-col items-start gap-2" @submit.prevent="saveAccount()">
    <label>
      Email
      <input v-model="account.contact_info.email" type="email" />
    </label>

    <label>
      Address Line 1
      <input v-model="account.contact_info.street_1" type="text" required />
    </label>

    <label>
      Address Line 2
      <input v-model="account.contact_info.street_2" type="text" />
    </label>

    <label>
      City
      <input v-model="account.contact_info.city" type="text" required />
    </label>

    <label>
      State/Province
      <input v-model="account.contact_info.state_province" type="text" required />
    </label>

    <label>
      Postal Code
      <input v-model="account.contact_info.postal_code" type="text" required />
    </label>

    <label>
      Country
      <input v-model="account.contact_info.country" type="text" />
    </label>

    <WcButton text="Save" type="submit" icon="wc-carbon:save" />
  </form>
</template>

<script setup lang="ts">
import { AccountContactInfo } from "@common/models/models"
import { WcButton } from "@/components/button"
import { computed } from "vue"
import _ from "lodash"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"

const props = defineProps<{
  accountId: number
}>()

const adminAccountsStore = useAdminAccountsStore()

const account = computed(() => {
  const cloned = _.cloneDeep(adminAccountsStore.getAccountById(props.accountId)!)
  if (cloned.contact_info === null) {
    cloned.contact_info = new AccountContactInfo()
  }
  return cloned
})

const saveAccount = async () => {
  await adminAccountsStore.updateAccount(account.value)
}
</script>

<style scoped lang="scss">
input[type="text"] {
  @apply invalid:border-error;
}
</style>
