export type SelectOption<T> = {
  icon?: string
  label: string
  value: T
}

export type SelectOptionGroup<T> = {
  label: string
  items: SelectOption<T>[]
}

export const pickDropdownValue = (options: SelectOption<any>[], value: string, defaultValue?: any) => {
  return options.find((option) => option.value === value) ?? defaultValue ?? options[0]
}
