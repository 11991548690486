export enum AssetTimeSeriesTimeKind {
  start = "start",
  end = "end",
}

export const ASSET_TIMESERIES_TIME_KIND: Record<AssetTimeSeriesTimeKind, string> = {
  start: "Start",
  end: "End",
}

export enum AssetTimeSeriesValueUnit {
  electric_energy_mwh = "electric_energy_mwh",
  electric_energy_kwh = "electric_energy_kwh",
  electric_energy_wh = "electric_energy_wh",

  electric_power_mw = "electric_power_mw",
  electric_power_kw = "electric_power_kw",
  electric_power_w = "electric_power_w",

  methane_gas_thm = "methane_gas_thm",

  fuel_oil_gal = "fuel_oil_gal",
}

export const ASSET_TIMESERIES_VALUE_UNIT: Record<AssetTimeSeriesValueUnit, string> = {
  electric_energy_kwh: "Electric Energy (kWh)",
  electric_energy_mwh: "Electric Energy (MWh)",
  electric_energy_wh: "Electric Energy (Wh)",

  electric_power_mw: "Electric Power (MW)",
  electric_power_kw: "Electric Power (kW)",
  electric_power_w: "Electric Power (W)",

  methane_gas_thm: "Methane Gas (Therms)",

  fuel_oil_gal: "Fuel Oil (Gallons)",
}

export enum AssetTimeSeriesMissingData {
  keep_missing = "keep_missing",
  infer_zeroes = "infer_zeroes",
  error = "error",
}

export const ASSET_TIMESERIES_MISSING_DATA: Record<AssetTimeSeriesMissingData, string> = {
  keep_missing: "Keep missing",
  infer_zeroes: "Infer zeroes",
  error: "Error",
}
