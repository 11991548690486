<template>
  <div>
    <svg width="31" height="37" viewBox="0 0 31 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="idea">
        <g id="bulb">
          <path
            id="Rectangle 5275"
            d="M21 32.5V25.5C21 25.5 20 27.8529 14 27.8529C8 27.8529 7 25.5 7 25.5V32.5H21Z"
            fill="#C0F5FF"
            stroke="#003E4A"
            stroke-linejoin="round" />
          <path id="Ellipse 3450" d="M14 36.5C16.2091 36.5 18 34.7091 18 32.5H10C10 34.7091 11.7909 36.5 14 36.5Z" fill="#C0F5FF" stroke="#003E4A" />
          <circle id="Ellipse 3448" cx="14" cy="14.5" r="13.5" fill="white" stroke="#003E4A" />
          <circle id="Ellipse 3449" cx="14" cy="14.5" r="11" fill="#C0F5FF" />
          <line id="Line 349" x1="11.5" y1="27.5" x2="11.5" y2="15.5" stroke="#003E4A" />
          <line id="Line 350" x1="17.5" y1="27.5" x2="17.5" y2="15.5" stroke="#003E4A" />
          <circle id="Ellipse 3445" cx="10" cy="14.5" r="2.5" fill="#C0F5FF" stroke="#003E4A" />
          <circle id="Ellipse 3446" cx="14.25" cy="14.5" r="2.5" fill="#C0F5FF" stroke="#003E4A" />
          <circle id="Ellipse 3447" cx="18.5" cy="14.5" r="2.5" fill="#C0F5FF" stroke="#003E4A" />
        </g>
        <path
          id="twinkle"
          d="M24.5018 0.457458L24.1689 4.35609L20.3582 3.46797L20.0503 4.41557L23.6552 5.93694L21.633 9.28668L22.4391 9.87233L25 6.91396L27.5609 9.87233L28.367 9.28668L26.3448 5.93694L29.9497 4.41557L29.6418 3.46797L25.8311 4.35609L25.4982 0.457458H24.5018Z"
          fill="#C0F5FF"
          stroke="#003E4A"
          stroke-linejoin="bevel" />
      </g>
    </svg>
  </div>
</template>
