<template>
  <div class="flex flex-col">
    <div class="wc-page-container text-body-2 mt-12 w-full">
      <section class="flex w-full flex-wrap items-start gap-5 md:flex-nowrap">
        <div class="order-2 md:basis-1/2">
          <h2 class="text-heading-4 mb-8 mt-4 md:mb-12 md:mt-0">{{ portfolio.name }}</h2>

          <div class="my-6 md:my-8">
            <h4 class="text-subheading-1">Summary</h4>
            <div class="mt-3">
              <p class="whitespace-pre-line">
                {{ portfolio.longDescription }}
              </p>
            </div>
          </div>

          <div class="w-full rounded-lg bg-lilac-100">
            <div class="p-4">
              <div>
                <div class="flex items-center justify-between gap-4">
                  <div class="text-overline-1 inline-block whitespace-nowrap rounded-lg bg-highlight-yellow px-3 py-1 font-[12px] text-neutral-99">
                    {{ portfolioStatus }}
                  </div>
                  <div class="text-body-3 text-right">
                    <span class="text-body-3-strong">{{ portfolio.quantitySold }} {{ Portfolio.Units(portfolio) }}</span> purchased of
                    {{ portfolio.quantityTotal }}&nbsp;{{ Portfolio.Units(portfolio) }}
                    available
                  </div>
                </div>
                <div class="mt-4 h-4 w-full rounded-lg bg-white">
                  <div
                    :style="{ width: `${(portfolio.quantitySold / portfolio.quantityTotal) * 100}%` }"
                    class="h-full min-w-8 rounded-lg bg-blue-70"></div>
                </div>
              </div>
              <div class="mt-10 flex flex-col items-center gap-8 text-center md:flex-row md:text-left">
                <div>
                  <h4 class="text-overline-1">Total portfolio impact</h4>
                  <div class="mt-3">{{ portfolio.quantityTotal }} {{ Portfolio.Units(portfolio) }}</div>
                </div>
                <div v-if="Portfolio.Units(portfolio) === 'MWh'">
                  <h4 class="text-overline-1">Emissions Impact</h4>
                  <div class="mt-3">{{ Portfolio.EmissionsImpact(portfolio) }}</div>
                </div>
                <div>
                  <h4 class="text-overline-1">Price</h4>
                  <div class="mt-3">{{ Portfolio.PriceFormatted(portfolio) }}/{{ Portfolio.Units(portfolio) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="order-1 basis-full bg-neutral-20 md:order-3 md:basis-1/2">
          <div class="h-[240px] w-full bg-neutral-20 md:aspect-square md:h-auto">
            <img :alt="portfolio.name" class="size-full object-cover" :src="Portfolio.Image(portfolio)" />
          </div>
        </div>
      </section>

      <section class="mb-12 mt-4 grid grid-cols-6 flex-wrap gap-5 md:mt-10 md:grid-cols-12">
        <div v-if="portfolio.locationDescription" class="col-span-2 mt-4">
          <h5 class="text-overline-1">Location</h5>
          <div class="mt-3">{{ portfolio.locationDescription }}</div>
        </div>
        <div class="col-span-2 mt-4">
          <h5 class="text-overline-1">Deployment</h5>
          <div class="mt-3">{{ portfolio.dateRangeDescription }}</div>
        </div>
        <div class="col-span-2 mt-4">
          <h5 class="text-overline-1">Time of day</h5>
          <div class="mt-3">{{ portfolio.hoursDescription }}</div>
        </div>
        <div class="col-span-2 mt-4">
          <h5 class="text-overline-1">GHG Reporting</h5>
          <div class="mt-3">{{ Portfolio.ScopeFormatted(portfolio) }}</div>
        </div>
        <div class="col-span-2 mt-4">
          <h5 class="text-overline-1">Project Category</h5>
          <div class="mt-3">{{ Portfolio.Category(portfolio) }}</div>
        </div>
        <div class="mt-4" :class="portfolio.locationDescription ? 'col-span-2' : 'col-span-4'">
          <h5 class="text-overline-1">Suppliers</h5>
          <div class="mt-3 flex flex-wrap">
            <span v-for="(supplier, index) in portfolio.suppliers" :key="supplier.accountId" class="ph-no-capture mr-1 whitespace-nowrap">
              <router-link
                v-if="supplier.isListedOnMarketplace"
                :to="{ name: 'wc-supplier', params: { id: supplier.accountId, slug: supplier.slug } }"
                class="underline"
                @click="captureSupplierSelectEvent(supplier.displayName)"
                >{{ supplier.displayName }}</router-link
              ><template v-else>{{ supplier.displayName }}</template
              ><template v-if="index + 1 < portfolio.suppliers.length">,</template>
            </span>
          </div>
        </div>
      </section>

      <section class="wc-homepage-grid text-body-2 border-t border-sagetone py-12">
        <div class="col-span-6 md:col-span-9">
          <WcAboutDemandResponse v-if="portfolio.buyerAgreementTemplate === 'demand_response'" />
          <WcAboutHeatPumps v-if="portfolio.buyerAgreementTemplate === 'electrification'" />
          <WcAboutSolar v-if="portfolio.buyerAgreementTemplate === 'renewables'" />
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
// Libraries
import { ref, computed } from "vue"
import { useRoute, useRouter } from "vue-router"
import posthog from "posthog-js"
import { setPageTitle, setPageDescription, setPageImage } from "@/metatags"
// Components
import WcAboutSolar from "@/modules/portfolio/components/WcAboutSolar.vue"
import WcAboutHeatPumps from "@/modules/portfolio/components/WcAboutHeatPumps.vue"
import WcAboutDemandResponse from "@/modules/portfolio/components/WcAboutDemandResponse.vue"

// Models
import { Order, Portfolio } from "@common/models/order"
// Services
import { usePortfolioService } from "@/services/service-container"

const router = useRouter()
const route = useRoute()

// PostHog Events
const captureSupplierSelectEvent = (supplierName: string) => posthog.capture(`Clicked on the ${supplierName} supplier - Portfolio View`)

const portfolioService = usePortfolioService()

const portfolio = ref<Portfolio>(new Portfolio())
const order = ref<Order>(new Order())

const portfolioStatus = computed(() => {
  return portfolio.value.status === "funding" ? "Fund now" : portfolio.value.status
})

try {
  portfolio.value = await portfolioService.getPortfolio(Number(route.params.id))
  order.value = new Order(portfolio.value)
} catch (error: any) {
  if (error?.message === "Portfolio not found") {
    router.push({ name: "wc-404" })
  } else {
    throw new Error(error) // Throw the error anyway, so we can render it in AppLayout >:D
  }
}

setPageTitle(portfolio.value.name)
setPageDescription(portfolio.value.shortDescription)
setPageImage(Portfolio.Image(portfolio.value))
</script>
