<template>
  <AppPage class="w-full">
    <section class="bg-background-sagelight">
      <AppPageHeader :backlink-route="{ name: 'wc-assets' }" backlink-text="Back to Dashboard" show-account-context>
        <span class="text-subheading-large-bold"> {{ assetGroup?.name }}</span>
        <WcButtonIcon
          v-if="assetGroup"
          class="top-0.5 ml-4 inline size-5 shrink-0 cursor-pointer"
          color="green"
          icon="wc-carbon:edit"
          variant="tertiary"
          @click="showEditAssetGroupModal(assetGroup.id)" />
        <template v-if="assetGroup" #title-adjacent>
          <WcCTA icon="wc-carbon:add" text="Add Assets" size="large" weight="heavy" @click="manageAssetGroupAssetsModal.openModal()" />
        </template>
      </AppPageHeader>
      <div class="wc-page-container grid grid-cols-4 pb-16">
        <div class="col-span-1">
          <div class="mb-4 flex items-center">
            <h3 class="text-overline-2 inline-block">Total Assets</h3>
          </div>
          <div>
            <div class="mb-1">
              <span class="text-featured-number pr-2" data-cy="total-active">{{ assetGroup?.deviceIds?.length }}</span>
            </div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="mb-4 flex items-center">
            <h3 class="text-overline-2 inline-block">Total EACs</h3>
          </div>
          <div>
            <div class="mb-1">
              <span class="text-featured-number pr-2" data-cy="total-retired"> X.XX </span>
              <span> million </span>
            </div>
            <div class="text-body-3">X.XX MWh + XX.X tCO2e</div>
          </div>
        </div>
      </div>
    </section>
    <AppPageSection class="wc-page-container text-body-2 py-8">
      <div class="flex flex-wrap items-end justify-between gap-2">
        <div class="flex grow flex-wrap items-center gap-3">
          <WcDropdown
            v-model="filterAssetStatus"
            class="shrink-0 basis-40"
            name="filterAssetStatus"
            :options="statusOptions"
            inset-label="Status"
            size="small" />
          <WcCalendarRange
            v-model="filterAssetCreatedDateRange"
            class="shrink-0 basis-40"
            name="filterAssetCreatedDateRange"
            inset-label="Created date"
            size="small" />
          <WcInputText
            v-model="filterAssetLocationKeyword"
            icon="wc-carbon:search"
            class="shrink-0 basis-48"
            name="filterAssetLocationKeyword"
            inset-label="Filter by location..."
            size="small" />
          <WcCTA
            class="shrink-0 basis-52 pl-1"
            icon-position="right"
            icon="wc-carbon:close-outline"
            text="Clear Filters"
            size="small"
            @click="handleClearFilters" />
        </div>
        <WcViewModeButton v-model="viewMode" class="ml-auto" size="small" />
      </div>
      <div v-if="viewMode === ViewMode.table">
        <div class="mt-4 overflow-x-scroll pb-3">
          <AssetsTable
            :assets="assets"
            allow-multiple-selection
            :selected-row-ids="selectedAssetIds"
            show-price
            @row-clicked="handleRowClicked"
            @rows-selected="handleRowsSelected" />
          <PaginationButtons class="mt-3" :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadAssets" />
        </div>
        <div
          v-if="selectedAssetIds.length > 0"
          class="mt-8 flex h-16 w-full flex-wrap items-center justify-between bg-darktone-lightbackground px-5 py-3">
          <div class="text-body-2 shrink-0">
            <div class="flex items-baseline gap-2">
              <span class="text-subheading-large-bold">{{ selectedAssetIds.length }}</span>
              <span class="text-subheading-1">assets selected</span>
            </div>
          </div>
          <div class="flex items-center gap-2">
            <WcButton text="Cancel" size="medium" variant="secondary" :is-disabled="selectedAssetIds.length === 0" @click="handleClearSelection" />
            <WcButton text="Remove Assets" size="medium" :is-disabled="selectedAssetIds.length === 0" @click="removeSelectedAssets" />
          </div>
        </div>
      </div>
      <div v-if="viewMode === ViewMode.map && assetLocations?.length > 0">
        <div class="relative my-4 size-full h-[600px]">
          <WcMap :locations="assetLocations ?? []" class="mb-8" locations-are-precise>
            <template #popup="asset">
              <AssetPopup :asset="asset" />
            </template>
          </WcMap>
          <PaginationButtons :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadAssets" />
        </div>
      </div>
      <InlineLoading v-if="isLoading" />
      <InlineError v-if="hasError" error-message="There was a problem loading this asset group. Please try again." />
    </AppPageSection>
    <EditAssetGroupModal ref="editAssetGroupModal" @asset-group-updated="handleAssetGroupUpdated" />
    <ManageAssetGroupAssetsModal
      ref="manageAssetGroupAssetsModal"
      :asset-group-id="assetGroupId"
      @asset-group-assets-updated="handleAssetGroupAssetsUpdated" />
  </AppPage>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"
import { useToast } from "vue-toastification"
import { format } from "date-fns"
import type { Asset, AssetGroup, AssetStatus } from "@common/models/asset"
import { ASSET_STATUS } from "@common/models/asset"
import { AppPage, AppPageHeader, AppPageSection } from "@/components/layout"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import { useAssetService } from "@/services/service-container"
import AssetsTable from "./components/AssetsTable.vue"
import type { PageInfo } from "@/services/base-fetcher"
import { WcButton } from "@/components/button"
import WcButtonIcon from "@/components/button/WcButtonIcon.vue"
import { ViewMode, WcCalendarRange, WcDropdown, WcInputText, WcViewModeButton } from "@/components/input"
import type { SelectOption } from "@/components/input"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import { WcCTA } from "@/components/button"
import WcMap from "@/components/WcMap/WcMap.vue"
import { mapAssetsToLocations } from "@/modules/asset/asset.service"
import { debounce } from "@/utils/debounce"
import AssetPopup from "./components/AssetPopup.vue"
import EditAssetGroupModal from "./components/EditAssetGroupModal.vue"
import ManageAssetGroupAssetsModal from "./components/ManageAssetGroupAssetsModal.vue"

const router = useRouter()
const assetService = useAssetService()

const props = defineProps<{ assetGroupId: number }>()

const editAssetGroupModal = ref()
const manageAssetGroupAssetsModal = ref()
const assetGroup = ref<AssetGroup | undefined>()
const assets = ref<Asset[]>([])
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const pageInfo = ref<PageInfo>()
const viewMode = ref<ViewMode>(ViewMode.table)
const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetLocationKeyword = ref<string | null | undefined>(null)
const filterAssetCreatedDateRange = ref<Date[] | null | undefined>(null)
const selectedAssetIds = ref<number[]>([])

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))

const toast = useToast()

onMounted(async () => {
  isLoading.value = false
  loadAssets()
})

const loadAssets = async (url?: string) => {
  try {
    const createdDateStart = (filterAssetCreatedDateRange.value ?? [])[0]
    const createdDateEnd = (filterAssetCreatedDateRange.value ?? [])[1]
    const filters = {
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetLocationKeyword.value && { location: filterAssetLocationKeyword.value }),
      ...(createdDateStart && { createdDateStart: format(createdDateStart, "yyyy-MM-dd") }),
      ...(createdDateEnd && { createdDateEnd: format(createdDateEnd, "yyyy-MM-dd") }),
    }
    assetGroup.value = await assetService.getAssetGroup(props.assetGroupId)

    const results = await assetService.listAssets({ groupId: props.assetGroupId, url, ...filters })
    assets.value = results.data
    pageInfo.value = results.pageInfo
  } catch (error: any) {
    if (error?.message === "Device group not found") {
      router.push({ name: "wc-404" })
      return
    }

    hasError.value = true
    console.error("There was an error loading this asset group", error)
  }
  isLoading.value = false
}

watch([filterAssetStatus], () => {
  loadAssets()
})

// Debounced text input
watch(
  [filterAssetCreatedDateRange, filterAssetLocationKeyword],
  debounce(() => {
    loadAssets()
  })
)

const assetLocations = computed(() => mapAssetsToLocations(assets.value))

const handleRowClicked = (row: { id: number }, event: MouseEvent | KeyboardEvent) => {
  const path = `/assets/${row.id}`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    router.push(path)
  }
}

const handleClearFilters = () => {
  filterAssetStatus.value = null
  filterAssetLocationKeyword.value = null
  filterAssetCreatedDateRange.value = null
  loadAssets()
}

const showEditAssetGroupModal = (assetGroupId: number) => {
  editAssetGroupModal.value.openModal(assetGroupId)
}

const handleAssetGroupUpdated = (addedGroup: AssetGroup | null) => {
  if (addedGroup) {
    assetGroup.value = addedGroup
  } else {
    router.push({ name: "wc-assets" })
  }
}
const handleAssetGroupAssetsUpdated = () => {
  loadAssets()
}

const handleRowsSelected = (selectedRowIDs: number[]) => {
  selectedAssetIds.value = selectedRowIDs
}

const handleClearSelection = () => {
  selectedAssetIds.value = []
}

const removeSelectedAssets = async () => {
  if (!assetGroup.value || selectedAssetIds.value.length === 0) {
    return
  }
  try {
    await assetService.removeAssetsFromAssetGroup(assetGroup.value.id, selectedAssetIds.value)
    selectedAssetIds.value = []
    isLoading.value = true
    loadAssets()
  } catch (error) {
    console.error(error)
    toast.error("There was a problem removing assets from the group. Please try again.")
  }
}
</script>
