import { Asset, AssetPricingFunctionType } from "@common/models/asset"

// MUST be kept in sync with the MARKETPLACE_FEE constant defined in https://github.com/wattcarbon/api/blob/main/carbon_data/constants.py
const MARKETPLACE_FEE = 0.15 // 15%

// TODO: Update this to use actual values for the assets in question instead of pulling from these made up constants
const INACCURATE_MWH_TO_TON_MULTIPLIER = 0.44
const INACCURATE_MWH_PER_ASSET_PER_QUARTER = 1.5316

export const calculateTotalPossibleRevenue = (props: { assets: Asset[]; priceInPennies: number; priceFunction: AssetPricingFunctionType }) => {
  const { assets, priceInPennies, priceFunction } = props
  const numAssets = assets.length
  const revenuePerAsset =
    priceFunction === AssetPricingFunctionType.per_mwh
      ? INACCURATE_MWH_PER_ASSET_PER_QUARTER
      : INACCURATE_MWH_PER_ASSET_PER_QUARTER * INACCURATE_MWH_TO_TON_MULTIPLIER
  const value = numAssets * revenuePerAsset * priceInPennies
  return Math.round((value + Number.EPSILON) * 100) / 100
}

export const calculatePayoutAfterFees = (priceInDollars: number): number => {
  if (!priceInDollars) return 0

  return Math.ceil(priceInDollars * 100 * (1 - MARKETPLACE_FEE)) / 100
}
