<template>
  <table class="w-full">
    <thead>
      <tr class="border-b border-sagetone">
        <th class="text-left">Portfolio</th>
        <th class="text-right">Weight</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-sagetone">
      <tr v-if="!stakesAndPortfolios.length">
        <td colspan="4" class="px-4 py-2 text-left">
          <p v-if="!isLoading">No stakes.</p>
          <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
        </td>
      </tr>
      <tr v-for="[stake, portfolio] in stakesAndPortfolios" :key="stake.accountId">
        <td class="max-w-[20ch] truncate px-4 py-2 text-left underline">
          <router-link :to="{ name: 'wc-admin-portfolio-stakes', params: { portfolioId: stake.portfolioId } }">
            {{ portfolio.name }}
          </router-link>
        </td>
        <td class="px-4 py-2 text-right">{{ stake.weight }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="2" class="px-4 pt-4 text-sagetone">Edit stakes from the the portfolio view.</td>
      </tr>
    </tfoot>
  </table>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue"
import { Icon } from "@iconify/vue"
import { Portfolio, PortfolioStake } from "@common/models/order"
import { usePortfolioService } from "@/services/service-container"
import { useAdminPortfoliosStore } from "../adminPortfolios.state"

const props = defineProps<{
  accountId: number
}>()

const adminPortfoliosStore = useAdminPortfoliosStore()
const portfolioService = usePortfolioService()

const isLoading = ref<boolean>(false)
const stakes = ref<Array<PortfolioStake>>([])

watchEffect(async () => {
  isLoading.value = true
  try {
    stakes.value = await portfolioService.listStakes({ accountId: props.accountId })
  } finally {
    isLoading.value = false
  }
})

const stakesAndPortfolios = computed<[PortfolioStake, Portfolio][]>(() =>
  stakes.value.map((s) => [s, adminPortfoliosStore.getPortfolioById(s.portfolioId)!])
)
</script>

<style scoped lang="scss">
thead th {
  @apply font-normal px-4 py-2;
}
</style>
