// Metadata properties that are already included in the Public Metadata and All Metadata sections
const STANDARD_METADATA_KEYS = [
  "address",
  "cambiumRegion",
  "city",
  "climateZoneAshrae2004",
  "coordinates",
  "createdTime",
  "customId",
  "deviceOwner",
  "eacOwner",
  "group",
  "groupId",
  "id",
  "kind",
  "loadZone",
  "methodologyUrl",
  "name",
  "nhgisCountyId",
  "price",
  "provider",
  "state",
  "street",
  "timezone",
  "utility",
  "updatedTime",
  "zipCode",
]

// Metadata properties representing internal info
const INTERNAL_METADATA_KEYS = ["accountId", "auditStatus", "location", "reviewNotes", "status"]

const omitStandardAndInternalMetadata = (metadata: Record<string, unknown>) => {
  return Object.fromEntries(
    Object.entries(metadata).filter(([key]) => !STANDARD_METADATA_KEYS.includes(key) && !INTERNAL_METADATA_KEYS.includes(key))
  )
}

export const userFacingPropertyName = (propertyName: string): string => {
  return propertyName
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .trim()
}

export const userFacingStringValue = (value: string): string => {
  // If the value already contains spaces, return it as-is
  if (value.includes(" ")) {
    return value
  }

  // For a snake_case string representing an enum, transform to title case
  return value
    .split("_")
    .map((word) => {
      if (word === word.toUpperCase()) return word
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join(" ")
}

const suffixReplacements: [string, string][] = [
  ["Id", "ID"],
  ["Uef", "UEF"],
  ["Afue", "AFUE"],
  ["Hspf", "HSPF"],
  ["Cef", "CEF"],
  ["Years", "(Years)"],
  ["F", "(°F)"],
  ["Ach50", "(ACH50)"],
  ["Kw", "(kW)"],
  ["Mwh", "(MWh)"],
]

const transformPropertyName = (originalName: string): string => {
  let name = userFacingPropertyName(originalName)
  suffixReplacements.forEach(([suffix, replacement]) => {
    const regex = new RegExp(`(?<=\\s)${suffix}$`, "i")
    name = name.replace(regex, replacement)
  })
  return name
}

const transformPropertyValue = (originalValue: unknown): unknown => {
  if (typeof originalValue === "boolean") {
    return originalValue ? "Yes" : "No"
  }
  if (typeof originalValue === "string") {
    return userFacingStringValue(originalValue)
  }
  return originalValue
}

export const formatGeneralAssetTypeMetadata = (metadata: Record<string, unknown>) => {
  const generalMetadata = omitStandardAndInternalMetadata(metadata)
  return Object.fromEntries(
    Object.entries(generalMetadata).map(([key, value]) => {
      return [transformPropertyName(key), transformPropertyValue(value)]
    })
  )
}
