<template>
  <div>
    <AssetMetadataTable class="mt-8" title="Demand Response Info" :metadata="details" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import type { DemandResponseAsset } from "@common/models/asset"
import AssetMetadataTable from "./AssetMetadataTable.vue"

const { asset } = defineProps<{ asset: DemandResponseAsset }>()

const details = computed(() => {
  return {
    "Meter ID": asset.meterId,
    "Service Point Number": asset.servicePointNumber,
    "Load Serving Entity": asset.loadServingEntity,
    "Bill Customer Agreement Number": asset.billCustomerAgreementNumber,
    "Bill Service Account Number": asset.billServiceAccountNumber,
  }
})
</script>
