import { ref, computed, watch } from "vue"
import { useRegistryService } from "@/services/service-container"
import { AccountHourlyBalanceSummary, EacUnits, EacValue } from "@/services/api/registry.model"
import { DateRange } from "@/services/api/registry.service"
import { defineStore } from "pinia"
import { addDays } from "date-fns"

export interface EacBalances {
  [isoDateString: string]: EacBalance
}

export interface EacBalance {
  dateTime: string // this will be the same value as the key (isoDateString)
  dateIndex: number
  hourIndex: number
  active: number
  retired: number
}

export interface EacMonthlyBalance {
  month: number
  year: number
  active: number
  retired: number
}

interface EacRangeSelection {
  minDateIndex: number
  maxDateIndex: number
  minHourIndex: number
  maxHourIndex: number
}

export type NullableEacRangeSelection = EacRangeSelection | null

export const isRetired = (eac: any) => {
  return eac["Status"] === "retired"
}

export const useEacStore = defineStore("eac", () => {
  const registryService = useRegistryService()
  const isLoading = ref(true)

  const dateRange = ref<DateRange | null>(null)
  const eacBalances = ref<EacBalances>({})
  const selection = ref<EacRangeSelection | null>(null)

  const setDateRange = (newDateRange: DateRange) => {
    dateRange.value = newDateRange
    resetSelection()
  }

  watch(dateRange, async () => {
    await fetchEacBalances()
  })

  const fetchEacBalances = async () => {
    if (dateRange.value) {
      isLoading.value = true

      const hourlySummary = await registryService.getHourlyBalanceSummary(dateRange.value)
      eacBalances.value = balancesByHour(hourlySummary, dateRange.value.startDate)

      isLoading.value = false
    }
  }

  const isBalanceSelected = (balance: EacBalance) => {
    if (!selection.value) return false
    const { minDateIndex, maxDateIndex, minHourIndex, maxHourIndex } = selection.value
    return (
      minHourIndex <= balance.hourIndex && balance.hourIndex <= maxHourIndex && minDateIndex <= balance.dateIndex && balance.dateIndex <= maxDateIndex
    )
  }

  const isDateTimeSelected = (isoDateString: string) => {
    const balance = eacBalances.value[isoDateString]
    return isBalanceSelected(balance)
  }

  const setSelection = (newSelection: EacRangeSelection) => {
    selection.value = newSelection
  }

  const resetSelection = () => {
    selection.value = null
  }

  const selectedDateTimeRange = computed(() => {
    if (!dateRange.value) return null
    if (!selection.value) return null

    return {
      startDate: addDays(dateRange.value.startDate, selection.value.minDateIndex),
      endDate: addDays(dateRange.value.startDate, selection.value.maxDateIndex + 1),
      startHour: selection.value.minHourIndex,
      endHour: selection.value.maxHourIndex + 1,
    }
  })

  const totalAvailableQuantity = computed(() => {
    return Object.values(eacBalances.value).reduce((total, hour) => total + hour.active + hour.retired, 0)
  })

  const selectedBalances = computed(() => Object.values(eacBalances.value).filter((balance) => isBalanceSelected(balance)))

  const totalSelectedQuantity = computed(() => selectedBalances.value.reduce((total, balance) => total + balance.active + balance.retired, 0))

  const totalSelectedActiveQuantity = computed(() => selectedBalances.value.reduce((total, balance) => total + balance.active, 0))

  return {
    // state
    eacBalances,
    isLoading,
    dateRange,
    // getters
    totalAvailableQuantity,
    totalSelectedQuantity,
    totalSelectedActiveQuantity,
    isDateTimeSelected,
    selectedDateTimeRange,
    // actions
    setDateRange,
    setSelection,
    resetSelection,
    fetchEacBalances,
  }
})

function hasTimeZone(dateString: string) {
  const timeZoneRegex = /(?:Z|[+-](?:2[0-3]|[01]\d):[0-5]\d)$/
  return timeZoneRegex.test(dateString)
}

export function parseUTCTimestamp(dateString: string) {
  if (!hasTimeZone(dateString)) {
    dateString += "Z" // treat naive timestamps as UTC
  }
  return new Date(dateString)
}

// Pick out the year, month, and date portions of the Date and construct a new
// UTC Date with only those pieces
function constructUtcDateWithoutTime(utcDateWithTime: Date) {
  return new Date(Date.UTC(utcDateWithTime.getUTCFullYear(), utcDateWithTime.getUTCMonth(), utcDateWithTime.getUTCDate()))
}

function dateDiffInDays(startDate: Date, endDate: Date) {
  const dateDiff = endDate.getTime() - startDate.getTime()
  return Math.floor(dateDiff / (1000 * 3600 * 24))
}

const accountBalanceTotalCountForStatus = (accountBalancesByStatus: Partial<Record<EacUnits, EacValue>>) => {
  return Object.values(accountBalancesByStatus).reduce((totalBalanceAnyUnit, eacCount) => totalBalanceAnyUnit + (eacCount.count ?? 0), 0)
}

export const balancesByHour = (hourlyBalanceSummary: AccountHourlyBalanceSummary, startDate: Date) => {
  const dateRangeStartUtcDateWithoutTime = constructUtcDateWithoutTime(startDate)
  return Object.fromEntries(
    Object.entries(hourlyBalanceSummary.hours)
      .filter(([, accountBalances]) => {
        return accountBalanceTotalCountForStatus(accountBalances.active) > 0 || accountBalanceTotalCountForStatus(accountBalances.retired) > 0
      })
      .map(([timestamp, accountBalances]) => {
        const hour = parseUTCTimestamp(timestamp)
        const utcDateWithoutTime = constructUtcDateWithoutTime(hour)
        const dateIndex = dateDiffInDays(dateRangeStartUtcDateWithoutTime, utcDateWithoutTime)

        return [
          hour.toISOString(),
          {
            dateTime: hour.toISOString(),
            dateIndex: dateIndex,
            hourIndex: hour.getUTCHours(),
            active: accountBalanceTotalCountForStatus(accountBalances.active),
            retired: accountBalanceTotalCountForStatus(accountBalances.retired),
          },
        ]
      })
  )
}
