export enum QueryGroupByType {
  YearMonthlyHourOfDay = "year_monthly_hour_of_day",
  YearMonthly = "year_monthly",
  HourOfDay = "hour_of_day_24",
  DateTime = "datetime", // 8760
  Date = "date", // 365
  Total = "total",
}

export enum QuerySourceType {
  Asset = "asset",
  // Order = "order",
}

export enum QueryLoadshapeSourceType {
  Actuals = "actuals",
  Forecast = "forecast",
}

export class LoadshapeQueryRequest {
  sourceIds: Array<number>
  startDate: string // ISO
  endDate: string // ISO
  sourceType = QuerySourceType.Asset
  loadshapeForecastType = QueryLoadshapeSourceType.Actuals
  groupBy = QueryGroupByType.DateTime
  responseFormat = "json"

  constructor(sourceIds = new Array<number>(), startDate: Date = new Date(), endDate: Date = new Date()) {
    this.sourceIds = [...sourceIds]
    this.startDate = startDate.toISOString().substring(0, 10)
    this.endDate = endDate.toISOString().substring(0, 10)
  }
}

export class LoadshapeAssetActualsQueryRequest extends LoadshapeQueryRequest {
  sourceType = QuerySourceType.Asset
  loadshapeForecastType = QueryLoadshapeSourceType.Actuals

  constructor(
    sourceIds = new Array<number>(),
    startDate: Date = new Date(),
    endDate: Date = new Date(),
    groupBy: QueryGroupByType = QueryGroupByType.DateTime
  ) {
    super(sourceIds, startDate, endDate)
    this.groupBy = groupBy
  }
}

export class LoadshapeAssetForecastQueryRequest extends LoadshapeQueryRequest {
  sourceType = QuerySourceType.Asset
  loadshapeForecastType = QueryLoadshapeSourceType.Forecast

  constructor(
    sourceIds = new Array<number>(),
    startDate: Date = new Date(),
    endDate: Date = new Date(),
    groupBy: QueryGroupByType = QueryGroupByType.DateTime
  ) {
    super(sourceIds, startDate, endDate)
    this.groupBy = groupBy
  }
}

// export class LoadshapeOrderActualsQueryRequest extends LoadshapeQueryRequest {
//     sourceType = QuerySourceType.Order
//     loadshapeForecastType = QueryLoadshapeSourceType.Actuals
// }

// export class LoadshapeOrderForecastQueryRequest extends LoadshapeQueryRequest {
//     sourceType = QuerySourceType.Order
//     loadshapeForecastType = QueryLoadshapeSourceType.Forecast
//     constructor(sourceIds = new Array<number>(), startDate: Date = new Date(), endDate: Date= new Date(), groupBy: QueryGroupByType = QueryGroupByType.DateTime) {
//         super(sourceIds,startDate,endDate)
//         this.groupBy = groupBy
//     }
// }

export class LoadshapeSummaryQueryRequest extends LoadshapeQueryRequest {
  sourceType = QuerySourceType.Asset
  loadshapeForecastType = QueryLoadshapeSourceType.Actuals
  groupBy = QueryGroupByType.Total

  constructor(sourceIds = new Array<number>(), startDate: Date, endDate: Date) {
    super(sourceIds, startDate, endDate)
  }
}
