const productionHostnames = ["app.wattcarbon.com", "www.wattcarbon.com", "wattcarbon.com"]

// For backwards compatibility; stuff that we know is only running in astro server
// can just access env variables directly, but there's some code that we want to reuse
// in both app.wattcarbon and www.wattcarbon that expects this format
function getExplicitEnv() {
  if (import.meta.env.PUBLIC_DEPLOYMENT) {
    // The server may use a different API endpoint than the client, e.g. in dev
    // The `SECRET_` prefixed env variables aren't bundled in the client code
    const API_BASE_URL = import.meta.env.SECRET_API_BASE || import.meta.env.PUBLIC_API_BASE
    return {
      DEPLOYMENT: import.meta.env.PUBLIC_DEPLOYMENT,
      BASE_DOMAIN: import.meta.env.PUBLIC_BASE_DOMAIN,
      API_BASE_URL,
      WWW_BASE_URL: import.meta.env.PUBLIC_WWW_BASE,
      APP_BASE_URL: import.meta.env.PUBLIC_APP_BASE,
    }
  }
}

function getInferredEnv() {
  if (import.meta.env.SSR) throw new Error("Cannot call getInferredEnv from server")
  const hostname = window.location.hostname
  return inferEnvironmentFromHostname(hostname)
}

export function getEnvironment() {
  return getExplicitEnv() ?? getInferredEnv()
}

/**
 * @deprecated This function is only still exported for tests.  Everything else should use `getEnvironment()`
 */
export const getEnvironmentFromHostname = (hostname: string) => {
  return inferEnvironmentFromHostname(hostname)
}

/**
 * This method is used to determine the environment based on the hostname.
 * It is intended to be used in the browser.
 * Because it may be used in the www subdomain or app subdomain, it needs to be able to handle both.
 */
const inferEnvironmentFromHostname = (hostname: string) => {
  // If environmentment variables from .env file are loaded, return those rather than inferring
  const explicitEnv = getExplicitEnv()
  if (explicitEnv) return explicitEnv

  // TODO: Once everything between app and www is merged, clean this up!
  if (productionHostnames.includes(hostname)) {
    return {
      DEPLOYMENT: "prod",
      BASE_DOMAIN: "wattcarbon.com",
      API_BASE_URL: "https://api.wattcarbon.com",
      WWW_BASE_URL: "https://www.wattcarbon.com",
      APP_BASE_URL: "https://app.wattcarbon.com",
    }
  }
  const match = hostname.match(/^(.*)\.wattcarbon\.com$/)
  if (!match) {
    return {
      DEPLOYMENT: "local",
      BASE_DOMAIN: "wattcarbon.localhost",
      API_BASE_URL: "https://api.wattcarbon.localhost",
      WWW_BASE_URL: "https://www.wattcarbon.localhost",
      APP_BASE_URL: "https://app.wattcarbon.localhost",
    }
  }

  if (match[1] === "staging") {
    // we have a frontend staging but not a backend staging
    return {
      DEPLOYMENT: "staging",
      BASE_DOMAIN: "wattcarbon.com",
      API_BASE_URL: "https://api.wattcarbon.com",
      WWW_BASE_URL: "https://www.wattcarbon.com",
      APP_BASE_URL: "https://staging.wattcarbon.com",
    }
  }

  // regex match the string before an optional hyphen in the previously matched string
  // e.g. "beta-api" => "beta"
  // const environmentMatch = hostname.match(/^(.*)-?(.*)\.wattcarbon\.com$/) || ['beta']
  const environmentString = match[1].split("-")[0]
  return {
    DEPLOYMENT: environmentString,
    BASE_DOMAIN: "wattcarbon.com",
    API_BASE_URL: `https://${environmentString}-api.wattcarbon.com`,
    WWW_BASE_URL: `https://${environmentString}-www.wattcarbon.com`,
    APP_BASE_URL: `https://${environmentString}.wattcarbon.com`,
  }
}
