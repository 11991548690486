import { Account } from "@common/models/models"
import { defineStore } from "pinia"
import { ref, Ref, computed } from "vue"
import { TYPE, useToast } from "vue-toastification"
import { useAccountsService } from "@/services/service-container"
import { useAssetService } from "@/services/service-container"

export const useAdminAccountsStore = defineStore("adminAccounts", () => {
  const isLoading = ref<boolean>(true)
  const isSaving = ref<boolean>(false)
  const accountMap = ref(new Map()) as Ref<Map<number, Account>>

  const accountsService = useAccountsService()
  const assetService = useAssetService()
  const toast = useToast()

  const loadAccounts = async () => {
    isLoading.value = true
    try {
      const accountList = await accountsService.listAllAccounts()
      accountMap.value = new Map(accountList.map((a) => [a.id, a]))
    } finally {
      isLoading.value = false
    }
  }

  const _toastify = <T extends Array<any>, U>(message: string, fn: (...args: T) => U) => {
    return async (...args: T): Promise<U> => {
      isSaving.value = true
      const toastId = toast(`${message}…`, { id: "Accounts", type: TYPE.DEFAULT, timeout: false })

      try {
        const result = await fn(...args)
        toast.update(toastId, { content: "Success!", options: { type: TYPE.SUCCESS, timeout: 1000 } })
        return result
      } catch (err) {
        toast.update(toastId, { content: `Error: ${err}`, options: { type: TYPE.ERROR } })
        throw err
      } finally {
        isSaving.value = false
      }
    }
  }

  const createAccount = _toastify("Creating", async (name: string) => {
    const newAccount = await accountsService.addAccount(name)
    accountMap.value.set(newAccount.id, newAccount)
    return newAccount
  })

  const fetchAccount = async (id: number) => {
    const account = await accountsService.getAccount(id)
    accountMap.value.set(account.id, account)
    return account
  }

  const updateAccount = _toastify("Saving", async (account: Account) => {
    const updatedAccount = await accountsService.updateAccount(account)
    accountMap.value.set(updatedAccount.id, updatedAccount)
    return updatedAccount
  })

  const uploadLogo = _toastify("Uploading", async (id: number, file: File) => {
    const updatedAccount = await accountsService.uploadLogo(id, file)
    accountMap.value.set(updatedAccount.id, updatedAccount)
    return updatedAccount
  })

  const deleteAccount = _toastify("Deleting", async (id: number) => {
    await accountsService.deleteAccount(id)
    accountMap.value.delete(id)
  })

  const deleteAccountAssets = _toastify("Deleting Assets", async (id: number) => {
    await assetService.deleteAccountAssets(id)
  })

  const addUser = _toastify("Adding", async (accountId: number, userEmail: string) => {
    const updatedAccount = await accountsService.addUser(accountId, userEmail)
    accountMap.value.set(updatedAccount.id, updatedAccount)
    return updatedAccount
  })

  const removeUser = _toastify("Removing", async (accountId: number, userId: string) => {
    const updatedAccount = await accountsService.removeUser(accountId, userId)
    accountMap.value.set(updatedAccount.id, updatedAccount)
    return updatedAccount
  })

  const accounts = computed(() => Array.from(accountMap.value.values()))

  const getAccountById = computed(() => {
    return (id: number) => accountMap.value.get(id)
  })

  // automatically load accounts on first instantiation
  loadAccounts()

  return {
    // state
    isLoading,
    isSaving,
    // actions
    loadAccounts,
    createAccount,
    fetchAccount,
    updateAccount,
    deleteAccount,
    deleteAccountAssets,
    uploadLogo,
    addUser,
    removeUser,
    // getters
    accounts,
    getAccountById,
  }
})
