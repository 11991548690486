<template>
  <WcModal :is-open="isOpen" header="Edit Asset Group" size="medium" @update:is-open="handleUpdateIsOpen">
    <template #panel>
      <div v-if="assetGroup" class="sm:w-[424px]">
        <fieldset class="mb-8">
          <label class="text-body-2 mb-2 text-black" for="assetGroupName">Asset Group Name</label>
          <WcInputText id="assetGroupName" v-model="assetGroupName" name="assetGroupName" />
        </fieldset>
        <div class="mb-8 grid grid-cols-3 gap-4">
          <div>
            <div class="text-subheading-large-bold">{{ assetGroup.deviceIds.length }}</div>
            <div class="text-body-3">Total Assets</div>
          </div>
          <div>
            <div class="text-subheading-large-bold">X.XXm</div>
            <div class="text-body-3">Total EACs</div>
          </div>
        </div>
        <div class="text-caption flex gap-4 text-hint">
          <div>Created: {{ formatDate(assetGroup.createdTime) }}</div>
          <div>Last updated: {{ formatDate(assetGroup.updatedTime) }}</div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full items-center justify-between">
        <WcCTA color="alert" icon-position="right" icon="wc-carbon:trash-can" text="Delete Asset Group" size="small" @click="deleteAssetGroup" />
        <div class="flex justify-end gap-3">
          <WcButton text="Cancel" variant="secondary" size="small" @click="handleUpdateIsOpen(false)" />
          <WcButton text="Save" :is-disabled="!assetGroup || !assetGroupName" size="small" @click="updateAssetGroup" />
        </div>
      </div>
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useToast } from "vue-toastification"
import type { AssetGroup } from "@common/models/asset"
import { WcButton, WcCTA } from "@/components/button"
import { WcInputText } from "@/components/input"
import WcModal from "@/components/WcModal.vue"
import { useAssetService } from "@/services/service-container"

const assetService = useAssetService()
const toast = useToast()

const emit = defineEmits<{
  "asset-group-updated": [assetGroup: AssetGroup | null]
}>()

const isOpen = ref<boolean>(false)
const assetGroup = ref<AssetGroup>()
const assetGroupName = ref<string>("")

const formatter = new Intl.DateTimeFormat("en", { dateStyle: "short" })
const formatDate = (date: string) => formatter.format(new Date(date))

const handleUpdateIsOpen = async (newValue: boolean) => {
  isOpen.value = newValue
}

const updateAssetGroup = async () => {
  if (!assetGroup.value) {
    return
  }
  try {
    const updatedAssetGroup = await assetService.updateAssetGroup(assetGroup.value.id, { name: assetGroupName.value })
    emit("asset-group-updated", updatedAssetGroup)
    toast.success(`Asset group ${updatedAssetGroup.name} updated`)
    closeModal()
  } catch (error) {
    toast.error("There was a problem updating the asset group. Please try again.")
    throw error
  }
}

const deleteAssetGroup = async () => {
  if (assetGroup.value && confirm(`Are you sure you want to delete asset group '${assetGroup.value.name}'?`)) {
    try {
      await assetService.deleteAssetGroup(assetGroup.value.id)
      emit("asset-group-updated", null)
      toast.success(`Asset group ${assetGroup.value.name} deleted`)
      closeModal()
    } catch (error) {
      toast.error("There was a problem deleting the asset group. Please try again.")
      throw error
    }
  }
}

const openModal = async (assetGroupId: number) => {
  assetGroup.value = await assetService.getAssetGroup(assetGroupId)
  assetGroupName.value = assetGroup.value.name

  handleUpdateIsOpen(true)
}
const closeModal = () => handleUpdateIsOpen(false)
defineExpose({ openModal, closeModal })
</script>
