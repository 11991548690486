<template>
  <h4 class="text-heading-5 text-blue-70">About solar</h4>
  <p class="mt-4">
    Solar energy reduces carbon emissions and electricity bills, and is an integral solution for providing Americans with energy independence from
    fossil fuels.
  </p>
  <p class="mt-4">
    Our solar projects focus on regions where the grid is dirtiest, like the Appalachian region, where the carbon reduction impact of new solar power
    is far greater. Equitable solar distribution also provides financial savings and stability. In areas with less renewable energy sources, residents
    often experience unpredictable rate hikes by their utility provider, at times over 30%.
  </p>
  <p class="mt-4">
    Solar is also revitalizing traditional coal mining communities across America, generating millions of new jobs, economic benefits and sustainable
    community growth.
  </p>
  <p class="mt-4">
    Today, solar power only accounts for about 5% of total US electricity generation. By 2030, the United States needs to ramp up renewable energy
    production by 5 times our current supply, making deployment acceleration urgent.
  </p>
  <h4 class="text-heading-5 mt-10 whitespace-pre-line text-blue-70">Why funding is necessary</h4>
  <p class="mt-4">
    While solar technology has become more affordable over time, the primary challenge facing widespread solar adoption is the upfront investment
    required for installation. Particularly for low income communities, financing remains a significant challenge, leaving major portions of the
    United States subject to expensive, unreliable and heavily polluting dirty energy.
  </p>
</template>
