import BaseFetcher from "@/services/base-fetcher"

// VITE_SMARTY_KEY is an "embedded" key intended for being shared with the
// client. the key is locked down for use from our Origins.
// https://www.smarty.com/docs/cloud/authentication
const domain = (import.meta.env.VITE_SMARTY_BASE as string) || "https://us-autocomplete-pro.api.smartystreets.com"
const key = import.meta.env.VITE_SMARTY_KEY as string

export default class AddressFetcher extends BaseFetcher {
  key: string
  constructor() {
    super(domain)
    this.key = key
  }

  async listAddressMatches(params: any) {
    const data = {
      search: params.street,
      key: this.key,
      source: "all",
      prefer_ratio: 30,
      max_results: 10,
    }
    const endpoint = "/lookup"
    const response = (await this.httpGet(endpoint, data)) as any
    if (response.suggestions) {
      return response.suggestions
        .filter((a: any) => a.entries === 0)
        .map((s: any) => {
          return {
            street: s.street_line,
            city: s.city,
            state: s.state,
            postalCode: s.zipcode,
          }
        })
    }
  }

  // async autocompleteAddress(preview: any) {
  //   const address = `${preview.address}, ${preview.city}, ${preview.pc}`
  //   const data = {
  //     address,
  //   }
  //   // const endpoint = `/completions?index=${index}&geocode=true`;
  //   const endpoint = `/verifications?geocode=true`
  //   const response = (await this.httpPost(endpoint, data)) as any
  //   if (response.status === "success") {
  //     return response.data
  //   }
  // }
  // getHeaders = () => {
  //   if (this.key) {
  //     return { headers: { "X-API-key": `${this.key}` } }
  //   }
  //   return {}
  // }
}
