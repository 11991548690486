<template>
  <section>
    <section class="flex flex-wrap gap-2">
      <WcDropdown v-model="filterAssetStatus" class="basis-40" name="filterAssetStatus" :options="statusOptions" inset-label="Status" />
      <WcDropdown v-model="filterAssetKind" class="basis-40" name="filterAssetKind" :options="kindOptions" inset-label="Type" />
      <WcDropdown v-model="filterAssetAccount" class="basis-40" name="filterAssetAccount" :options="accountOptions" inset-label="Account" />
    </section>

    <section class="mt-4">
      <div class="flex justify-between gap-3">
        <WcButton
          text="Remove from story"
          color="alert"
          size="small"
          :is-disabled="!selectedStoryAssetIds.length"
          @click="handleRemoveSelectedStoryAssets" />
        <WcButton text="Add Assets" size="small" @click="openAddModal" />
      </div>
      <div class="mt-4 h-[calc(100vh-35.125rem)] overflow-scroll">
        <AdminStoryAssetTable
          :assets="storyAssets"
          :stories="stories"
          allow-multiple-selection
          :selected-row-ids="selectedStoryAssetIds"
          show-price
          @row-clicked="handleRowClicked"
          @rows-selected="handleStoryAssetRowsSelected" />
        <PaginationButtons class="pt-2" :page-info="storyAssetsPageInfo" @load-items="loadStoryAssets" />
      </div>
    </section>

    <AdminStoryAssetsDialog v-if="story" :is-open="isAddModalOpen" :stories="stories" :story="story" @update:is-open="setIsAddModalOpen" />
  </section>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useToast } from "vue-toastification"
import { WcButton } from "@/components/button"
import type { SelectOption } from "@/components/input"
import { WcDropdown } from "@/components/input"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { useAdminStoriesStore } from "@/modules/admin/adminStories.state"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService, useStoryService } from "@/services/service-container"
import type { Asset, AssetKind, AssetStatus } from "@common/models/asset"
import { ASSET_KIND, ASSET_STATUS } from "@common/models/asset"
import type { Story } from "@common/models/story"
import AdminStoryAssetsDialog from "./AdminStoryAssetsDialog.vue"
import AdminStoryAssetTable from "./AdminStoryAssetTable.vue"

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))
const kindOptions = Object.entries(ASSET_KIND).map(([value, label]) => ({ label, value }))
const accountOptions = computed(() => {
  return adminAccountsStore.accounts.filter(({ id }) => id > 0).map(({ id, name }) => ({ label: name, value: id }))
})

const props = defineProps<{ storyId?: number }>()

const adminAccountsStore = useAdminAccountsStore()
const adminStoriesStore = useAdminStoriesStore()
const assetService = useAssetService()
const storyService = useStoryService()
const toast = useToast()

const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetKind = ref<SelectOption<AssetKind> | null>(null)
const filterAssetAccount = ref<SelectOption<number> | null>(null)

const stories = ref<Story[]>([])
const storyAssets = ref<Asset[]>([])
const storyAssetsPageInfo = ref<PageInfo>()
const selectedStoryAssetIds = ref<number[]>([])
const isAddModalOpen = ref<boolean>(false)

const openAddModal = () => {
  isAddModalOpen.value = true
}

const setIsAddModalOpen = (isOpen: boolean) => {
  isAddModalOpen.value = isOpen
  if (!isOpen) {
    loadStoryAssets()
  }
}

const story = computed(() => {
  if (props.storyId !== undefined) {
    return adminStoriesStore.getStoryById(props.storyId)
  }
  return undefined
})

const loadStories = async () => {
  try {
    stories.value = await storyService.listStoriesAdmin()
  } catch (error) {
    console.error("There was an error loading stories", error)
  }
}

const loadStoryAssets = async (url?: string) => {
  try {
    const filters = {
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetKind.value && { kind: filterAssetKind.value.value }),
      ...(filterAssetAccount.value && { accountIds: filterAssetAccount.value.value }),
      storyId: props.storyId,
    }
    const results = await assetService.listAssetsAdmin({ url, ...filters })
    storyAssets.value = results.data
    storyAssetsPageInfo.value = results.pageInfo
  } catch (error) {
    console.error("There was an error loading story assets", error)
  }
}

onMounted(async () => {
  loadStories()
  loadStoryAssets()
})

watch([filterAssetStatus, filterAssetKind, filterAssetAccount], () => {
  loadStoryAssets()
})

const handleRowClicked = (row: { id: number }) => {
  const path = `/assets/${row.id}`
  window.open(path, "_blank")
}

const handleStoryAssetRowsSelected = (selectedRowIDs: number[]) => {
  selectedStoryAssetIds.value = selectedRowIDs
}

const handleRemoveSelectedStoryAssets = async () => {
  const assetIds = selectedStoryAssetIds.value
  try {
    await assetService.setAssetStoryBulkAdmin(assetIds, null)

    selectedStoryAssetIds.value = []
    await loadStoryAssets()

    toast.success(`${assetIds.length} assets were removed from the story`)
  } catch (error) {
    const message = "There was an error removing assets from the story"
    console.error(message, error)
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
  }
}
</script>
