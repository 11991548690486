<template>
  <section v-if="impactStatements?.length" class="my-6">
    <div v-for="(impactStatement, index) in impactStatements" :key="index" class="impact-statement mb-6 rounded-lg bg-lilac-100 p-6">
      <div v-if="impactStatement.type === StatementType.quote" class="bold mb-[-52px] mt-[-20px] font-['Meursault'] text-8xl leading-[110%]">“</div>
      <div v-if="impactStatement.type === StatementType.other" class="mb-3 ml-2">
        <IdeaIcon />
      </div>
      <WcMarkdown :source="impactStatement.statement" />
      <div v-if="impactStatement.attribution" class="text-heading-6 pt-2 text-right">— {{ impactStatement.attribution }}</div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed } from "vue"
import WcMarkdown from "@/components/ui/WcMarkdown.vue"
import type { Story } from "@common/models/story"
import IdeaIcon from "@/components/icon/IdeaIcon.vue"

const props = defineProps<{ story: Story }>()

enum StatementType {
  quote = "quote",
  other = "other",
}

type StatementItem = {
  attribution: string
  statement: string
  type: StatementType
}

const impactStatements = computed(() => {
  const statements = props.story.impactStatements?.map((statement) => ({
    statement,
    type: StatementType.other,
  })) as StatementItem[]
  if (props.story.impactQuote) {
    statements?.push({
      attribution: props.story.impactQuoteAttribution || "",
      statement: props.story.impactQuote,
      type: StatementType.quote,
    })
  }
  return statements
})
</script>

<style lang="scss">
.impact-statement:nth-child(odd) {
  @apply bg-highlight-frostblue;
}
.impact-statement:nth-child(even) {
  @apply bg-cornyellow-200;
}
@screen sm {
  .impact-statement:nth-child(odd) {
    margin-right: 150px;
  }
  .impact-statement:nth-child(even) {
    margin-left: 198px;
  }
}
@screen lg {
  .impact-statement:nth-child(odd) {
    margin-left: 48px;
  }
}
.impact-statement p {
  // text-heading-6
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  font-family: "Meursault", serif;
}
</style>
