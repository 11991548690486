<template>
  <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
    <div class="col-span-1">
      <div class="mb-4 flex items-center">
        <h3 class="text-overline-2 inline-block">Total EACs</h3>
        <ToolTip class="ph-no-capture inline-block" panel-class="bg-white" button-class="!size-[11px] -mt-0.5 ml-2 !text-hint !border-hint">
          The total quantity of EACs associated with your account, including both active and retired EACs.
        </ToolTip>
      </div>
      <div data-cy="total-eacs">
        <div class="mb-3">
          <span class="text-featured-number pr-2">
            {{ totalEacs.quantity }}
          </span>
          <span class="text-body-1">
            {{ totalEacs.unit }}
          </span>
        </div>
        <div v-if="totalElectricity.quantity || 0 > 0 || totalEmissions.quantity || 0 > 0" class="text-body-3">
          {{ totalElectricity.quantity }} {{ totalElectricity.unit }} + {{ totalEmissions.quantity }} {{ totalEmissions.unit }}
        </div>
      </div>
    </div>
    <div class="col-span-1">
      <h3 class="text-overline-2 mb-4 inline-block">Total Assets</h3>
      <div v-if="store.numAssets != null" data-cy="total-assets">
        <span class="text-featured-number pr-2">
          {{ store.numAssets }}
        </span>
        <span class="text-body-1">assets</span>
      </div>
    </div>
    <div class="col-span-1 lg:col-span-2">
      <h3 class="text-overline-2 mb-4 inline-block">Most Recent Activity</h3>
      <div class="mb-1" data-cy="most-recent-activity">
        <span v-if="recentTransactionAmount?.quantity" class="text-featured-number pr-2">
          {{ Math.abs(parseFloat(recentTransactionAmount.quantity)) }}
        </span>
        <span class="text-body-1"> {{ recentTransactionSummary }} </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import ToolTip from "@/components/ui/ToolTip.vue"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import { Unit } from "@/services/api/transaction.service"
import { useEnterpriseDashboardMetricsStore } from "./enterprise.state"

const TRANSACTION_TYPE_TO_SUMMARY: { [key: string]: string } = {
  minting: "created",
  allocation: "allocated",
  transfer: "transferred",
  retirement: "retired",
  purchase: "purchased",
  listing_sale: "sold",
  listing_earnings: "sold",
}

const store = useEnterpriseDashboardMetricsStore()

const numElectricityEacs = computed(() => {
  if (!store.accountBalanceSummary) return null
  const numActive = store.accountBalanceSummary.active.wh_electricity_supplied?.count ?? 0
  const numRetired = store.accountBalanceSummary.retired.wh_electricity_supplied?.count ?? 0
  return numActive + numRetired
})

const numEmissionsEacs = computed(() => {
  if (!store.accountBalanceSummary) return null
  const numActive = store.accountBalanceSummary.active.g_co2e_avoided?.count ?? 0
  const numRetired = store.accountBalanceSummary.retired.g_co2e_avoided?.count ?? 0
  return numActive + numRetired
})

const totalEacs = computed(() => {
  if (numElectricityEacs.value === null && numEmissionsEacs.value === null) return { quantity: "", unit: "" }
  return getFormattedEacQuantity((numElectricityEacs.value ?? 0) + (numEmissionsEacs.value ?? 0), EacMeasurementParameter.EACs)
})

const totalElectricity = computed(() => {
  if (numElectricityEacs.value === null) return { quantity: "", unit: "" }
  return getFormattedEacQuantity(numElectricityEacs.value, EacMeasurementParameter.Electricity)
})

const totalEmissions = computed(() => {
  if (numEmissionsEacs.value === null) return { quantity: "", unit: "" }
  return getFormattedEacQuantity(numEmissionsEacs.value, EacMeasurementParameter.GhgEmissions)
})

const recentTransactionAmount = computed(() => {
  if (!store.transactions?.length) return null
  const mostRecentTransaction = store.transactions[0]
  const amountDetailEntry =
    mostRecentTransaction.details[0].kind === "listing_earnings" ? mostRecentTransaction.details[1] : mostRecentTransaction.details[0]

  const measurementUnit =
    amountDetailEntry.units === Unit.wh_electricity_supplied ? EacMeasurementParameter.Electricity : EacMeasurementParameter.GhgEmissions
  return getFormattedEacQuantity(Math.abs(amountDetailEntry.amount), measurementUnit, 2, "exceptZero")
})

const recentTransactionSummary = computed(() => {
  if (!store.transactions) return null
  if (store.transactions.length === 0) return "None"
  const description = TRANSACTION_TYPE_TO_SUMMARY[store.transactions[0].details[0].kind] ?? "transacted"
  return `${recentTransactionAmount.value?.unit} of EACs ${description}`
})
</script>
