<template>
  <div :id="chartId" class="bg-white dark:bg-white"></div>
</template>
<script lang="ts" setup>
// Libraries
import { computed } from "vue"
import { onMounted, PropType, watch } from "vue"
import { EChartsType } from "echarts"
import * as echarts from "echarts"
import { getDayOfYear, addDays, getYear } from "date-fns"

// Models
import { LoadshapeRow } from "@common/models/loadshape"
import colors from "@common/colors"
// Services
import { useMainStore } from "@/store"

const props = defineProps({
  height: { type: String },
  width: { type: String },
  data: { type: Object as PropType<Array<LoadshapeRow>>, required: true },
  isMarket: { type: Boolean, default: false },
})

const store = useMainStore()
const chartId = `heat_map_${new Date().getTime()}`

const startingYear = computed(() => {
  return props.data.length > 0 ? getYear(props.data[0].datetime) : new Date().getFullYear()
})
const firstDayOfLoadshape = computed(() => {
  return props.data.length > 0 ? getDayOfYear(props.data[0].datetime) : 0
})

const option = {
  tooltip: {
    position: "top",
    formatter: (params: any) => {
      const date = addDays(new Date(startingYear.value, 0, firstDayOfLoadshape.value, params.value[1]), params.value[0])
      return `${date.toLocaleString("en", { month: "short", day: "numeric", hour: "numeric", hour12: true })}
      <br/>
      <div class='flex justify-between items-center text-sm'>
       <div class='mt-2 w-4 h-4 rounded-full' style='background-color:${params.color};'>&nbsp;</div>
      <div class='h-4'>${params.value[2]}%</div></div>`
    },
  },
  responsive: true,
  grid: {
    backgroundColor: store.colors.grid,
    left: 40,
    top: 10,
    right: 10,
    bottom: 45,
  },
  xAxis: {
    type: "category",
    data: [],
    splitArea: {
      show: true,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    axisLabel: {
      interval: 0,
      width: 50,
      overflow: "truncate",
      fontSize: 10,
      formatter: (value: number, index: number) => {
        const date = addDays(new Date(startingYear.value, 0, firstDayOfLoadshape.value), index)
        return date.getDate() === 15 ? date.toLocaleString("en", { month: "short", year: "2-digit" }) : ""
      },
    },
  },
  yAxis: {
    backgroundColor: store.colors.grid,
    type: "category",
    min: 0,
    max: 24,
    inverse: true,
    data: [],
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    axisLabel: {
      verticalAlign: "bottom",
      fontSize: 8,
      padding: [2, 0, 0, 0],
    },
  },
  visualMap: {
    min: 0,
    max: 100,
    calculable: true,
    splitNumber: 10,
    type: "piecewise",
    orient: "horizontal",
    top: "bottom",
    left: "center",
    text: ["100% Clean", "0%"],
    itemWidth: 10,
    textStyle: {
      fontWeight: "bold",
      fontSize: 10,
    },
    padding: 3,
    inRange: {
      color: [
        colors.white,
        colors.blue[90],
        colors.blue[80],
        colors.blue[70],
        colors.blue[60],
        colors.blue[50],
        colors.blue[40],
        colors.blue[30],
        colors.blue[20],
        colors.blue[10],
      ],
    },
    outOfRange: {
      color: colors.sagetone.DEFAULT,
    },
    handleStyle: {
      color: colors.highlight.DEFAULT,
      borderColor: "transparent",
    },
    formatter: (value: number) => `${Math.round(value)}%`,
  },
  series: [
    {
      name: "Clean Power",
      type: "heatmap",
      data: [],
      label: {
        show: false,
      },
      itemStyle: {
        borderColor: colors.neutral["20"],
        borderWidth: 0,
      },
      emphasis: {
        itemStyle: {
          color: "inherit",
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.4)",
        },
      },
    },
  ],
}
let chart: EChartsType
const hours = [...Array(25).keys()].map((key) => new Date(startingYear.value, 0, 1, key).toLocaleString("en", { hour: "numeric", hour12: true }))

const renderChart = (rows: Array<LoadshapeRow>) => {
  // assume hours are sorted. get first day and then just add on.
  // TODO unit test this.

  const days = [...Array(365).keys()].map((key) => getDayOfYear(addDays(new Date(startingYear.value, 0, firstDayOfLoadshape.value), key)))
  const series = Object.values(rows).map((row: LoadshapeRow) => {
    const hourFormatted = row.datetime.toLocaleString("en", { hour: "numeric", hour12: true })
    const hourIndex = hours.indexOf(hourFormatted)
    const dayIndex = days.indexOf(getDayOfYear(row.datetime))
    const field = props.isMarket ? "marketBasedCarbonFreePercent" : "locationalCarbonFreePercent"
    return [dayIndex, hourIndex, row[field] ? Math.round(row[field]) : null] // x, y, val1, val2 {
  })

  const updatedOption = {
    xAxis: {
      ...option.xAxis,
      data: days,
    },
    yAxis: {
      ...option.yAxis,
      data: hours,
    },
    series: [
      {
        // Find series by name
        name: "Clean Power",
        data: series,
      },
    ],
  }
  chart.setOption(updatedOption)
}

onMounted(() => {
  const chartDom = document.getElementById(chartId)
  if (!chartDom) {
    return
  }
  chart = echarts.init(chartDom, undefined, {
    width: props.width,
    height: props.height,
  })
  option && chart.setOption(option)
  renderChart(props.data)
  new ResizeObserver(() => chart.resize()).observe(chartDom)
})

watch(
  () => props.data,
  (value: Array<LoadshapeRow>) => {
    renderChart(value)
  }
)
</script>
