<template>
  <input v-model="model" type="date" class="w-full" :name="name" />
</template>

<script setup lang="ts">
const model = defineModel<string | null>()
defineProps<{
  name: string
}>()
</script>
