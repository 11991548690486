import AccountsService from "@/modules/accounts/accounts.service"
import QueryService from "@/services/api/query.service"
import AuthService from "@/services/auth/auth.service"
import ApiFetcher from "@/services/api-fetcher"
import SiteService from "@/modules/sites/site.service"
import ProviderService from "@/services/api/provider.service"
import SupplierService from "@/modules/suppliers/supplier.service"
import OrdersService from "@/services/api/orders.service"
import PortfolioService from "@/services/api/portfolio.service"
import StoryService from "@/services/api/story.service"
import PaymentService from "@/services/api/payment.service"
import RegistryService from "@/services/api/registry.service"
import { loadStripe, Stripe } from "@stripe/stripe-js"
import AssetService from "@/modules/asset/asset.service"
import ListingService from "@/services/api/listing.service"
import TransactionService from "@/services/api/transaction.service"

let apiFetcher: ApiFetcher
let queryService: QueryService
let siteService: SiteService
let accountsService: AccountsService
let authService: AuthService
let providerService: ProviderService
let supplierService: SupplierService
let ordersService: OrdersService
let portfolioService: PortfolioService
let storyService: StoryService
let paymentService: PaymentService
let registryService: RegistryService
let assetService: AssetService
let listingService: ListingService
let transactionService: TransactionService

export function useServiceContainerProvider() {
  apiFetcher = new ApiFetcher()
  queryService = new QueryService(apiFetcher)
  siteService = new SiteService(apiFetcher)
  accountsService = new AccountsService(apiFetcher)
  authService = new AuthService(apiFetcher)
  providerService = new ProviderService(apiFetcher)
  supplierService = new SupplierService(apiFetcher)
  ordersService = new OrdersService(apiFetcher)
  portfolioService = new PortfolioService(apiFetcher)
  storyService = new StoryService(apiFetcher)
  paymentService = new PaymentService(apiFetcher)
  registryService = new RegistryService(apiFetcher)
  assetService = new AssetService(apiFetcher)
  listingService = new ListingService(apiFetcher)
  transactionService = new TransactionService(apiFetcher)
}

export function useApiFetcher(): ApiFetcher {
  return apiFetcher
}

export function useAuthService(): AuthService {
  return authService
}

export function useQueryService(): QueryService {
  return queryService
}

export function useAccountsService(): AccountsService {
  return accountsService
}

export function useProviderService(): ProviderService {
  return providerService
}

export function useSiteService(): SiteService {
  return siteService
}

export function useSupplierService(): SupplierService {
  return supplierService
}
export function useOrdersService(): OrdersService {
  return ordersService
}

export function usePaymentService(): PaymentService {
  return paymentService
}

export function usePortfolioService(): PortfolioService {
  return portfolioService
}

export function useStoryService(): StoryService {
  return storyService
}

export function useRegistryService(): RegistryService {
  return registryService
}

export function useAssetService(): AssetService {
  return assetService
}

export function useListingService(): ListingService {
  return listingService
}

export function useTransactionService(): TransactionService {
  return transactionService
}

export async function useStripe(): Promise<Stripe> {
  const stripe = await loadStripe(import.meta.env.VITE_STRIPE_KEY)
  if (!stripe) {
    throw new Error("Unable to load Stripe")
  }
  return stripe
}
