import { Site } from "@common/models/site"
import { defineStore } from "pinia"
import { gModelDict } from "@common/global"

export const useSiteStore = defineStore("sites", {
  state: () => {
    return {
      modelDict: gModelDict,
      site: new Site(),
      siteSummaries: new Array<Site>(),
      selectedSites: new Array<Site>(),
      selectedAssetIds: new Array<number>(),
      providers: {
        postalCode: "",
        values: new Array<any>(),
      },
      isDirty: false,
      isEditMode: false,
    }
  },
  getters: {
    getAssetDict: (state) => {
      return (assetType: string) => {
        if (Object.hasOwn(state.modelDict, assetType)) {
          return state.modelDict[assetType]
        }
        // default
        return state.modelDict["summary"]
      }
    },
  },
  actions: {
    // Load
    // async loadSites(accountId: number) {
    //   if (this.sites.length > 0 && !this.isDirty) {
    //     return
    //   }
    //   try {
    //     const sites = await this.service.listSites(accountId)
    //     this.setSites(sites)
    //     this.isDirty = false
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    // async getSite(id: number) {
    //   return await this.service.getSite(id)
    // },
    setSite(site: Site) {
      this.site = site
    },
    selectSite(site: Site) {
      const index = this.selectedSites.indexOf(site)
      if (index >= 0) {
        this.selectedSites.splice(index, 1)
        return
      }

      this.selectedSites.push(site)
    },
    resetSelectedSites() {
      this.selectedSites = []
    },
    setName(site: Site) {
      this.site.name = site.name
    },
    async setAddress(site: Site) {
      this.site.address.street = site.address.street
      this.site.address.city = site.address.city
      this.site.address.postalCode = site.address.postalCode
      this.site.address.state = site.address.state
      this.site.address.country = site.address.country
    },
    // async setGeocode(street: string, city: string, state: string, postalCode: string, country: string) {
    //   const geocode = await this.service.geocode(street, city, state, postalCode, country)
    //   this.site.location.latitude = geocode.latitude
    //   this.site.location.longitude = geocode.longitude
    // },
    // async getProviders(postalCode: string) {
    //   if (postalCode && this.providers.postalCode !== postalCode) {
    //     this.providers = {
    //       postalCode: postalCode,
    //       values: await this.service.getProviders(postalCode),
    //     }
    //   }
    //
    //   return this.providers.values
    // },
    // async create(asset: Asset): Promise<Site> {
    //   const site = {
    //     id: 0,
    //     name: asset.name,
    //     type: asset.type,
    //     address: {
    //       street: asset.street,
    //       city: asset.city,
    //       state: asset.state,
    //       country: asset.country,
    //       postalCode: asset.postalCode,
    //       longitude: asset.longitude,
    //       latitude: asset.latitude,
    //     },
    //     assets: new Array<Asset>(),
    //   } as unknown as Site
    //   try {
    //     const savedSite = await this.service.updateSite(site)
    //     this.isDirty = true
    //     return new Promise<Site>((resolve) => {
    //       resolve(savedSite)
    //     })
    //   } catch (error: any) {
    //     return new Promise<any>((resolve, reject) => {
    //       console.log(error)
    //       useMainStore().addErrorMessage(error)
    //       reject(error)
    //     })
    //   }
    // },
    // getNewSiteAsset(site: Site) {
    //   const asset = new Asset()
    //   asset.siteId = site.id | 0
    //   asset.type = ""
    //   asset.modelParameters = {}
    //   return asset
    // },
  },
})
