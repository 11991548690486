import { DateDataItem } from "@/components/visualizations/VizHelper"

export class NetZeroOverTimeData {
  name = ""
  keys = new Array<string>()
  totalConsumed = 0
  totalOffset = 0
  consumed = new Array<any>()
  consumedFuture = new Array<any>()
  consumedSelected = new Array<any>()
  produced = new Array<any>()
  producedFuture = new Array<any>()
  producedSelected = new Array<any>()
  producedPreview = new Array<any>()
}

export class Wc24LoadshapeData {
  energy = new Array<DateDataItem>()
  carbon = new Array<DateDataItem>()
  negaEnergy = new Array<DateDataItem>()
  negaCarbon = new Array<DateDataItem>()
  carbonFree = new Array<DateDataItem>()
  intensity = new Array<DateDataItem>()
}
