<template>
  <WcSegmentedButton v-model:selected="internalSelectedValue" :options="VIEW_MODE_OPTIONS" :size="size" icon-only />
</template>

<script setup lang="ts">
import { computed } from "vue"
import type { InputSize, SelectOption } from "@/components/input"
import WcSegmentedButton from "./WcSegmentedButton.vue"

enum ViewMode {
  table = "table",
  map = "map",
}

const VIEW_MODE_OPTIONS = [
  { value: ViewMode.table, icon: "wc-carbon:list", label: "Table" },
  { value: ViewMode.map, icon: "wc-carbon:map", label: "Map" },
]

defineProps<{ size?: InputSize }>()

const modelValue = defineModel<ViewMode>()

const internalSelectedValue = computed({
  get: () => VIEW_MODE_OPTIONS.find((option) => option.value === modelValue.value) || null,
  set: (newValue: SelectOption<ViewMode> | null) => {
    if (newValue) {
      modelValue.value = newValue.value
    }
  },
})
</script>
