<template>
  <div class="mt-4">
    <slot name="accountTypeSelectionLabel">
      <div class="text-body-2 text-blue-70">I am purchasing as a:</div>
    </slot>
    <div class="switch-field">
      <input id="radio-one" v-model="accountType" type="radio" name="switch-one" :value="AccountType.individual" />
      <label for="radio-one">Individual</label>
      <input id="radio-two" v-model="accountType" type="radio" name="switch-one" :value="AccountType.organization" />
      <label for="radio-two">Company/Organization</label>
    </div>
    <div v-if="accountType == AccountType.organization && organizationAccounts.length" class="my-6 w-full max-w-[590px]">
      <div class="text-body-2 text-blue-70">Select an organization</div>
      <Listbox v-model="selectedAccount">
        <div class="text-body-2 relative mt-1">
          <ListboxButton
            class="focus-visible:ring-2/75 relative w-full cursor-pointer rounded-md border border-blue-90 bg-white py-3 pl-3 pr-10 text-left focus:outline-none focus-visible:border-highlight focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-highlight">
            <span v-if="selectedAccount" class="block truncate">{{ selectedAccount?.name }}</span>
            <div v-if="currentState === State.creating" class="text-blue-70">
              <span class="block truncate pl-6 font-normal">Create a new organization</span>
              <span class="absolute inset-y-0 left-0 flex items-center pl-3"><Icon icon="wc-carbon:add" /></span>
            </div>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-0">
              <ChevronIcon class="size-10 text-blue-70" aria-hidden="true" />
            </span>
          </ListboxButton>
          <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions
              class="text-body-3 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
              <ListboxOption
                v-for="organization in organizationAccounts"
                v-slot="{ selected }"
                :key="organization.id"
                :value="organization"
                as="template">
                <li class="relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-neutral-20">
                  <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ organization.name }}</span>
                  <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3"><Icon icon="wc-carbon:checkmark" /></span>
                </li>
              </ListboxOption>
              <ListboxOption v-slot="{ selected }" :value="{} as Account" as="template">
                <li class="relative cursor-pointer select-none py-2 pl-10 pr-4 text-blue-70 hover:bg-neutral-20">
                  <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">Create a new organization</span>
                  <span class="text-amber-600 absolute inset-y-0 left-0 flex items-center pl-3"><Icon icon="wc-carbon:add" /></span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <form v-if="currentState == State.editing || currentState == State.creating" class="my-6 w-full max-w-[590px]" @submit.prevent="handleSubmit">
      <TextInput
        v-model="legalName"
        :label="`Legal name of ${accountType}`"
        type="text"
        name="legal-full-name"
        class="w-full"
        required
        max-length="1000"
        :data-attribute="{ name: 'data-cy', value: 'legal-full-name-input' }" />
      <div v-if="props.includeSubmitButton">
        <WcButton
          v-if="currentState == State.creating"
          text="Create organization"
          type="submit"
          :is-disabled="!canSubmit"
          class="mt-4 w-full sm:w-auto" />
        <div v-else class="mt-4 flex w-full flex-wrap gap-4">
          <WcButton
            v-if="!(accountType === AccountType.individual && accountHasDefaultName)"
            variant="secondary"
            text="Cancel"
            class="order-2 grow sm:grow-0"
            @click="cancelEditingLegalName"
            >Cancel</WcButton
          >
          <WcButton
            :text="accountHasDefaultName ? 'Continue' : 'Update account'"
            type="submit"
            :is-disabled="!canSubmit"
            class="grow sm:grow-0 md:order-1"
            >Continue</WcButton
          >
        </div>
      </div>
    </form>
    <div v-else class="wc-homepage-grid my-6">
      <div class="col-span-6">
        <div class="text-field-label text-blue-70">Legal name of {{ accountType }}</div>
        <div class="flex items-baseline gap-8">
          <div class="text-body-2 mt-2">{{ props.modelValue?.name }}</div>
          <button class="text-body-2 text-blue-70 underline" data-cy="edit-legal-name-button" @click="startEditingLegalName">Edit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useAuthService, useAccountsService } from "@/services/service-container"
import { useMainStore } from "@/store"
import { Account, AccountType } from "@common/models/models"
import { WcButton } from "@/components/button"
import TextInput from "@/components/ui/TextInput.vue"
import ChevronIcon from "@/components/icon/ChevronIcon.vue"
import { Icon } from "@iconify/vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import _ from "lodash"

const store = useMainStore()
const authService = useAuthService()
const accountsService = useAccountsService()

interface Props {
  modelValue: Account
  includeSubmitButton?: boolean
}
const props = defineProps<Props>()
const emit = defineEmits(["update:modelValue", "update:isEditing"])

const emailAddress = computed(() => store.auth.user?.username)

/* Individual | Company/Organization */
const individualAccount = computed(() => store.accounts.filter((account) => account.type === AccountType.individual)[0])
const organizationAccounts = computed(() => store.accounts.filter((account) => account.type === AccountType.organization))
const accountType = ref<string>(props.modelValue?.type)
watch(accountType, (newType) => {
  if (newType === AccountType.individual) {
    emit("update:modelValue", individualAccount.value)
  } else if (newType === AccountType.organization && organizationAccounts.value.length) {
    emit("update:modelValue", organizationAccounts.value[0])
  } else {
    emit("update:modelValue", {})
  }
})

/**
 * Account selection
 * The Listbox component also needs the modelValue, but we can't mutate the modelValue prop in this component.
 * So, this copies and watches modelValue to selectedAccount and passes it as a v-model to the Listbox component.
 * TODO: This could be solved by using the new defineModel() macro in Vue 3.4
 */
const selectedAccount = ref<Account>(props.modelValue)
watch(
  () => props.modelValue,
  (newSelectedAccount) => {
    selectedAccount.value = newSelectedAccount
    initializeAccountEditor()
  }
)
watch(selectedAccount, async (newSelectedAccount) => {
  emit("update:modelValue", newSelectedAccount)
  // Switch account to the newly selected account
  if (!_.isEmpty(newSelectedAccount)) await authService.switchAccount(newSelectedAccount.id)
})

/* Account editing */
enum State {
  selecting = 0,
  editing = 1,
  creating = 2,
}
const currentState = ref<State>(State.selecting)
const legalName = ref<string>("")
const accountHasDefaultName = computed(() => {
  return accountType.value === AccountType.individual && store.auth.user?.username === props.modelValue?.name
})
const startEditingLegalName = () => {
  currentState.value = State.editing
  legalName.value = accountHasDefaultName.value ? "" : props.modelValue.name
  emit("update:isEditing", true)
}
const cancelEditingLegalName = () => {
  currentState.value = State.selecting
  emit("update:isEditing", false)
}
const startCreatingOrganization = () => {
  currentState.value = State.creating
  legalName.value = ""
  emit("update:isEditing", true)
}
const initializeAccountEditor = () => {
  if (accountType.value === AccountType.individual && accountHasDefaultName.value) {
    startEditingLegalName()
  } else if (_.isEmpty(props.modelValue)) {
    startCreatingOrganization()
  } else {
    cancelEditingLegalName()
  }
}
const canSubmit = computed(() => {
  return currentState.value === State.selecting || (currentState.value >= State.editing && legalName.value.length > 0)
})
const handleSubmit = async () => {
  if (currentState.value === State.selecting) {
    return props.modelValue
  } else if (currentState.value === State.editing) {
    const newAccount = await accountsService.updateAccountName(props.modelValue, legalName.value)
    if (newAccount) {
      store.accounts = await accountsService.listMyAccounts()
      emit("update:modelValue", newAccount)
      return newAccount
    }
  } else if (currentState.value === State.creating) {
    if (!emailAddress.value) throw new Error()
    let newAccount = await accountsService.addAccount(emailAddress.value)
    newAccount = await accountsService.updateAccountName(newAccount, legalName.value)
    if (newAccount) {
      store.accounts = await accountsService.listMyAccounts()
      emit("update:modelValue", newAccount)
      return newAccount
    }
  }
}

defineExpose({ canSubmit, handleSubmit })

onMounted(() => {
  initializeAccountEditor()
})
</script>
