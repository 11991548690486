import { ref } from "vue"
import posthog from "posthog-js"
import { getEnvironment } from "@/environment"

const { DEPLOYMENT } = getEnvironment()

export default function useFeature(key: string, options: { valueInDev: boolean }) {
  const { valueInDev = false } = options || {}
  const enabled = ref<boolean | null>(null)

  // Add a slight delay in dev to simulate posthog request
  if (DEPLOYMENT === "local") {
    setTimeout(() => {
      enabled.value = valueInDev
    }, 200)
  }

  posthog.onFeatureFlags(() => {
    enabled.value = posthog.isFeatureEnabled(key) ?? false
  })

  return enabled
}
