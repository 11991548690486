import ApiFetcher from "@/services/api-fetcher"
import { Account, AccountContactInfo } from "@common/models/models"

export default class AccountsService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  async listAllAccounts(options?: { hasDevices?: boolean }): Promise<Array<Account>> {
    const endpoint = "/accounts"
    const params = options ?? {}
    try {
      const result = await this.fetcher.httpGet<Array<Account>>(endpoint, params)
      return new Promise<Array<Account>>((resolve) => {
        resolve(result)
      })
    } catch (error) {
      console.log(error)
      return new Promise<any>((resolve, reject) => {
        reject(error)
      })
    }
  }

  async listMyAccounts(): Promise<Array<Account>> {
    return await this.fetcher.httpGet<Array<Account>>("/users/me/accounts", {})
  }

  async getAccount(accountId: number): Promise<Account> {
    const endpoint = `/accounts/${accountId}`
    const params = {}
    return await this.fetcher.httpGet<Account>(endpoint, params)
  }

  async updateAccount(account: Account): Promise<Account> {
    const endpoint = `/accounts/${account.id}`
    return await this.fetcher.httpPut(endpoint, account)
  }
  async updateAccountContact(account: Account): Promise<Account> {
    const endpoint = `/accounts/${account.id}/contact-info`
    return await this.fetcher.httpPut(endpoint, account.contact_info)
  }

  async updateAccountName(account: Account, accountName: string): Promise<Account> {
    const endpoint = `/accounts/${account.id}/name`
    return await this.fetcher.httpPut(endpoint, { name: accountName })
  }

  async addAccount(name: string, contactInfo?: AccountContactInfo): Promise<Account> {
    const endpoint = `/accounts`
    return await this.fetcher.httpPost<Account>(endpoint, { name: name, contact_info: contactInfo })
  }

  async deactivateAccount(accountId: number) {
    const endpoint = `/accounts/${accountId}/deactivate`
    return await this.fetcher.httpPost<Account>(endpoint, {})
  }

  async reactivateAccount(accountId: number) {
    const endpoint = `/accounts/${accountId}/reactivate`
    return await this.fetcher.httpPost<Account>(endpoint, {})
  }

  async deleteAccount(accountId: number) {
    const endpoint = `/accounts/${accountId}`
    return await this.fetcher.httpDelete(endpoint, {})
  }

  async addUser(accountId: number, userEmail: string): Promise<Account> {
    const endpoint = `/accounts/${accountId}/users`
    return await this.fetcher.httpPut<Account>(endpoint, { email: userEmail })
  }

  async removeUser(accountId: number, userId: string): Promise<Account> {
    const endpoint = `/accounts/${accountId}/users/${userId}`
    return await this.fetcher.httpDelete<Account>(endpoint)
  }

  async sendEmailCampaign(userId: string): Promise<Account> {
    const endpoint = `/users/${userId}/add-to-mailing-list`
    return await this.fetcher.httpPut(endpoint, {})
  }

  async uploadLogo(accountId: number, file: any): Promise<Account> {
    const endpoint = `/accounts/${accountId}/logo`
    return await this.fetcher.httpUpload(endpoint, { logo: file }, file)
  }
}
