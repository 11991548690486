import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import * as Sentry from "@sentry/vue"
import { getEnvironment } from "./environment"
import { gtmService } from "./services/gtm.service"
import Toast, { PluginOptions, POSITION } from "vue-toastification"
import "./static/styles/global.scss"
import "@common/static/styles/index.scss"
import "vue-toastification/dist/index.css"
import "filepond/dist/filepond.min.css"
import "flatpickr/dist/flatpickr.css"
import "mapbox-gl/dist/mapbox-gl.css"
import { addCollection } from "@iconify/vue"
import posthog from "posthog-js"
import PrimeVue from "primevue/config"
import "primevue/resources/themes/lara-light-blue/theme.css"

import carbonIconSet from "@iconify/json/json/carbon.json"

import icons from "./icons.json"

const { DEPLOYMENT, API_BASE_URL } = getEnvironment()

// Report the SHA of the currently-deployed commit
console.info(`Version: ${__APP_VERSION__}`)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

if (import.meta.env.VITE_POSTHOG_KEY) {
  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: "https://app.posthog.com",
    opt_out_capturing_by_default: true,
    opt_out_persistence_by_default: true,
    persistence: "cookie",
  })
}

// TODO this is a really large import. We are writing the icIcons to a json file.
// TODO parse out only the icons we need and write them to that json file.
// Import the icons for offline use
carbonIconSet.prefix = "wc-carbon"
addCollection(carbonIconSet)
addCollection(icons)
/* This code is used to download only the icons that we need. Write them to the icons.json file
// import { getIcons } from "@iconify/utils/lib/icon-set/get-icons"
// import icIconSet from "@iconify/json/json/ic.json"
// import { gModelDict } from "@common/global"
// const assetIcons = Object.values(gModelDict).map((m: any) => m.icon.split(":")[1])
// const icIconSetFiltered = getIcons(icIconSet as IconifyJSON, assetIcons)
// if (icIconSetFiltered) {
//   icIconSetFiltered.prefix = "wc-ic"
//   addCollection(icIconSetFiltered)
// }
*/
const toastOptions: PluginOptions = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 8,
  newestOnTop: true,
  icon: false,
  toastClassName: "wc-toast",
  position: POSITION.TOP_RIGHT,
  hideProgressBar: true,
}

const app = createApp(App).use(Toast, toastOptions).use(pinia).use(router).use(PrimeVue)

const vueGtmPlugin = gtmService.initialize(import.meta.env.VITE_GOOGLE_TAG_ID, router)
if (vueGtmPlugin) app.use(vueGtmPlugin)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_WEB_DSN,
  environment: DEPLOYMENT,
  release: __APP_VERSION__,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.thirdPartyErrorFilterIntegration({
      // Specify the application keys that you specified in the Sentry bundler plugin
      filterKeys: ["wattcarbon-source-key"],

      // Defines how to handle errors that contain third party stack frames.
      // Possible values are:
      // - 'drop-error-if-contains-third-party-frames'
      // - 'drop-error-if-exclusively-contains-third-party-frames'x
      // - 'apply-tag-if-contains-third-party-frames'
      // - 'apply-tag-if-exclusively-contains-third-party-frames'
      behaviour: "apply-tag-if-contains-third-party-frames",
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACE_RATE),

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [API_BASE_URL],

  // https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // this tells the Vue integration to call the original Vue error logger after
  // sending the event (so we still see something in the console)
  logErrors: true,

  // ignore errors coming from code that isn't ours
  denyUrls: [/^chrome-extension:\/\//i],
})

app.mount("#app")
