import { Site } from "@common/models/site"
import { SiteAsset } from "@common/models/siteAsset"
import { useSiteStore } from "@/modules/sites/site.state"
import { Order } from "@common/models/order"
import { LoadshapeRow, Loadshape } from "@common/models/loadshape"
import { format, addMonths, startOfYear } from "date-fns"
import { NetZeroOverTimeData } from "@/components/visualizations/visualizationModels"
import { VizDateFormat } from "@/components/visualizations/VizHelper"

export class BadgeHelper {
  // TODO move this to the global dict
  static getClass(type: string) {
    switch (type) {
      case "solar":
        return "solar"
      case "wind":
        return "wind"
      default:
        return "building"
    }
  }
}

export class GraphHelper {
  static getYearMonths(startDate: Date, endDate: Date) {
    const yearMonths = []
    while (startDate <= endDate) {
      yearMonths.push(format(startDate, "yyyy-MM"))
      startDate = addMonths(startDate, 1)
    }
    return yearMonths
  }

  static getYearToDateMonths() {
    return this.getYearMonths(startOfYear(new Date()), addMonths(new Date(), -1))
  }

  static getForecastYearMonths() {
    return this.getYearMonths(new Date(), addMonths(new Date(), 12))
  }

  static getSummaryYearMonths() {
    const startDate = addMonths(startOfYear(new Date()), -1)
    return this.getYearMonths(startDate, addMonths(startDate, 24))
  }

  static getGraphValues(hours: Array<LoadshapeRow>, isEnergy = true) {
    const fieldValue = isEnergy ? "marketBasedEnergySumMwh" : "marketBasedCarbonSumKgCo2"
    return hours.map((h: LoadshapeRow) => {
      return {
        value: h[fieldValue],
        label: format(h.datetime, VizDateFormat.monthYear),
        date: h.datetime,
      }
    })
  }

  static getGraphData(assetLoadshape: Loadshape, orderLoadshape: Loadshape, isEnergy = true) {
    const data = new NetZeroOverTimeData()
    data.name = isEnergy ? "MWh" : "tCO₂"
    if (Loadshape.getSourceCount(assetLoadshape) > 0) {
      data.consumed = GraphHelper.getGraphValues(assetLoadshape.rows, isEnergy)
    }
    if (Loadshape.getSourceCount(orderLoadshape) > 0) {
      data.produced = GraphHelper.getGraphValues(orderLoadshape.rows, isEnergy)
    }

    return data
  }
}

export class AssetTableRow {
  label = ""
  icon = ""
  class = ""
  name = ""
  type = ""
  status = ""
  carbonFreePercent = 0
  carbonSumTonnesCo2e = 0
  carbonAvgDailyTonnesCo2e = 0
  energySumMwh = 0
  energyAvgDailyMwh = 0
  carbonRatio = 0
  nValuesAggregated = 0
  asset = new SiteAsset()

  static fromLoadshape(loadshape: Loadshape): AssetTableRow {
    return {
      carbonFreePercent: loadshape.marketBasedHourlyCarbonFreePercent,
      carbonSumTonnesCo2e: loadshape.marketBasedCarbonSumKgCo2,
      energySumMwh: loadshape.marketBasedEnergySumMwh,
      carbonRatio: loadshape.carbonRatio,
    } as AssetTableRow
  }
}

export class SiteOrderTableRow {
  label = ""
  icon = ""
  class = ""
  name = ""
  type = ""
  status = ""
  carbonFreePercent = 0
  carbonSumTonnesCo2e = 0
  carbonAvgDailyTonnesCo2e = 0
  energySumMwh = 0
  energyAvgDailyMwh = 0
  carbonRatio = 0
  nValuesAggregated = 0
  site = new Site()
  order = new Order()
  assets = new Array<AssetTableRow>()
}

export class TableHelper {
  static getRowSum = (rows: Array<AssetTableRow | SiteOrderTableRow>): SiteOrderTableRow => {
    const total = rows.reduce(
      (sum: any, row: any) => {
        if (isNaN(row.carbonSumTonnesCo2e)) {
          return sum
        }
        const carbonFreeEnergy = row.energySumMwh < 0 ? 0 : row.energySumMwh * row.carbonFreePercent
        const consumedEnergy = row.energySumMwh < 0 ? 0 : row.energySumMwh
        return {
          carbonFreePercent: 0,
          carbonFreeEnergySumMwh: sum.carbonFreeEnergySumMwh + carbonFreeEnergy,
          consumedEnergySumMwh: sum.consumedEnergySumMwh + consumedEnergy,
          carbonSumTonnesCo2e: sum.carbonSumTonnesCo2e + row.carbonSumTonnesCo2e,
          carbonAvgTonnesCo2e: 0,
          carbonAvgDailyLbs: 0,
          energySumMwh: sum.energySumMwh + row.energySumMwh,
          energyAvgDailyMwh: 0,
          energyAvgMwh: 0,
          nValuesAggregated: sum.nValuesAggregated + row.nValuesAggregated,
          name: row.name,
          label: row.label,
          icon: row.icon,
          class: row.class,
          status: "Site",
          site: row.site,
          order: row.order,
        } as unknown as SiteOrderTableRow
      },
      {
        carbonFreePercent: 0,
        carbonFreeEnergySumMwh: 0,
        consumedEnergySumMwh: 0,
        carbonSumTonnesCo2e: 0,
        carbonAvgTonnesCo2e: 0,
        carbonAvgDailyTonnesCo2e: 0,
        energySumMwh: 0,
        energyAvgMwh: 0,
        energyAvgDailyMwh: 0,
        nValuesAggregated: 0,
        name: "",
        label: "",
        icon: "",
        class: "",
        status: "Site",
        site: undefined,
        order: undefined,
        assetRows: new Array<AssetTableRow>(),
      } as unknown as SiteOrderTableRow
    )
    total.carbonFreePercent = total.energySumMwh === 0 ? 0 : (total.carbonFreeEnergySumMwh / total.consumedEnergySumMwh).toFixed(1)
    // total.carbonSumTonnesCo2e = (total.carbonSumTonnesCo2e / total.nValuesAggregated).toFixed(1)
    total.carbonAvgDailyTonnesCo2e = (total.carbonAvgTonnesCo2e * 24).toFixed(1)
    total.energyAvgMwh = (total.energySumMwh / total.nValuesAggregated).toFixed(1)
    total.energyAvgDailyMwh = (total.energyAvgMwh * 24).toFixed(1)
    total.carbonRatio = total.energySumMwh !== 0 ? Number((total.carbonSumTonnesCo2e / total.energySumMwh).toFixed(2)) : 0
    total.carbonRatio = total.energySumMwh < 0 ? total.carbonRatio * -1 : total.carbonRatio
    return total
  }

  static toRow = (site: Site): AssetTableRow => {
    const assetRows = site.assets.map((r: SiteAsset) => {
      const dict = useSiteStore().getAssetDict(r.type) // Global dict
      const row = new AssetTableRow()
      row.asset = r
      row.name = r.name
      row.label = dict.label
      row.icon = dict.icon
      row.class = BadgeHelper.getClass(r.type)
      row.carbonFreePercent = Number(r.modelSummary.totals?.energySumMwh) < 0 ? 100 : Number(r.modelSummary.totals?.carbonFreePercent) * 100
      row.carbonSumTonnesCo2e = Number(r.modelSummary.totals?.carbonSumTonnesCo2e)
      row.carbonAvgDailyTonnesCo2e = Number((Number(r.modelSummary.totals?.carbonAvgTonnesCo2e) * 24).toFixed(1))
      row.energySumMwh = Number(r.modelSummary.totals?.energySumMwh)
      row.energyAvgDailyMwh = Number((Number(r.modelSummary.totals?.energyAvgMwh) * 24).toFixed(1))
      row.nValuesAggregated = Number(r.modelSummary.totals?.nValuesAggregated)
      row.carbonRatio = row.energySumMwh !== 0 ? Number((row.carbonSumTonnesCo2e / row.energySumMwh).toFixed(2)) : 0
      return row as AssetTableRow
    })

    const assetTotal = this.getRowSum(assetRows)

    return {
      id: site.id,
      name: site.name,
      type: site.assets.length > 0 ? site.assets[0].type : "",
      carbonFreePercent: assetTotal.carbonFreePercent,
      carbonSumTonnesCo2e: assetTotal.carbonSumTonnesCo2e,
      carbonAvgDailyTonnesCo2e: assetTotal.carbonAvgDailyTonnesCo2e,
      energySumMwh: assetTotal.energySumMwh,
      energyAvgDailyMwh: assetTotal.energyAvgDailyMwh,
      carbonRatio: assetTotal.carbonRatio,
      nValuesAggregated: assetTotal.nValuesAggregated,
      label: assetTotal.label,
      icon: assetTotal.icon,
      class: assetTotal.class,
      site: site,
      assets: assetRows,
    } as unknown as AssetTableRow
  }
  static orderToRow = (order: Order): SiteOrderTableRow => {
    const siteStore = useSiteStore()
    const assetType = order.portfolio.buyerAgreementTemplate
    const dict = siteStore.getAssetDict(assetType)
    const row = {
      id: order.id,
      name: order.portfolio.name,
      type: order.portfolio.buyerAgreementTemplate,
      label: dict.label,
      icon: dict.icon,
      class: BadgeHelper.getClass(order.portfolio.buyerAgreementTemplate),
      status: order.status,
      carbonFreePercent: 0,
      carbonSumTonnesCo2e: 0,
      carbonAvgDailyTonnesCo2e: 0,
      energySumMwh: 0,
      energyAvgDailyMwh: 0,
      carbonRatio: 0,
      nValuesAggregated: 0,
      order: order,
    } as unknown as SiteOrderTableRow

    row.carbonRatio = row.energySumMwh !== 0 ? Math.abs(Number((row.carbonSumTonnesCo2e / row.energySumMwh).toFixed(2))) * -1 : 0

    return row
  }
}
