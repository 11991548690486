<template>
  <AppPage>
    <AppPageHeader :backlink-route="{ name: 'wc-assets-asset-timeseries-upload' }" backlink-text="Back to Upload Asset Time Series"
      >Review asset time series</AppPageHeader
    >
    <AppPageContent>
      <div class="max-w-[780px] pb-8 pt-4">We found {{ numEntries }} rows of measurements based on the uploaded file.</div>
      <AssetTimeSeriesUploadErrorInfo class="mt-4 border-y border-neutral-30 py-6" />
      <div v-if="numErrors === 0" class="my-8">
        <div v-if="numEntries > 0">
          <table class="text-body-1 w-full text-left">
            <tr>
              <th class="text-body-1 mb-3 w-80 pr-2">Date range</th>
              <td class="text-body-1 my-10">{{ dateRangeStart?.toLocaleDateString() }} - {{ dateRangeEnd?.toLocaleDateString() }}</td>
            </tr>
            <tr>
              <th class="text-body-1 mb-3 w-80 pr-2">Total energy</th>
              <td class="text-body-1 my-10">
                {{ getFormattedEacQuantity(totalEnergy, EacMeasurementParameter.Electricity) }}
              </td>
            </tr>
            <tr>
              <th class="text-body-1 mb-3 w-80 pr-2">Average per day</th>
              <td class="text-body-1 my-10">
                {{ getFormattedEacQuantity(averageEnergyDaily, EacMeasurementParameter.Electricity) }}
              </td>
            </tr>
            <tr>
              <th class="text-body-1 mb-3 w-80 pr-2 align-top">Average per row</th>
              <td class="text-body-1 my-10 align-top">
                {{ getFormattedEacQuantity(averageEnergyPerEntry, EacMeasurementParameter.Electricity) }}
              </td>
            </tr>
          </table>
          <div class="mt-4 max-h-[calc(100vh-50rem)] min-h-[400px] overflow-scroll border-b border-neutral-30">
            <WcTable
              :data="entries"
              :columns="[
                { key: 'hour', label: 'Date/time', align: 'left' },
                { key: 'value', label: 'Measurement', align: 'right' },
                { key: 'metadata', label: 'Metadata', align: 'left' },
              ]"
              row-id-field="id"
              table-class="min-w-main-content mx-auto text-body-1 asset-timeseries-table"
              th-class="text-left py-4 top-0 bg-white sticky"
              td-class="px-3 py-1 align-baseline min-w-24"
              :sortable="true">
              <template #value="{ row }">
                <div>
                  {{ getFormattedEacQuantity(row.value, EacMeasurementParameter.Electricity) }}
                </div>
              </template>
            </WcTable>
          </div>
        </div>
      </div>

      <div class="mt-4 flex flex-wrap gap-2">
        <WcButton
          :icon="isSubmittingAssetTimeSeries ? 'spinner' : undefined"
          :is-disabled="!isSubmissionValid || isSubmittingAssetTimeSeries"
          text="Submit Time Series Data"
          @click="submitAssetTimeSeries" />
        <WcButton variant="secondary" :is-disabled="isSubmittingAssetTimeSeries" text="Upload Again" @click="routeToUploadAgain" />
      </div>
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useRouter } from "vue-router"
import { useToast } from "vue-toastification"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import { WcButton } from "@/components/button"
import WcTable from "@/components/WcTable/WcTable.vue"
import { useAssetService } from "@/services/service-container"
import { useAssetTimeSeriesStore } from "./assetTimeSeries.state"
import AssetTimeSeriesUploadErrorInfo from "./components/AssetTimeSeriesUploadErrorInfo.vue"

function getDateRange(dates: string[]): { start: Date | null; end: Date | null; numDays: number } {
  const validDates = dates.map((dateStr) => new Date(dateStr)).filter((date) => !isNaN(date.getTime()))

  if (validDates.length === 0) {
    return { start: null, end: null, numDays: 0 }
  }

  const start = new Date(Math.min(...validDates.map((date) => date.getTime())))
  const end = new Date(Math.max(...validDates.map((date) => date.getTime())))
  const MS_PER_DAY = 1000 * 60 * 60 * 24
  const numDays = Math.ceil((end.getTime() - start.getTime()) / MS_PER_DAY)

  return { start, end, numDays }
}

const assetService = useAssetService()
const assetTimeSeriesStore = useAssetTimeSeriesStore()
const router = useRouter()
const toast = useToast()

const props = defineProps<{ assetId: number }>()

const entries = assetTimeSeriesStore.entries ?? []
const isSubmissionValid = assetTimeSeriesStore.isSubmissionValid
const numErrors = assetTimeSeriesStore.numErrors

const isSubmittingAssetTimeSeries = ref<boolean>(false)

const numEntries = entries.length

const totalEnergy = entries.reduce((acc, entry) => acc + Number(entry.value || 0), 0)
const { start: dateRangeStart, end: dateRangeEnd, numDays } = getDateRange(entries.map((entry) => entry.hour))
const averageEnergyDaily = totalEnergy / numDays
const averageEnergyPerEntry = totalEnergy / numEntries

const submitAssetTimeSeries = async () => {
  if (!assetTimeSeriesStore.assetTimeSeriesFile || !assetTimeSeriesStore.assetTimeSeriesFile || !assetTimeSeriesStore.assetTimeSeriesInputs) {
    console.error("No file uploaded")
    return
  }

  isSubmittingAssetTimeSeries.value = true
  try {
    const results = await assetService.uploadAssetTimeSeries(assetTimeSeriesStore.assetTimeSeriesInputs)
    if (results.length === 0) {
      throw new Error("No asset measurements were added")
    } else if (results.length === 1) {
      toast.success(`1 asset measurement was added`)
    } else {
      toast.success(`${results.length} asset measurement rows were added`)
    }
    assetTimeSeriesStore.$reset()
    router.push({ name: "wc-assets-asset", params: { assetId: props.assetId.toString() } })
  } catch (error) {
    if (error instanceof Error) {
      toast.error(`There was a problem uploading asset time series\n${error.message}`)
    }
  }
  isSubmittingAssetTimeSeries.value = false
}

const routeToUploadAgain = () => {
  router.push({ name: "wc-assets-asset-timeseries-upload" })
}
</script>

<style lang="scss">
// Override table heading to show the bottom border as a box-shadow when sticky
.asset-timeseries-table {
  thead {
    border-bottom-width: 0 !important;
  }
  th {
    box-shadow: inset 0 -1px theme("colors.neutral.30");
  }
}
</style>
