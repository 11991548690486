<template>
  <AppPage>
    <section class="bg-background-sagelight">
      <AppPageHeader :backlink-route="{ name: 'wc-enterprise-dashboard' }" backlink-text="Back to Dashboard" class="-mb-9" show-account-context>
        <span class="text-subheading-large-bold"> EAC Management </span>
        <template #description>
          <div class="text-body-2">
            View your OpenEAC Exchange transactions, and view, retire, and report on your Energy Attribute Certificates (EACs). Each EAC represents a
            unique claim to the environmental benefits of an energy decarbonization project. This includes EACs purchased from suppliers as well as
            any EACs that were minted from assets you've registered.
          </div>
          <WcButton variant="secondary" size="small" text="Learn more" :href="`${WWW_BASE_URL}/faq#eacs`" class="my-4" />
        </template>
      </AppPageHeader>

      <div class="wc-page-container w-full">
        <div class="w-3/4">
          <EacBalanceSummary />
        </div>
        <nav class="text-subheading-2 mt-10 flex flex-row">
          <router-link class="tab" :to="{ name: 'wc-eacs-select-eacs' }">Select EACs</router-link>
          <router-link class="tab" :to="{ name: 'wc-eacs-transactions' }">Transactions</router-link>
        </nav>
      </div>
    </section>

    <div class="wc-page-container mt-9 w-full">
      <router-view></router-view>
    </div>
  </AppPage>
</template>

<script setup lang="ts">
import { WcButton } from "@/components/button"
import { AppPage, AppPageHeader } from "@/components/layout"
import { getEnvironment } from "@/environment"
import EacBalanceSummary from "@/modules/accounts/components/EacBalanceSummary.vue"

const { WWW_BASE_URL } = getEnvironment()
</script>

<style scoped lang="scss">
.tab {
  @apply py-2 px-6;
  &:first-of-type:not(:only-of-type) {
    @apply -ml-6;
  }
}

.router-link-active {
  @apply border-b-2 border-sagetone;
}
</style>
