import { VueGtmPlugin, createGtm, useGtm } from "@gtm-support/vue-gtm"
import { Router } from "vue-router"

/**
 * Service to manage Google Tag Manager (GTM) tracking.
 * This service initializes the GTM Data Layer and Vue GTM Plugin.
 * It does not load the GTM script and start tracking until user consent is granted.
 */
class GtmService {
  gtmId?: string
  vueGtmPlugin?: VueGtmPlugin

  constructor() {
    return
  }
  /**
   * Initializes the GTM Data Layer. If a Vue Router is provided, it will initialize and return the Vue GTM Plugin.
   * @param gtmId The Google Tag Manager ID, e.g. "AW-1234567890"
   * @param router The Vue Router instance
   * @returns The Vue GTM Plugin instance
   */
  public initialize(gtmId: string, router?: Router) {
    if (!gtmId) return
    this.gtmId = gtmId

    window.dataLayer = window.dataLayer || []
    function gtag(...args: any) {
      if (!window.dataLayer) return
      window.dataLayer.push(args)
    }
    gtag("consent", "default", {
      ad_user_data: "denied",
      ad_personalization: "denied",
      ad_storage: "denied",
      analytics_storage: "denied",
      wait_for_update: 500,
    })
    gtag("js", new Date())
    gtag("config", gtmId)

    if (router) {
      const vueGtmParams = {
        id: gtmId,
        source: `https://www.googletagmanager.com/gtag/js`,
        enabled: false, // Plugin is disabled until gtm.enable(true) is called after it is determined that user has consented to tracking
        debug: false, // Useful to debug events and page changes. Should be set to false in production.
        loadScript: true,
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: [], // Don't trigger events for specified router names (optional)
      }
      const vueGtmPlugin = createGtm(vueGtmParams)
      this.vueGtmPlugin = vueGtmPlugin
      return vueGtmPlugin
    }
  }

  /**
   * Grant user consent to tracking and load the GTM script.
   * initialize() must be called before calling this method.
   */
  public grantConsent() {
    // Update the GTM Data Layer with user consent
    window.dataLayer = window.dataLayer || []
    function gtag(...args: any[]) {
      if (!window.dataLayer) return
      window.dataLayer.push(args)
    }
    gtag("consent", "update", {
      ad_user_data: "granted",
      ad_personalization: "granted",
      ad_storage: "granted",
      analytics_storage: "granted",
    })

    if (this.vueGtmPlugin) {
      useGtm()?.enable(true)
    } else {
      // Inject the GTM script into the DOM
      const script = document.createElement("script")
      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.gtmId}`
      document.head.appendChild(script)
    }
  }

  public trackEvent(eventName: string, eventParams: object) {
    useGtm()?.trackEvent({
      event: eventName,
      ...eventParams,
    })
  }
}

export const gtmService = new GtmService()
