export class DashboardGraphStackData {
  monthLabels: string[]
  dateRanges: Date[][]
  locationalCarbonFreeActualMWh: number[]
  locationalCarbonFreeForecastedMWh: number[]
  marketCarbonFreeActualMWh: number[]
  marketCarbonFreeForecastedMWh: number[]
  marketCarbonFreeProductionActualMWh: number[]
  marketCarbonFreeProductionForecastedMWh: number[]
  netCarbonBasedActualMWh: number[]
  netCarbonBasedForecastedMWh: number[]
  constructor() {
    this.monthLabels = []
    this.dateRanges = []
    this.locationalCarbonFreeActualMWh = []
    this.locationalCarbonFreeForecastedMWh = []
    this.marketCarbonFreeActualMWh = []
    this.marketCarbonFreeForecastedMWh = []
    this.marketCarbonFreeProductionActualMWh = []
    this.marketCarbonFreeProductionForecastedMWh = []
    this.netCarbonBasedActualMWh = []
    this.netCarbonBasedForecastedMWh = []
  }
}

export const formatNumericalFigure = (input: number, options: { isNegativeZero?: boolean; precision?: number; round?: boolean } = {}) => {
  const precision = options.precision !== undefined ? options.precision : 3
  const isNegativeZero = options.isNegativeZero !== undefined ? options.isNegativeZero : false
  const round = options.round !== undefined ? options.round : false
  if (input === 0) {
    return isNegativeZero ? "-0" : "0"
  } else if (Math.abs(input) >= 1000000) {
    return parseFloat((input / 1000000).toPrecision(precision)) + "M"
  } else if (Math.abs(input) >= 1000) {
    return parseFloat((input / 1000).toPrecision(precision)) + "K"
  } else {
    return parseFloat((round ? Math.round(input) : input).toPrecision(precision)).toString()
  }
}

export const getYearMonth = (datetime: Date) => {
  return `${datetime.getFullYear()}-${(datetime.getMonth() + 1).toString().padStart(2, "0")}`
}

export const isMarketAssetType = (assetType: string) => {
  return assetType === "market_based_production"
}

export const getMarketAssetTypeLabel = (assetType: string) => {
  if (assetType === "solar") return "Solar REC"
  else if (assetType === "wind") return "Wind REC"
  else return assetType
}
