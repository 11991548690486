<template>
  <h4 class="text-heading-5 text-blue-70">About heat pumps</h4>
  <p class="mt-4">
    Heat pumps are better for people and the planet. They are an energy efficient replacement to fossil fuel burning furnaces and boilers, and if
    implemented worldwide could cut global emissions by 3 gigatons per year. They’re also better for our health, and our finances. Heat pumps
    eliminate indoor pollution caused by fossil fuels, which increases the risk of childhood asthma and other health risks. Financially, the average
    homeowner would save $500-$900 per year on their utility bill by making the switch.
  </p>
  <h4 class="text-heading-5 mt-10 whitespace-pre-line text-blue-70">Why funding is necessary</h4>
  <p class="mt-4">
    High upfront costs are the biggest barrier to heat pump adoption for both buyers and suppliers. Equipment costs for heat pumps are higher than
    their fossil fuel counterparts, and shortages of qualified installers create bottlenecks in deployment. As a result, 20,000 new furnaces every day
    are installed across the country, locking in those emissions for 15 years. Funding will decrease upfront costs and accelerate widespread adoption
    in building systems.
  </p>
</template>
