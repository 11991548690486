/**
 * Service to manage LinkedIn Insights Tag tracking.
 * This service initializes the Insights tag snippet but, does not load the
 * script and start tracking until user consent is granted.
 */
class LinkedInInsightsService {
  constructor() {
    return
  }

  isLoaded = false

  // Check both the VITE_ and PUBLIC_ prefixed versions since this could run in either `web` or `www`
  partnerId = import.meta.env.VITE_LINKEDIN_INSIGHTS_PARTNER_ID || import.meta.env.PUBLIC_LINKEDIN_INSIGHTS_PARTNER_ID

  /**
   * Loads the snippet once the user has granted consent.
   */
  public grantConsent() {
    if (!this.partnerId || this.isLoaded) {
      return
    }

    try {
      this.loadLinkedInInsightTag(this.partnerId)
      this.isLoaded = true
    } catch (error) {
      console.error("Error loading LinkedIn Insights Tag", error)
    }
  }

  private loadLinkedInInsightTag(partnerId: string) {
    const linkedInScript = document.createElement("script")
    linkedInScript.type = "text/javascript"
    linkedInScript.text =
      `_linkedin_partner_id = "${partnerId}";` +
      "window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];" +
      "window._linkedin_data_partner_ids.push(_linkedin_partner_id);" +
      "(function(l) {" +
      "if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};" +
      "window.lintrk.q=[]}" +
      'var s = document.getElementsByTagName("script")[0];' +
      'var b = document.createElement("script");' +
      'b.type = "text/javascript";b.async = true;' +
      // The script is hosted by us, but originated from LinkedIn's
      // https://snap.licdn.com/li.lms-analytics/insight.min.js
      'b.src = "https://s3.us-west-2.amazonaws.com/static.wattcarbon.com/wattcarbon/linkedin-insights.min.js";' +
      "s.parentNode.insertBefore(b, s);})(window.lintrk);"
    document.head.appendChild(linkedInScript)
  }
}

export const linkedInInsightsService = new LinkedInInsightsService()
