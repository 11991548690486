<template>
  <AssetMetadataTable class="mt-8" title="Energy Efficiency Info" :metadata="details" />
</template>

<script setup lang="ts">
import { computed } from "vue"
import type { EnergyEfficiencyAsset } from "@common/models/asset"
import { INTERVENTION_TYPE, INTERVENTION_MEASURE_UPGRADE, BUILDING_TYPE } from "@common/models/asset"
import AssetMetadataTable from "./AssetMetadataTable.vue"

const { asset } = defineProps<{ asset: EnergyEfficiencyAsset }>()

const details = computed(() => {
  return {
    "Intervention Type": asset.interventionType ? INTERVENTION_TYPE[asset.interventionType] : "",
    "Intervention Measure Upgrade": asset.interventionMeasureUpgrade ? INTERVENTION_MEASURE_UPGRADE[asset.interventionMeasureUpgrade] : "",
    "Building Type": asset.buildingType ? BUILDING_TYPE[asset.buildingType] : "",
    "Square Footage of Living Space": asset.livingSpaceSqft,
  }
})
</script>
