import { Provider } from "./models"

export class HighlightProject {
  name!: string
  subtitle!: string
  description!: string
  id!: number
}

export class HeroImage {
  id!: number
  url!: string
  description!: string
}

export class SupplierRequest {
  accountId!: number
  displayName = ""
  slug = ""
  description = ""
  aboutThisCompany = ""
  isListedOnMarketplace = false
  whiteLabelDisplayName = ""
  primaryMeasure = ""
  mwhPerYear = 0
  primaryContactName = ""
  primaryContactEmail = ""
  projectsPerYear = 0
  states = Array<string>()
  incentivePricePerUnit = 0
  otherNotes = ""
  quantity = 0
  coBenefits = ""
  projectCategories = new Array<string>()
  images = new Array<HeroImage>()
  highlightProjects = new Array<HighlightProject>()
  climateImpacts = ""
  operatingLocations = ""
}

export class Supplier extends SupplierRequest {
  categories = new Array<string>()
  scopes = new Array<string>()
  providers = new Array<Provider>()
  logoUrl?: string
}
