<template>
  <div class="relative">
    <div class="flex items-center justify-center">
      <button
        class="flex size-6 items-center justify-center pt-px"
        :class="[
          buttonClass,
          {
            'border-darktone-dark text-darktone-dark': openerColor === 'primary',
            'border-sagetone-lightbackground text-sagetone-lightbackground': openerColor === 'primary-dark',
          },
        ]"
        aria-label="info"
        @click="handleClickTooltipButton">
        <TooltipIcon
          :icon-classes="[
            'transition-colors',
            {
              'hover:text-blue-70': props.openerColor === 'primary',
              'hover:text-sagetone-light': props.openerColor === 'primary-dark',
            },
          ]" />
      </button>
    </div>
    <Transition name="fade">
      <div
        v-if="tooltipIsVisible"
        ref="tooltip"
        class="text-body-3 absolute -top-4 left-6 z-10 flex w-[256px] rounded bg-white px-3 py-2 text-black drop-shadow-md"
        :class="panelClass"
        :style="tooltipAdjustment">
        <div>
          <slot />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onBeforeUnmount } from "vue"
import TooltipIcon from "../icon/TooltipIcon.vue"

const tooltip = ref<HTMLInputElement | null>(null)
const tooltipIsVisible = ref(false)

type Props = {
  buttonClass?: string
  openerColor?: "primary" | "primary-dark"
  panelClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  openerColor: "primary",
})

const handleClickTooltipButton = (event: Event) => {
  tooltipIsVisible.value = !tooltipIsVisible.value
  event.stopPropagation() // prevents immediately triggering handleClickOutsideTooltip
  if (tooltipIsVisible.value) {
    document.addEventListener("click", handleClickOutsideTooltip)
  }
}
const handleClickOutsideTooltip = (event: Event) => {
  // closes tooltip when user clicks outside of tooltip
  if (tooltip.value && !tooltip.value.contains(event.target as Node)) {
    tooltipIsVisible.value = false
    document.removeEventListener("click", handleClickOutsideTooltip)
  }
}
const tooltipAdjustment = computed(() => {
  // the tooltip is left-aligned to the icon, so it could go off the right side of the screen. this ensures it doesn't
  if (!tooltipIsVisible.value || !tooltip.value) return
  const padding = 16 // number of pixels between the right edge of the screen and the right edge of the tooltip
  const excess = tooltip.value.getBoundingClientRect().right - window.innerWidth + 2 * padding
  if (excess > 0) return { transform: `translateX(-${excess}px)` }
  return {}
})
onBeforeUnmount(() => {
  // removes event listener in case tooltip is open but user navigates away and component is unmounted
  document.removeEventListener("click", handleClickOutsideTooltip)
})
</script>
