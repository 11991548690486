<template>
  <div class="max-w-[780px]">
    <p v-if="numErrors > 0" class="text-subheading-1 text-error">Errors ({{ numErrors }})</p>
    <p v-if="numErrors === 0" class="text-subheading-1">No errors</p>
    <p v-if="numErrors === 0" class="mt-2.5">We did not find any errors at this stage. You can submit this asset metadata.</p>
    <template v-else>
      <p class="mt-2.5">Your asset data cannot be uploaded until these errors are resolved. Please fix them and try uploading your file again.</p>
      <p class="mt-4">
        <template v-if="numErrorRows && numGlobalErrors">
          There {{ areIs(numErrorRows) }} <strong>{{ rowsWithErrorsMessage }}</strong
          >, and <strong>{{ globalErrorsMessage }}</strong
          >:
          <ol class="text-body-2 mt-4 list-outside list-decimal pl-6">
            <li v-for="(error, index) in globalErrors" :key="index">{{ error }}</li>
          </ol>
        </template>
        <template v-if="numErrorRows && !numGlobalErrors">
          There {{ areIs(numErrorRows) }} <strong>{{ rowsWithErrorsMessage }}</strong>
        </template>
        <template v-if="!numErrorRows && numGlobalErrors">
          There {{ areIs(numGlobalErrors) }} <strong>{{ globalErrorsMessage }}</strong
          >:
          <ol class="text-body-2 mt-4 list-outside list-decimal pl-6">
            <li v-for="(error, index) in globalErrors" :key="index">{{ error }}</li>
          </ol>
        </template>
      </p>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useAssetStore } from "../asset.state"

const assetStore = useAssetStore()

const globalErrors = assetStore.assetSubmissionGlobalErrors
const numErrorRows = assetStore.numErrorRows
const numGlobalErrors = assetStore.numGlobalErrors
const numErrors = numGlobalErrors + numErrorRows

const rowsWithErrorsMessage = numErrorRows === 1 ? `1 row with errors` : `${numErrorRows} rows with errors`
const globalErrorsMessage = numGlobalErrors === 1 ? `1 global error` : `${numGlobalErrors} global errors`

const areIs = (num: number) => (num === 1 ? "is" : "are")
</script>
