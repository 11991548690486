<template>
  <div v-if="numWarningRows > 0 || numGlobalWarnings > 0" class="max-w-[780px]">
    <p class="text-body-2-strong">Warnings ({{ numWarningRows + numGlobalWarnings }})</p>
    <p class="mt-2.5">These issues are not required to be resolved, but may help troubleshoot issues with your file or data.</p>
    <p class="mt-4">
      <template v-if="numWarningRows && numGlobalWarnings">
        There {{ areIs(numWarningRows) }} <strong>{{ rowsWithWarningsMessage }}</strong
        >, and <strong>{{ numGlobalWarnings }} global warnings</strong>:
        <ol class="text-body-3 mt-4 list-outside list-decimal pl-6">
          <li v-for="(warning, index) in globalWarnings" :key="index">{{ warning }}</li>
        </ol>
      </template>
      <template v-if="numWarningRows && !numGlobalWarnings">
        There {{ areIs(numWarningRows) }} <strong>{{ rowsWithWarningsMessage }}</strong>
      </template>
      <template v-if="!numWarningRows && numGlobalWarnings">
        There {{ areIs(numGlobalWarnings) }} <strong>{{ globalWarningsMessage }}</strong
        >:
        <ol class="text-body-3 mt-4 list-outside list-decimal pl-6">
          <li v-for="(warning, index) in globalWarnings" :key="index">{{ warning }}</li>
        </ol>
      </template>
    </p>
  </div>
</template>

<script setup lang="ts">
import { useAssetStore } from "../asset.state"

const assetStore = useAssetStore()

const globalWarnings = assetStore.assetSubmissionGlobalWarnings
const numGlobalWarnings = assetStore.numGlobalWarnings
const numWarningRows = assetStore.numWarningRows

const rowsWithWarningsMessage = numWarningRows === 1 ? `1 row with warnings` : `${numWarningRows} rows with warnings`
const globalWarningsMessage = numGlobalWarnings === 1 ? `1 global warning` : `${numGlobalWarnings} global warnings`

const areIs = (num: number) => (num === 1 ? "is" : "are")
</script>
