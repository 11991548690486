import { defineStore } from "pinia"
import { ref, watch, computed } from "vue"
import { Site } from "@common/models/site"
import { SiteAsset } from "@common/models/siteAsset"
import { useMainStore } from "@/store"
import { useSiteService } from "@/services/service-container"
import _ from "lodash"

export const useSitesStore = defineStore("sites", () => {
  const isLoading = ref<boolean>(false)
  const sites = ref<Array<Site>>([])

  const mainStore = useMainStore()
  const siteService = useSiteService()

  const _upsert = (site: Site) => {
    const siteIndex = sites.value.findIndex((s: Site) => s.id === site.id)
    if (siteIndex >= 0) {
      sites.value[siteIndex] = site
    } else {
      sites.value.push(site)
    }
  }

  const fetchSite = async (id: number) => {
    const site = await siteService.getSite(id)
    _upsert(site)
    return site
  }

  const updateSite = async (site: Site) => {
    const updatedSite = await siteService.updateSite(site)
    _upsert(updatedSite)
    return updatedSite
  }

  const deleteSite = async (id: number) => {
    await siteService.deleteSite(id)
    _.remove(sites.value, (s: Site) => s.id === id)
  }

  watch(
    () => mainStore.auth.user?.account_id,
    async (accountId) => {
      if (accountId) {
        try {
          isLoading.value = true
          sites.value = await siteService.listSites()
        } finally {
          isLoading.value = false
        }
      }
    },
    { immediate: true }
  )

  const assets = computed(() => sites.value.flatMap((s: Site) => s.assets))
  const assetIds = computed(() => assets.value.map((a: SiteAsset) => a.id))

  return {
    // state
    isLoading,
    sites,
    // actions
    fetchSite,
    updateSite,
    deleteSite,
    // getters
    assets,
    assetIds,
  }
})
