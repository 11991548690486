import { useRoute, useRouter } from "vue-router"

import type { RouteLocationRaw } from "vue-router"

const route = useRoute()
const router = useRouter()

/* Push a route using vue router, or if that doesn't exist, navigate to the given href */
export const push = async (route: RouteLocationRaw, href: string) => {
  if (router) return router.push(route)
  else window.location.href = href
}

/* Get a query param from the current route, or if that doesn't exist, pull it from the current location */
export const getQueryParam = (param: string) => {
  if (route) return route.query[param]
  else return new URL(window.location.href).searchParams.get(param)
}
