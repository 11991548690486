<template>
  <WcMultiselect
    v-model="model"
    class="max-w-[224px]"
    :name="name"
    :options="options"
    header="Resource Type"
    all-selected-label="Any resource"
    scroll-height="400px">
  </WcMultiselect>
</template>

<script setup lang="ts">
import { defineModel } from "vue"
import type { SelectOption } from "@/components/input"
import { WcMultiselect } from "@/components/input"
import type { AssetCategory } from "@common/models/asset"
import { ASSET_CATEGORY } from "@common/models/asset"

const options = Object.entries(ASSET_CATEGORY).map(([value, label]) => ({ label, value })) as SelectOption<AssetCategory>[]

const model = defineModel<AssetCategory[] | null>()
defineProps<{ name: string }>()
</script>
