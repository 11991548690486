import { defineStore } from "pinia"
import { ref, nextTick } from "vue"

import type { Ref } from "vue"

export const useProcurementStore = defineStore("procurement", () => {
  const isVisible = ref<boolean>(false)

  let containerRef: Ref<HTMLElement | undefined> | undefined

  return {
    isVisible,

    show: () => {
      isVisible.value = true

      nextTick(() => {
        containerRef?.value?.scrollIntoView({ behavior: "smooth" })
      })
    },

    hide: () => {
      isVisible.value = false
    },

    setContainerRef: (ref: Ref<HTMLElement | undefined>) => (containerRef = ref),
  }
})
