<template>
  <div class="mt-4 overflow-x-scroll pb-8">
    <WcButton text="Upload Assets" size="small" icon="wc-carbon:add" class="mb-4" @click="handleUploadAssetClick" />
    <AssetsTable :assets="assets" show-delete show-price show-kind @row-clicked="handleRowClicked" @row-delete-clicked="handleRowDeleteClicked" />
    <PaginationButtons class="shadow-top p-2" :page-info="pageInfo" @load-items="loadAssets" />
  </div>
  <AdminAccountAssetsUploadModal ref="assetsUploadModal" :account-id="accountId" />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import type { Asset } from "@common/models/asset"
import { WcButton } from "@/components/button"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import AssetsTable from "@/modules/asset/components/AssetsTable.vue"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService } from "@/services/service-container"
import AdminAccountAssetsUploadModal from "./AdminAccountAssetsUploadModal.vue"

const props = defineProps<{ accountId?: number }>()

const assetService = useAssetService()
const router = useRouter()

const assets = ref<Asset[]>([])
const pageInfo = ref<PageInfo>()
const assetsUploadModal = ref<InstanceType<typeof AdminAccountAssetsUploadModal>>()

const loadAssets = async (url?: string) => {
  try {
    const filters = {
      accountIds: [props.accountId],
    }
    const result = await assetService.listAssetsAdmin({ url, ...filters })
    assets.value = result.data
    pageInfo.value = result.pageInfo
  } catch (error) {
    console.error("There was an error loading assets", error)
  }
}
onMounted(() => {
  loadAssets()
})
const handleRowClicked = (row: { id: number }, event: MouseEvent | KeyboardEvent) => {
  const path = `/admin/assets/${row.id}`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    router.push(path)
  }
}

const handleUploadAssetClick = () => {
  if (assetsUploadModal.value) {
    assetsUploadModal.value.openModal()
  }
}

const handleRowDeleteClicked = async (assetId: number, event: MouseEvent) => {
  event.stopPropagation()
  const asset = assets.value.find((asset) => asset.id === assetId)
  if (asset && confirm(`Delete asset '${asset.id}'?`)) {
    await assetService.deleteAsset(asset.id)
    location.reload()
  }
}
</script>
