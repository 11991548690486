import type { LocationQuery } from "vue-router"
import type { AssetCategory } from "@common/models/asset"
import { ListingQuarter } from "@common/models/listing"

const parseFilterFromQueryParam = (query: LocationQuery, key: string) => {
  const value = query[key]
  if (value == null || value == "") {
    return null
  }
  return (value as string).split(",")
}

export const parseInitialResourceTypes = (query: LocationQuery) => {
  return parseFilterFromQueryParam(query, "resourceTypes") as AssetCategory[]
}

export const parseInitialDispatchDates = (query: LocationQuery) => {
  const dispatchDateStrings = parseFilterFromQueryParam(query, "dispatchDates")
  if (dispatchDateStrings == null) {
    return null
  }
  return dispatchDateStrings
    .map((dispatchDateString) => {
      const [year, quarter] = dispatchDateString.split("-Q")
      if (!year || !quarter) {
        return null
      }
      return new ListingQuarter(parseInt(year), parseInt(quarter))
    })
    .filter((date) => date !== null) as ListingQuarter[]
}

export const parseInitialLocations = (query: LocationQuery) => {
  return parseFilterFromQueryParam(query, "locations")
}
