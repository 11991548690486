export const setPageTitle = (title?: string) => {
  const pageTitle = title ? `${title} | WattCarbon` : "WattCarbon"
  document.title = pageTitle
  document.querySelector('meta[name="title"]')?.setAttribute("content", pageTitle)
  document.querySelector('meta[property="og:title"]')?.setAttribute("content", pageTitle)
  document.querySelector('meta[property="twitter:title"]')?.setAttribute("content", pageTitle)
}

export const setPageDescription = (description?: string) => {
  const pageDescription = description || "Eliminate fossil fuels by funding decarbonization projects in America’s buildings."
  document.querySelector('meta[property="og:description"]')?.setAttribute("content", pageDescription)
  document.querySelector('meta[property="twitter:description"]')?.setAttribute("content", pageDescription)
}

export const setPageImage = (url?: string) => {
  const imageUrl = url || "https://static.wattcarbon.com/wattcarbon/3392821187390.jpg"
  document.querySelector('meta[property="og:image"]')?.setAttribute("content", imageUrl)
  document.querySelector('meta[property="twitter:image"]')?.setAttribute("content", imageUrl)
}

export const setCanonicalUrl = (url?: string) => {
  let linkEl = document.querySelector('link[rel="canonical"]')

  if (url) {
    if (!linkEl) {
      linkEl = document.createElement("link")
      linkEl.setAttribute("rel", "canonical")
      document.querySelector("head")!.appendChild(linkEl)
    }
    linkEl.setAttribute("href", url)
  } else {
    linkEl?.remove()
  }
}
