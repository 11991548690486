<template>
  <div class="cursor-pointer" data-cy="story-search-card">
    <WcLink :to="linkUrl" class="no-underline">
      <div class="grid grid-cols-7 gap-5">
        <div class="col-span-2">
          <div class="aspect-[4/3] bg-neutral-10 hover:bg-neutral-20">
            <img v-if="imageUrl" class="size-full object-cover mix-blend-multiply" :src="imageUrl || ''" />
            <div v-else class="size-full bg-neutral-10" />
          </div>
        </div>
        <div class="col-span-5 lg:flex lg:justify-between lg:gap-x-5">
          <div class="lg:shrink lg:basis-full">
            <div class="text-subheading-large-bold mb-2">{{ props.story.name }}</div>
            <div v-if="props.story.locationDescription" class="text-body-2 my-1">{{ props.story.locationDescription }}</div>
            <div v-if="props.story.dateRangeDescription" class="text-body-2 my-1">{{ props.story.dateRangeDescription }}</div>
            <div v-if="props.story.availabilityDescription" class="text-body-2 my-1">Type: {{ props.story.availabilityDescription }}</div>
          </div>
          <div v-if="isQuantityVisible" class="flex gap-x-1 lg:block lg:shrink-0 lg:basis-40 lg:text-right">
            <div class="text-body-2" data-cy="total-quantity">{{ totalEacs }}</div>
            <div class="text-body-2" data-cy="total-carbon">({{ totalGco2 }})</div>
          </div>
        </div>
      </div>
    </WcLink>
  </div>
</template>

<script setup lang="ts">
import { computed, withDefaults } from "vue"
import { MarketplaceStory } from "@common/models/story"
import WcLink from "@/components/ui/WcLink.vue"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"

const props = withDefaults(
  defineProps<{
    story: MarketplaceStory
    isQuantityVisible?: boolean
  }>(),
  {
    isQuantityVisible: true,
  }
)

const imageUrl = computed(() => props.story.imageUrl)

const linkUrl = computed(() => {
  if (props.story.id) {
    return `/openeac-exchange/stories/${props.story.id}`
  }
  // If there is no story, then skip to the procurement page
  return `/openeac-exchange/stories/0`
})

const totalEacs = computed(() => {
  return props.story.totalEacs ? getFormattedEacQuantity(props.story.totalEacs, EacMeasurementParameter.Electricity, 1) : undefined
})
const totalGco2 = computed(() => {
  return props.story.totalGco2 ? getFormattedEacQuantity(props.story.totalGco2, EacMeasurementParameter.CarbonDioxide, 1) : undefined
})
</script>
