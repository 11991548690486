<template>
  <section class="wc-homepage-grid mt-3 justify-center border border-error bg-error-light pb-1">
    <div class="col-span-12 m-6 text-error md:col-span-8 md:col-start-3 xl:col-span-4 xl:col-start-5">
      <p class="text-subheading-1 pb-2">Error</p>
      <p class="text-body-2">{{ props.errorMessage }}</p>
    </div>
  </section>
</template>

<script setup lang="ts">
interface Props {
  errorMessage: string
}

const props = defineProps<Props>()
</script>
