import { SiteAsset } from "@common/models/siteAsset"
import { LoadshapeSummary } from "@common/models/loadshape"
import { Site } from "@common/models/site"

export class SideNavRow {
  id = 0
  name = ""
  icon = ""
  class = ""
  sourceId = 0
  sourceType = ""
  type = ""
  status = ""
  carbonFreePercent = 0
  carbonSumTonnesCo2e = 0
  carbonAvgDailyTonnesCo2e = 0
  energySumMwh = 0
  energyAvgDailyMwh = 0
  locationalCarbonBasedEnergySumMwh = 0
  carbonRatio = 0
  nValuesAggregated = 0
  site?: Site
  rows = new Array<SideNavRow>()

  static fromSite(site: Site): SideNavRow {
    return {
      ...new SideNavRow(),
      id: site.id,
      name: site.name,
      site: site,
      rows: site.assets.map(SideNavRow.fromAsset),
    } as SideNavRow
  }

  static fromAsset(asset: SiteAsset): SideNavRow {
    return {
      ...new SideNavRow(),
      name: asset.name,
      sourceId: asset.id,
      sourceType: "asset",
      type: asset.type,
    } as SideNavRow
  }

  static fromLoadshapeSummary(row: SideNavRow, summary: LoadshapeSummary): SideNavRow {
    row = {
      ...row,
      carbonFreePercent: summary.marketBasedHourlyCarbonFreePercent,
      carbonSumTonnesCo2e: summary.marketBasedCarbonSumKgCo2 / 1000,
      energySumMwh: summary.marketBasedEnergySumMwh,
      locationalCarbonBasedEnergySumMwh: summary.locationalCarbonBasedEnergySumMwh,
      carbonRatio: 0,
    } as SideNavRow

    row.carbonRatio = row.energySumMwh !== 0 ? Number((row.carbonSumTonnesCo2e / row.energySumMwh).toFixed(2)) : 0

    return row
  }
}

export class SideNavFilters {
  [key: string]: any
  startDate = new Date()
  endDate = new Date()
}
