export const selectedRowIdsToMapping = (selectedRowIds?: number[]): Record<number, boolean> => {
  if (selectedRowIds == undefined) {
    return {}
  }
  const mapping = {} as Record<number, boolean>
  selectedRowIds!.forEach((id) => {
    mapping[id] = true
  })
  return mapping
}

export const selectedRowMappingToIds = (mapping: Record<number, boolean>) => {
  return Object.entries(mapping)
    .filter(([, enabled]) => enabled)
    .map(([id]) => parseInt(id))
}
