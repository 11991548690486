<template>
  <!-- eslint-disable-next-line vue/no-v-html This has been sanitized via commonmark -->
  <div class="commonmark" v-html="renderedSource" />
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { Parser } from "commonmark"
import { WcCommonmarkHtmlRenderer } from "./WcCommonmarkHtmlRenderer"

const commonmarkParser = new Parser({ smart: true })
const commonmarkRenderer = new WcCommonmarkHtmlRenderer({ safe: true })

const renderCommonmark = (source: string | undefined) => {
  if (!source) {
    return ""
  }

  const parsed = commonmarkParser.parse(source)

  // Convert "#" to <h5> and "##", "###", etc. to <h6>.
  //
  // This makes the headings make sense for our document but allows the admin
  // to use natural headings in the editor.
  const walker = parsed.walker()
  let event
  while ((event = walker.next())) {
    const node = event.node
    if (event.entering && node.type == "heading") {
      node.level = node.level == 1 ? 5 : 6
    }
  }

  return commonmarkRenderer.render(parsed)
}
const { source } = defineProps<{ source: string | undefined }>()
const renderedSource = computed(() => renderCommonmark(source))
</script>

<style>
.commonmark,
.commonmark p {
  @apply text-[16px] leading-[24px] text-black;
}
.commonmark h5 {
  font-family: theme(fontFamily.heading);
  @apply text-[24px] leading-[30px] text-black mb-2 mt-4;
}

.commonmark h6 {
  font-family: theme(fontFamily.heading);
  @apply text-[18px] leading-[22px] text-black mb-2 mt-4;
}

.commonmark p + p {
  @apply mt-4;
}

.commonmark a {
  text-decoration: underline;
  @apply text-black;
}

.commonmark ul {
  padding-left: 1.25em;
}

.commonmark li {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 0.25em;
}
</style>
