import { defineStore } from "pinia"
export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      errors: new Array<string>(),
    }
  },
  actions: {
    addError(message: string) {
      this.errors.push(message)
    },
    resetErrors() {
      this.errors = new Array<string>()
    },
  },
})
