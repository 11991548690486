<template>
  <div v-if="transactions.length > 0 || isLoading">
    <WcTable
      :data="transactions"
      :columns="[
        { key: 'createdTime', label: 'Date', align: 'left', thClass: 'w-2/12' },
        { key: 'account', label: 'Account', align: 'left', thClass: 'w-2/12' },
        { key: 'kind', label: 'Description', align: 'left', thClass: 'w-4/12' },
        ...(includePriceInfo ? [{ key: 'penniesUsd', label: 'Amount (USD)', align: 'right', thClass: 'w-2/12' } as TableHeader] : []),
        { key: 'eacs', label: 'EACs', align: 'right', thClass: 'w-2/12' },
      ]"
      row-id-field="id"
      table-class="text-body-3 w-main-content table-fixed"
      td-class="p-3"
      tr-class="border-b border-neutral-20"
      :sortable="false">
      <template #createdTime="{ row }">
        {{ format(new Date(row.createdTime), "LLLL d, y") }}
      </template>
      <template #kind="{ row }">
        <router-link v-if="formatLink(row)" :to="formatLink(row)" class="underline">
          {{ formatDescription(row) }}
        </router-link>
        <template v-else>
          {{ formatDescription(row) }}
        </template>
      </template>
      <template #account="{ row }">
        {{ formatAccount(row) }}
      </template>
      <template #penniesUsd="{ row }">
        {{ formatCurrency(row) }}
      </template>
      <template #eacs="{ row }">
        <span :class="getEacQuantity(row) < 0 ? 'text-error' : ''">
          {{ formatEacQuantity(row) }}
        </span>
      </template>
    </WcTable>

    <PaginationButtons :page-info="pageInfo" :is-disabled="isLoading" class="mt-4" @load-items="loadTransactions" />
  </div>
  <div v-else>
    <div class="flex flex-col items-center justify-center gap-8 bg-neutral-20 p-8">
      <p class="text-body-2 text-center">There are no entries to show yet</p>
      <div class="flex flex-col items-center justify-between gap-4 md:flex-row">
        <WcButton to="/openeac-exchange" text="Browse OpenEAC Exchange to buy EACs" />
        <WcButton to="/assets/register" text="Register an asset to generate or track EACs" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { WcButton } from "@/components/button"
import type { TableHeader } from "@/components/WcTable/WcTable"
import WcTable from "@/components/WcTable/WcTable.vue"
import { useTransactionService } from "@/services/service-container"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import formatCurrencyInPenniesFactory from "@common/utils/formatCurrencyInPenniesFactory"
import { CARBON_UNITS, ELECTRICITY_UNITS, Transaction, TransactionKind, Unit } from "@/services/api/transaction.service"
import { format } from "date-fns"
import { onMounted, ref } from "vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import { PageInfo } from "@/services/base-fetcher"
import { useMainStore } from "@/store"

const store = useMainStore()

const props = withDefaults(defineProps<{ includePriceInfo?: boolean; kind?: TransactionKind[] }>(), {
  includePriceInfo: true,
})

const formatCurrencyInPennies = formatCurrencyInPenniesFactory("exceptZero")

const transactionService = useTransactionService()
const isLoading = ref(true)
const pageInfo = ref<PageInfo>()
const transactions = ref<Transaction[]>([])

onMounted(() => {
  loadTransactions()
})

const loadTransactions = async (url?: string) => {
  try {
    isLoading.value = true

    const result = await transactionService.listTransactions({
      url,
      per_page: 25,
      ...(props.kind && { kind: props.kind }),
    })
    pageInfo.value = result.pageInfo
    transactions.value = result.data
  } finally {
    isLoading.value = false
  }
}

const formatDescription = (transaction: Transaction) => {
  for (const details of transaction.details) {
    switch (details.kind) {
      case "minting":
        return `EACs Created`
      case "allocation":
        if (details.amount > 0) {
          return `Allocated EACs from Asset #${details.kindDetails.deviceId}`
        } else {
          return `EACs from your Asset #${details.kindDetails.deviceId} were distributed to buyers`
        }
      case "transfer":
        return "Transferred"
      case "retirement":
        return `Retired`
      case "purchase":
        if (details.amount == 0) {
          return `Pending purchase`
        } else {
          return `Purchased`
        }
      case "listing_earnings":
        continue
      case "listing_sale":
        if (details.amount == 0) {
          return `Pending sale`
        } else {
          return `Sold`
        }
      case "payment_posted":
        continue
      case "withdrawal":
        return `Withdrew funds`
      default:
        // @ts-ignore - Using transaction.kind in case we add a new transaction type in the BE
        // currently ignoring 'fee' transactions
        return transaction.kind
    }
  }
}

const formatAccount = (transaction: Transaction) => {
  return store.accounts.find((a) => a.id === transaction.accountId)?.name
}

const formatLink = (transaction: Transaction) => {
  for (const detail of transaction.details) {
    switch (detail.kind) {
      case "payment_posted":
        return { name: "wc-listing-confirmation", params: { paymentId: detail.kindDetails.paymentId } }
      default:
        continue
    }
  }
  return ""
}

const convertEacUnits = (unit: Unit) => {
  if (ELECTRICITY_UNITS.includes(unit)) {
    return EacMeasurementParameter.Electricity
  } else if (CARBON_UNITS.includes(unit)) {
    return EacMeasurementParameter.GhgEmissions
  }
  return null
}

const getEacQuantity = (transaction: Transaction) => {
  for (const detail of transaction.details) {
    const eacFormat = convertEacUnits(detail.units)
    if (eacFormat) {
      return detail.amount
    }
  }
  return 0
}

const formatEacQuantity = (transaction: Transaction) => {
  for (const detail of transaction.details) {
    const eacFormat = convertEacUnits(detail.units)
    if (eacFormat) {
      return getFormattedEacQuantity(detail.amount, eacFormat, 2, "exceptZero")
    }
  }
  return "—"
}

const formatCurrency = (transaction: Transaction) => {
  for (const detail of transaction.details) {
    switch (detail.kind) {
      case "payment_posted":
        return formatCurrencyInPennies(-1 * detail.kindDetails.paymentAmount)
      case "listing_earnings":
      case "withdrawal":
        return formatCurrencyInPennies(detail.amount)
    }
  }
  return "—"
}
</script>
