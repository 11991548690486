import ApiFetcher from "@/services/api-fetcher"
import { Provider } from "@common/models/models"

export default class ProviderService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  async listProviders(): Promise<Array<Provider>> {
    return new Promise((resolve, reject) => {
      const endpoint = `/providers`
      const params = {}
      this.fetcher.httpGet<Array<Provider>>(endpoint, params).then(
        (data) => resolve(data),
        (error) => reject(error)
      )
    })
  }
}
