import { RouteRecordRaw } from "vue-router"
import SignIn from "@/modules/auth/SignInView.vue"
import Login from "@/modules/auth/LoginView.vue"
import Register from "@/modules/auth/RegisterView.vue"
import LandingLayout from "@/modules/auth/LandingLayout.vue"
export const authRoutes: Array<RouteRecordRaw> = [
  {
    path: "/signin",
    name: "wc-signin",
    component: SignIn,
    meta: { layout: LandingLayout },
  },
  {
    path: "/login",
    name: "wc-login",
    component: Login,
    meta: { layout: LandingLayout },
  },
  {
    path: "/register",
    name: "wc-register",
    component: Register,
    meta: { layout: LandingLayout },
  },
]
