<template>
  <BasePageSideNav
    :selected-asset-ids="selectedAssetIds"
    :apply-button-is-disabled="pageState.is8760Loading || pageState.isLoadshapeLoading"
    @update="onSideNavUpdate">
    <template #subheader>
      <WcAccountingSubNav />
    </template>
    <template #default>
      <section class="flex grow flex-col bg-white pl-6 pr-9">
        <WcCard card-class="border-b border-background-sagelight">
          <template #header>
            <div class="flex justify-between border-b border-divider-dark pb-[11px]">
              <h4 class="text-subheading-1">Energy Use</h4>
              <div class="text-subheading-2">
                {{ format(sideNavFilters.startDate, "MMM yyyy") }} - {{ format(sideNavFilters.endDate, "MMM yyyy") }}
              </div>
            </div>
            <div class="relative z-10 -mb-6 flex gap-4 pt-[14px]">
              <div class="flex">
                <input id="radio-one" v-model="isLoadshapeDaily" class="cursor-pointer" type="radio" name="switch-one" :value="true" />
                <label class="cursor-pointer pl-2 text-black" for="radio-one">Daily</label>
              </div>
              <div class="flex">
                <input id="radio-two" v-model="isLoadshapeDaily" class="cursor-pointer" type="radio" name="switch-one" :value="false" />
                <label class="cursor-pointer pl-2 text-black" for="radio-two">Monthly</label>
              </div>
            </div>
          </template>

          <div class="size-full pb-2">
            <div v-if="pageState.isLoadshapeLoading" class="h-[350px]">
              <WcLoadingSpinner />
            </div>
            <WcEnergyOverTime
              v-if="!pageState.isLoadshapeLoading && sites.length > 0"
              :data="loadshapeSummary?.data || []"
              :is-daily="isLoadshapeDaily"
              data-cy="chart-energy"
              height="350"
              @on-change="onEnergyOverTimeChange" />
          </div>
        </WcCard>
        <WcCard>
          <template #header>
            <div class="mb-6 flex justify-between border-b border-divider-dark pb-[11px]">
              <h4 class="text-subheading-1">Average Hourly Emissions</h4>
              <div class="text-subheading-2">{{ format(zoomStartDate, "MMM yyyy") }} - {{ format(zoomEndDate, "MMM yyyy") }}</div>
            </div>
          </template>
          <div class="w-full">
            <div v-if="pageState.isHourlyLoadshapeLoading" class="h-[350px]">
              <WcLoadingSpinner />
            </div>
            <div class="flex size-full items-center justify-center pb-4 pl-4">
              <div v-if="!pageState.isLoadshapeLoading && pageState.error" class="flex h-full items-center justify-center bg-error-light">
                <span class="text-error">{{ pageState.error }}</span>
              </div>
              <div v-if="!pageState.isLoadshapeLoading && sites.length === 0" class="flex h-full items-center justify-center bg-neutral-20">
                Add sites to view your emissions
              </div>
              <div class="w-full">
                <Wc24HourLoadshape
                  v-if="!pageState.isHourlyLoadshapeLoading && sites.length > 0"
                  :data="loadshapeSummary24Hour?.data || []"
                  data-cy="chart-loadshape"
                  height="350" />
              </div>
            </div>
          </div>
        </WcCard>
      </section>
    </template>
  </BasePageSideNav>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue"

import { storeToRefs } from "pinia"

import { useSitesStore } from "@/store/sites"

import { debounce } from "lodash"
import { Loadshape, LoadshapeGroupedSummary } from "@common/models/loadshape"
import { LoadshapeAssetActualsQueryRequest, LoadshapeQueryRequest, QueryGroupByType } from "@common/models/loadshapeQueryRequest"
import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"
import Wc24HourLoadshape from "@/modules/vpp/components/Wc24HourLoadshape.vue"
import WcEnergyOverTime from "@/modules/vpp/components/WcEnergyOverTime.vue"
import { SideNavFilters } from "@/components/layout/layout.model"
import { format } from "date-fns"
import BasePageSideNav from "@/components/layout/BasePageSideNav.vue"
import { useQueryService } from "@/services/service-container"
import WcCard from "@/components/WcCard.vue"
import WcAccountingSubNav from "@/modules/accounting/components/WcAccountingSubNav.vue"

const pageState = reactive({
  error: undefined,
  is8760Loading: false,
  isLoadshapeLoading: false,
  isHourlyLoadshapeLoading: false,
} as {
  error?: string
  is8760Loading: boolean
  isLoadshapeLoading: boolean
  isHourlyLoadshapeLoading: boolean
})

const sitesStore = useSitesStore()
const { sites } = storeToRefs(sitesStore)

const selectedAssetIds = ref(new Array<number>())
const sideNavFilters = ref(new SideNavFilters())

const queryService = useQueryService()
const loadshapeData = reactive({
  request: new LoadshapeQueryRequest(),
  result: Loadshape.GetEmptyLoadshape(),
  hourRequest: new LoadshapeQueryRequest(),
  hourResult: Loadshape.GetEmptyLoadshape(),
})
const loadshapeSummary = ref<LoadshapeGroupedSummary>(new LoadshapeGroupedSummary())
const loadshapeSummary24Hour = ref<LoadshapeGroupedSummary>(new LoadshapeGroupedSummary())
const zoomStartDate = ref(new Date())
const zoomEndDate = ref(new Date())

const isLoadshapeDaily = ref(true)
const onEnergyOverTimeChange = async ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
  zoomStartDate.value = new Date(startDate)
  zoomEndDate.value = new Date(endDate)
  await updateHourlyDataDelayed()
}

const loadshapeGroupBy = computed(() => {
  return isLoadshapeDaily.value ? QueryGroupByType.Date : QueryGroupByType.YearMonthly
})
const updateYearData = async () => {
  pageState.error = undefined
  pageState.isLoadshapeLoading = true

  try {
    loadshapeData.request = new LoadshapeAssetActualsQueryRequest(
      selectedAssetIds.value,
      sideNavFilters.value.startDate,
      sideNavFilters.value.endDate
    )
    loadshapeData.request.groupBy = loadshapeGroupBy.value
    loadshapeSummary.value = await queryService.getLoadshapeGroupedSummary(loadshapeData.request)

    await updateHourlyData()
  } catch (error) {
    console.log(error)
    pageState.error = "Something went wrong on our end"
  } finally {
    pageState.isLoadshapeLoading = false
  }
}

const updateHourlyData = async () => {
  const sourceIds = selectedAssetIds.value
  pageState.isHourlyLoadshapeLoading = true
  loadshapeData.hourRequest = new LoadshapeAssetActualsQueryRequest(sourceIds, sideNavFilters.value.startDate, sideNavFilters.value.endDate) // zoomYearMonths.value
  loadshapeData.hourRequest.groupBy = QueryGroupByType.HourOfDay
  loadshapeSummary24Hour.value = await queryService.getLoadshapeGroupedSummary(loadshapeData.hourRequest)
  pageState.isHourlyLoadshapeLoading = false
}

const updateHourlyDataDelayed = debounce(updateHourlyData, 1000, {})

onMounted(async () => {
  await updateYearData()
})

const onSideNavUpdate = async (assetIds: Array<number>, filters: SideNavFilters) => {
  selectedAssetIds.value = assetIds
  sideNavFilters.value = filters
  zoomStartDate.value = sideNavFilters.value.startDate
  zoomEndDate.value = sideNavFilters.value.endDate
  await updateYearData()
}

watch(
  () => sites,
  async () => {
    // If assets have been removed redo the graph
    const assetIds = sitesStore.assetIds
    const updatedAssetIds = selectedAssetIds.value.filter((id) => assetIds.includes(id))

    const doUpdateGraph = updatedAssetIds.length !== selectedAssetIds.value.length
    selectedAssetIds.value = updatedAssetIds
    if (doUpdateGraph) {
      await updateYearData()
    }
  },
  { deep: true }
)

watch(isLoadshapeDaily, async () => {
  await updateYearData()
})
</script>
