<template>
  <WcModal :is-open="isOpen" header="Create New Asset Group" size="medium" @update:is-open="handleUpdateIsOpen">
    <template #panel>
      <div class="w-[424px]">
        <fieldset class="mb-8">
          <label class="text-body-2 mb-2 text-black" for="assetGroupName">Asset Group Name</label>
          <WcInputText v-model="assetGroupName" name="assetGroupName" />
        </fieldset>
      </div>
    </template>
    <template #footer>
      <WcButton text="Cancel" class="ml-auto" variant="secondary" @click="handleUpdateIsOpen(false)" />
      <WcButton text="Save" :is-disabled="!assetGroupName" @click="createAssetGroup" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useToast } from "vue-toastification"
import { WcButton } from "@/components/button"
import { WcInputText } from "@/components/input"
import WcModal from "@/components/WcModal.vue"
import { useAssetService } from "@/services/service-container"

const assetService = useAssetService()
const toast = useToast()

const emit = defineEmits(["asset-group-added"])

const isOpen = ref<boolean>(false)
const assetGroupName = ref<string>("")

const handleUpdateIsOpen = (newValue: boolean) => {
  isOpen.value = newValue
  if (!newValue) {
    resetModal()
  }
}

const resetModal = () => {
  assetGroupName.value = ""
}

const createAssetGroup = async () => {
  try {
    const assetGroup = await assetService.createAssetGroup({ name: assetGroupName.value })
    emit("asset-group-added", assetGroup)
    toast.success(`Asset group ${assetGroup.name} created`)
    return assetGroup
  } catch (error) {
    toast.error("Failed to create asset group. Please try again.")
    throw error
  }
}

const openModal = () => handleUpdateIsOpen(true)
const closeModal = () => handleUpdateIsOpen(false)
defineExpose({ openModal, closeModal })
</script>
