<template>
  <div class="max-w-[780px]">
    <p v-if="numErrors > 0" class="text-subheading-1 text-error">Errors ({{ numErrors }})</p>
    <p v-if="numErrors === 0" class="text-subheading-1">No errors</p>
    <p v-if="numErrors === 0" class="mt-2.5">We did not find any errors at this stage. You can submit this time series data.</p>
    <template v-else>
      <p class="mt-2.5">
        Your time series data cannot be uploaded until these errors are resolved. Please fix them and try uploading your file again.
      </p>
      <p class="mt-4">
        <template v-if="assetTimeSeriesStore.assetTimeSeriesGlobalError">
          There is <strong>1 global error</strong>:
          <ul class="text-body-2 mt-4 list-outside list-disc pl-6">
            <li>{{ assetTimeSeriesStore.assetTimeSeriesGlobalError }}</li>
          </ul>
        </template>
        <template v-if="numErrorRows">
          There {{ areIs(numErrorRows) }} <strong>{{ rowsWithErrorsMessage }}</strong>
          <ul class="text-body-2 mt-4 list-outside list-disc pl-6">
            <li v-for="(error, index) in assetTimeSeriesStore.assetTimeSeriesRowErrors" :key="index">
              {{ `Row ${error.loc[0]}, column '${error.loc[1]}': ${error.msg}` }}
            </li>
          </ul>
        </template>
      </p>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useAssetTimeSeriesStore } from "../assetTimeSeries.state"

const assetTimeSeriesStore = useAssetTimeSeriesStore()

const numErrors = assetTimeSeriesStore.numErrors
const numErrorRows = assetTimeSeriesStore.assetTimeSeriesRowErrors.length

const rowsWithErrorsMessage = numErrorRows === 1 ? `1 row with errors` : `${numErrorRows} rows with errors`

const areIs = (num: number) => (num === 1 ? "is" : "are")
</script>
