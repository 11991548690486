import { SiteAsset, mapSiteAsset } from "./siteAsset"

export type SiteType = {
  id: number
  name: string
  text?: string
  type: string
  address: {
    street: string
    city: string
    state: string
    postalCode: string
    country: string
  }
  location: {
    longitude: number
    latitude: number
  }
  customId?: string
  custom?: string
  updatedTime?: string
  assets: Array<SiteAsset>
}

export const mapSite = (s: Site) => {
  s.assets = s.assets.map(mapSiteAsset)
  return s
}

export class Site implements SiteType {
  [key: string]: any
  id = 0
  name = ""
  text = ""
  type = ""
  address = {
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  }
  location!: {
    longitude: number
    latitude: number
  }
  customId = ""
  custom = ""
  updatedTime = undefined
  assets = new Array<SiteAsset>()
}
