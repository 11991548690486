import { defineStore } from "pinia"
import { ref, Ref, computed } from "vue"
import { Supplier, HighlightProject } from "@common/models/supplier"
import { TYPE, useToast } from "vue-toastification"
import { useSupplierService } from "@/services/service-container"

export const useAdminSuppliersStore = defineStore("adminSuppliers", () => {
  const isLoading = ref<boolean>(true)
  const isSaving = ref<boolean>(false)
  const suppliersMap = ref(new Map()) as Ref<Map<number, Supplier>>

  const supplierService = useSupplierService()
  const toast = useToast()

  const loadSuppliers = async () => {
    isLoading.value = true
    try {
      const suppliersList = await supplierService.listSuppliersAdmin()
      suppliersMap.value = new Map(suppliersList.map((s) => [s.accountId, s]))
    } finally {
      isLoading.value = false
    }
  }

  const _toastify = <T extends Array<any>, U>(message: string, fn: (...args: T) => U) => {
    return async (...args: T): Promise<U> => {
      isSaving.value = true
      const toastId = toast(`${message}…`, { id: "Suppliers", type: TYPE.DEFAULT, timeout: false })

      try {
        const result = await fn(...args)
        toast.update(toastId, { content: "Success!", options: { type: TYPE.SUCCESS, timeout: 1000 } })
        return result
      } catch (err) {
        toast.update(toastId, { content: `Error: ${err}`, options: { type: TYPE.ERROR } })
        throw err
      } finally {
        isSaving.value = false
      }
    }
  }

  const upsertSupplier = _toastify("Updating", async (accountId: number, supplier: Supplier) => {
    let newSupplier
    if (supplier.accountId === undefined) {
      newSupplier = await supplierService.addSupplier({ ...supplier, accountId: accountId } as Supplier)
    } else {
      newSupplier = await supplierService.updateSupplier(supplier)
    }
    suppliersMap.value.set(newSupplier.accountId, newSupplier)
    return newSupplier
  })

  const addSupplierImage = _toastify("Uploading", async (supplierId: number, file: File, description: string) => {
    const updatedSupplier = await supplierService.addImage(supplierId, file, description)
    suppliersMap.value.set(updatedSupplier.accountId, updatedSupplier)
    return updatedSupplier
  })

  const updateSupplierImage = _toastify("Updating", async (supplierId: number, imageId: number, description: string) => {
    const updatedSupplier = await supplierService.updateImage(supplierId, imageId, description)
    suppliersMap.value.set(updatedSupplier.accountId, updatedSupplier)
    return updatedSupplier
  })

  const removeSupplierImage = _toastify("Removing", async (supplierId: number, imageId: number) => {
    const updatedSupplier = await supplierService.removeImage(supplierId, imageId)
    suppliersMap.value.set(updatedSupplier.accountId, updatedSupplier)
    return updatedSupplier
  })

  const addSupplierProject = _toastify("Uploading", async (supplierId: number, project: HighlightProject) => {
    const updatedSupplier = await supplierService.addProject(supplierId, project)
    suppliersMap.value.set(updatedSupplier.accountId, updatedSupplier)
    return updatedSupplier
  })

  const updateSupplierProject = _toastify("Updating", async (supplierId: number, project: HighlightProject) => {
    const updatedSupplier = await supplierService.updateProject(supplierId, project)
    suppliersMap.value.set(updatedSupplier.accountId, updatedSupplier)
    return updatedSupplier
  })

  const removeSupplierProject = _toastify("Removing", async (supplierId: number, projectId: number) => {
    const updatedSupplier = await supplierService.removeProject(supplierId, projectId)
    suppliersMap.value.set(updatedSupplier.accountId, updatedSupplier)
    return updatedSupplier
  })

  const getSupplierByAccountId = computed(() => {
    return (accountId: number) => suppliersMap.value.get(accountId)
  })

  const suppliers = computed(() => Array.from(suppliersMap.value.values()))

  // automatically load suppliers on first instantiation
  loadSuppliers()

  return {
    // state
    isLoading,
    isSaving,
    // actions
    upsertSupplier,
    addSupplierImage,
    updateSupplierImage,
    removeSupplierImage,
    addSupplierProject,
    updateSupplierProject,
    removeSupplierProject,
    // getters
    getSupplierByAccountId,
    suppliers,
  }
})
