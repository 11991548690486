import { ref, computed } from "vue"
import { defineStore } from "pinia"
import { ListingQuarter, Procurement } from "@common/models/listing"
import type { AssetCategory } from "@common/models/asset"
import { MarketplaceStory } from "@common/models/story"

export type ListingFilters = {
  deviceCategory: AssetCategory[] | null
  state: string[] | null
  quarter: ListingQuarter[] | null
}

export type Checkout = {
  procurement: Procurement
  story: MarketplaceStory
}

export const useMarketStore = defineStore(
  "market",
  () => {
    // Listing filters

    const listingFilters = ref<ListingFilters>({
      deviceCategory: null,
      state: null,
      quarter: null,
    })

    const setListingFilters = (filters: ListingFilters) => {
      listingFilters.value = filters
    }

    const hasActiveFilters = computed(() => {
      return (
        (listingFilters.value.deviceCategory !== null && listingFilters.value.deviceCategory.length > 0) ||
        (listingFilters.value.state !== null && listingFilters.value.state.length > 0) ||
        (listingFilters.value.quarter !== null && listingFilters.value.quarter.length > 0)
      )
    })

    const listingFilterParams = computed(() => {
      // skips entries that are null or empty
      const params = Object.fromEntries(
        Object.entries(listingFilters.value).filter(([key, value]) => {
          return key && value && value.length > 0
        })
      )
      if (params.state) {
        params.state = listingFilters.value.state
      }
      return params
    })

    const procurementQuantity = ref<number>(1000)
    const procurementQuantityUnit = ref<any>({ label: "MWh", value: "electricity" })
    const procurementSort = ref<string>("carbon_per_energy")

    // Checkout

    const checkout = ref<Checkout>({
      procurement: new Procurement(),
      story: new MarketplaceStory(),
    })

    const setCheckout = (newCheckout: Checkout) => {
      checkout.value = newCheckout
    }

    const clearCheckout = () => {
      checkout.value = {
        procurement: new Procurement(),
        story: new MarketplaceStory(),
      }
    }

    const customOrderSubmittedStoryIds = ref<number[]>([])

    return {
      listingFilters,
      listingFilterParams,
      hasActiveFilters,
      setListingFilters,
      procurementQuantity,
      procurementQuantityUnit,
      procurementSort,
      checkout,
      setCheckout,
      clearCheckout,
      customOrderSubmittedStoryIds,
    }
  },
  {
    persist: {
      storage: localStorage,
      paths: ["checkout"],
    },
  }
)
