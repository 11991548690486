<template>
  <WcModal :is-open="isOpen" header="Edit Site" size="xlarge" @update:is-open="$emit('update:isOpen', false)">
    <template #panel>
      <WcSiteEdit :site="props.site" @dismissed="$emit('update:isOpen', false)" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import WcModal from "@/components/WcModal.vue"
import WcSiteEdit from "./components/WcSiteEdit.vue"
import { PropType } from "vue"
import { Site } from "@common/models/site"

const props = defineProps({
  site: { type: Object as PropType<Site>, required: true },
  isOpen: { type: Boolean, default: false },
})
defineEmits(["update:isOpen"])
</script>

<style scoped></style>
