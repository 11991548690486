<template>
  <div>
    <AssetMetadataTable class="mt-8" title="Solar Info" :metadata="details" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import type { SolarAsset } from "@common/models/asset"
import { REC_REGISTRY } from "@common/models/asset"
import AssetMetadataTable from "./AssetMetadataTable.vue"

const { asset } = defineProps<{ asset: SolarAsset }>()

const details = computed(() => {
  return {
    "Meter ID": asset.meterId,
    "Nameplate Capacity (kW)": asset.nameplateCapacityKw,
    "Commenced Operation Date": asset.commencedOperationDate ? new Date(asset.commencedOperationDate).toISOString().split("T")[0] : "",
    "Estimated Annual Production (MWh)": asset.estimatedAnnualProductionMwh,
    "EIA Plant ID": asset.eiaPlantId,
    "External Registry Name": asset.externalRegistryName ? REC_REGISTRY[asset.externalRegistryName] : "",
    "External Registry Device ID": asset.externalRegistryDeviceId,
  }
})
</script>
