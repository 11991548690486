import { CSVToArray } from "@common/services/utility.helper"
import type { EacBalance, EacBalances, NullableEacRangeSelection } from "@/modules/accounts/eac.state"

const TIMESTAMP_FIELD_NAME = "Generation/Savings Time"

export const parseEacCsvData = (eacCsvData: any) => {
  const data = CSVToArray(eacCsvData, ",")
  // find the index of the first row that is an array of just one empty string
  // this is the row that separates the introductory matter from the headers and data
  const firstEmptyRowIndex = data.findIndex((row) => row.length === 1 && row[0] === "")
  const headers: string[] = data[firstEmptyRowIndex + 1]
  const rows = data.slice(firstEmptyRowIndex + 2)
  // turn csv array into a javascript object using the first row as headers
  const records = rows
    .filter((row) => row.length > 0 && row[0] != "")
    .map((row) => {
      return row.reduce((obj, value, index) => {
        obj[headers[index]] = value
        return obj
      }, {} as any)
    })
    .sort((a: any, b: any) => {
      const aDate = new Date(a[TIMESTAMP_FIELD_NAME])
      const bDate = new Date(b[TIMESTAMP_FIELD_NAME])
      return aDate.getTime() - bDate.getTime()
    })
  return { records: records, headers: headers }
}

export const isBalanceSelected = (balance: EacBalance, selection: NullableEacRangeSelection) => {
  if (!selection) return false
  const { minDateIndex, maxDateIndex, minHourIndex, maxHourIndex } = selection
  return (
    minHourIndex <= balance.hourIndex && balance.hourIndex <= maxHourIndex && minDateIndex <= balance.dateIndex && balance.dateIndex <= maxDateIndex
  )
}

export const filterToSelectedBalances = (eacBalances: EacBalances, selection: NullableEacRangeSelection): EacBalance[] => {
  return Object.values(eacBalances).filter((balance) => isBalanceSelected(balance, selection))
}

export const quantityAcrossBalances = (balances: EacBalance[]) => {
  return balances.reduce((total, balance) => total + balance.active + balance.retired, 0)
}

export const activeQuantityAcrossBalances = (balances: EacBalance[]) => {
  return balances.reduce((total, balance) => total + balance.active, 0)
}
