<template>
  <WcButton v-if="!store.isVisible" v-bind="$props" text="See Procurement Options" @click="store.show" />
</template>

<script setup lang="ts">
import { WcButton } from "@/components/button"

import { useProcurementStore } from "./procurement-store"

const store = useProcurementStore()
</script>
