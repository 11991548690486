<template>
  <div class="mt-4 flex w-full flex-col md:mt-0 md:px-10 md:pb-6">
    <form class="w-full" @submit.prevent="register(email)">
      <h3 v-if="!hasSubmitted" class="text-heading-5 mb-4">Sign in or register to continue</h3>
      <h3 v-else class="text-heading-5 mb-4">Check your email</h3>
      <div v-if="errors.length > 0" class="text-body-3 alert danger my-4 w-full text-error">
        <ol>
          <li v-for="e in errors" :key="e">{{ e }}</li>
        </ol>
      </div>
      <div v-if="hasSubmitted" class="text-body-3 mt-6">
        <p><strong>We sent you a link to sign in and continue.</strong></p>
        <p class="mt-4">This link will expire in 10 minutes, and each link can only be used once.</p>
      </div>
      <TextInput v-model="email" class="mt-6" label="Email" type="email" name="email" />
      <ButtonCTA v-if="!hasSubmitted" type="submit" class="mt-5" width="full" button-classes="bg-blue-90 text-white hover:bg-blue-40"
        >Sign in / Register</ButtonCTA
      >
      <ButtonCTA
        v-else
        type="submit"
        class="mt-5"
        width="full"
        button-classes="bg-blue-90 text-white hover:bg-blue-40"
        @click.prevent="register(email)"
        >Resend link</ButtonCTA
      >
    </form>
  </div>
</template>

<script setup lang="ts">
// Libraries
import { computed, ref } from "vue"
// Services
import { useAuthStore } from "@/modules/auth/auth.state"
import { useAuthService } from "@/services/service-container"
// Components
import TextInput from "@/components/ui/TextInput.vue"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"

const { callbackUrl, useWwwAuth = false } = defineProps<{
  callbackUrl: string
  useWwwAuth?: boolean
}>()

const authService = useAuthService()
const authStore = useAuthStore()
const email = ref("")

const errors = computed(() => authStore.errors)
const hasSubmitted = ref(false)

const register = async (email: string) => {
  authStore.resetErrors()
  try {
    await authService.registerMagic(email, callbackUrl, useWwwAuth)
    hasSubmitted.value = true
  } catch {
    authStore.addError("There was a problem with that account")
    authStore.addError("Please check your credentials")
  }
}
</script>

<style scoped></style>
