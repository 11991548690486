import ApiFetcher from "@/services/api-fetcher"
import { Portfolio, PortfolioStake, PortfolioSupplier } from "@common/models/order"

export interface PortfolioStakeFilter {
  accountId?: number
  portfolioId?: number
}

export default class PortfolioService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }
  listPortfolios = async (): Promise<Array<Portfolio>> => {
    const endpoint = "/portfolios"
    return await this.fetcher.httpGet<Array<Portfolio>>(endpoint, {})
  }
  getPortfolio = async (id: number): Promise<Portfolio> => {
    // can include switch statements for the parameter
    const endpoint = `/portfolios/${id}`
    const result = await this.fetcher.httpGet<Portfolio>(endpoint, { portfolioId: id })
    return Object.assign(new Portfolio(), result)
  }
  listPortfoliosAdmin = async (): Promise<Array<Portfolio>> => {
    const endpoint = "/portfolios/admin"
    return await this.fetcher.httpGet<Array<Portfolio>>(endpoint, {})
  }
  addPortfolio = async (portfolioRequest: Portfolio) => {
    const endpoint = "/portfolios"
    return await this.fetcher.httpPost<Portfolio>(endpoint, portfolioRequest)
  }
  updatePortfolio = async (portfolioRequest: Portfolio) => {
    const endpoint = `/portfolios/${portfolioRequest.id}`
    return await this.fetcher.httpPut<Portfolio>(endpoint, portfolioRequest)
  }
  uploadImage = async (portfolioId: number, file: any) => {
    const endpoint = `/portfolios/${portfolioId}/image`
    return await this.fetcher.httpUploadPut<Portfolio>(endpoint, { image: file }, file)
  }
  deletePortfolio = async (portfolioId: number) => {
    const endpoint = `/portfolios/${portfolioId}`
    return await this.fetcher.httpDelete(endpoint, {})
  }
  attachSupplier = async (id: number, supplierId: number, quantity: number) => {
    const endpoint = `/portfolios/${id}/suppliers/${supplierId}`
    return await this.fetcher.httpPut<PortfolioSupplier>(endpoint, { quantity: quantity })
  }
  detachSupplier = async (id: number, supplierId: number) => {
    const endpoint = `/portfolios/${id}/suppliers/${supplierId}`
    return await this.fetcher.httpDelete<PortfolioSupplier>(endpoint)
  }
  listStakes = async (filter: PortfolioStakeFilter) => {
    const endpoint = `/portfolios/stakes`
    return await this.fetcher.httpGet<PortfolioStake[]>(endpoint, filter)
  }
  createStake = async (portfolioId: number, accountId: number, weight: number) => {
    const endpoint = `/portfolios/${portfolioId}/stakes`
    return await this.fetcher.httpPost<PortfolioStake>(endpoint, { accountId: accountId, weight: weight })
  }
  deleteStake = async (portfolioId: number, accountId: number) => {
    const endpoint = `/portfolios/${portfolioId}/stakes/${accountId}`
    await this.fetcher.httpDelete(endpoint)
  }
}
