<template>
  <section class="flex w-full flex-col">
    <div class="flex rounded-md bg-neutral-20 p-2">
      <div class="whitespace-nowrap px-3 py-2 text-sm font-bold">Data source</div>
      <nav class="tabs flex w-full items-center justify-evenly space-x-4" data-cy="tabs-asset-dataSources">
        <div
          v-for="d in dataSourceOptions"
          :key="d.key"
          :class="{ active: d.key === dataSource.key }"
          class="cursor-pointer rounded-md px-3 py-2 text-sm font-medium"
          @click="setDataSource(d)">
          {{ d.name }}
        </div>
      </nav>
    </div>
    <section>
      <section v-if="dataSource.type === 'modeled'" class="pt-4">
        <div class="q-mb-sm">
          Model the asset's hourly energy use based on {{ dataSource.name.toLowerCase() }}<span class="pl-0 text-error" title="Required">*</span>.
        </div>
        <section class="flex items-center justify-between" style="width: 100%">
          <input
            v-model="dataModelValue"
            type="text"
            inputmode="decimal"
            data-cy="input-asset-modelValue"
            :class="{ 'border-error': !dataModelValue }"
            @change="writeModelParameters()" />
        </section>
      </section>
      <section v-if="dataSource.type === 'uploaded'">
        <WcAssetDataUpload :asset="asset" @uploaded="$emit('saveAsset')" @update:file-count="onFileCountChanged" />
      </section>
      <section v-if="dataSource.type === 'connected'">
        <div class="mt-2 flex flex-col gap-2">
          <label>
            <span class="pl-0">API Token<span class="pl-0 text-error" title="Required">*</span></span>
            <input v-model="apiToken" :class="{ 'border-error': !apiToken }" @change="writeModelParameters()" />
          </label>
          <label>
            <span class="pl-0">Meter UID<span class="pl-0 text-error" title="Required">*</span></span>
            <input v-model="meterUid" :class="{ 'border-error': !meterUid }" @change="writeModelParameters()" />
          </label>
        </div>
      </section>
    </section>
  </section>
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, ref, watch } from "vue"
import { SiteAsset } from "@common/models/siteAsset"
import WcAssetDataUpload from "./WcAssetDataUpload.vue"
import { gModelDict } from "@common/global"

const dataSources = [
  {
    order: 2,
    icon: "material-symbols:star",
    name: "Annual MWh",
    text: "Model hourly energy use",
    type: "modeled",
    key: "annualUsageMwh",
  },
  {
    order: 1,
    icon: "material-symbols:circle",
    name: "Area (ft²)",
    text: "Model hourly energy use",
    type: "modeled",
    key: "areaSqft",
  },
  {
    order: 1,
    icon: "material-symbols:circle",
    name: "Nameplate Capacity (kW)",
    text: "Model hourly energy use",
    type: "modeled",
    key: "nameplateCapacityKw",
  },
  {
    order: 1,
    icon: "material-symbols:circle",
    name: "Nameplate Capacity (MW)",
    text: "Model hourly energy use",
    type: "modeled",
    key: "nameplateCapacityMw",
  },
  {
    order: 2,
    icon: "material-symbols:star",
    name: "Annual energy production (MWh)",
    text: "Model hourly energy use",
    type: "modeled",
    key: "annualEnergyGeneratedMwh",
  },
  {
    order: 3,
    icon: "material-symbols:stars",
    name: "Upload",
    text: "Use your energy data",
    type: "uploaded",
    key: "none",
  },
  {
    order: 4,
    icon: "material-symbols:stars",
    name: "Connect",
    text: "Automatic data upload",
    type: "connected",
    key: "apiToken",
  },
]

const props = defineProps({
  asset: { type: Object as PropType<SiteAsset>, required: true },
})
const emit = defineEmits(["saveAsset", "update:isValid", "update:hasButton", "update:asset"])

const asset = ref<SiteAsset>(props.asset)
const dataSource = ref({} as any)
const dataModelValue = ref<any>(0)
const meterUid = ref()
const apiToken = ref()
const fileCount = ref<number>(0)

const dataSourceOptions = computed(() => {
  if (!Object.hasOwn(gModelDict, asset.value.type)) {
    return []
  }
  // Get the list of valid parameters for a model to see what options to show. upload and connected are always valid.
  const params = gModelDict[asset.value.type].params
  return dataSources
    .filter((o: any) => {
      return o.type == "uploaded" || o.type === "connected" || params.indexOf(o.key) > -1
    })
    .sort((a, b) => a.order - b.order)
})

const setDataSource = (source: any) => {
  if (!source) {
    return
  }
  dataSource.value = source
  dataModelValue.value = 0

  if (dataSource.value.type === "uploaded") {
    onFileCountChanged(fileCount.value)
  } else {
    emit("update:hasButton", false)
    writeModelParameters()
  }
}

const readModelParameters = (asset: SiteAsset) => {
  if (asset.dataSource === "connected") {
    dataSource.value = dataSourceOptions.value.find((o: any) => o.type === "connected")
    meterUid.value = asset.modelParameters.meterInternalId
    apiToken.value = asset.modelParameters.apiToken
  } else {
    let source = dataSourceOptions.value.find((o: any) => o.type === asset.dataSource) ?? dataSourceOptions.value[0]
    let value = 0
    Object.entries(asset.modelParameters).forEach(([parameterName, parameterValue]: [string, string]) => {
      const option = dataSourceOptions.value.find((o: any) => o.key === parameterName)
      if (option) {
        const asNumber = Number(parameterValue)
        if (asNumber > 0) {
          source = option
          value = asNumber
          return
        }
      }
    })

    dataSource.value = source
    dataModelValue.value = value
  }

  writeModelParameters()
}

const getAsset = (asset: any, type: string) => {
  switch (type) {
    case "connected":
      return {
        ...asset,
        dataSource: "connected",
        modelParameters: {
          apiToken: apiToken.value,
          meterInternalId: meterUid.value,
        },
      }
    case "uploaded":
      return {
        ...asset,
        dataSource: "uploaded",
        modelParameters: {},
      }
    default:
      return {
        ...asset,
        dataSource: dataSource.value.type,
        modelParameters: {
          [dataSource.value.key]: dataModelValue.value,
        },
      }
  }
}
const writeModelParameters = () => {
  let isValid = false
  if (!dataSource.value) {
    return
  }
  asset.value = getAsset(asset.value, dataSource.value.type)
  if (dataSource.value.type === "connected") {
    isValid = apiToken.value && meterUid.value
  } else if (dataSource.value.type === "uploaded") {
    isValid = fileCount.value > 0
  } else {
    isValid = Number(dataModelValue.value) > 0
  }

  emit("update:asset", asset.value)
  emit("update:isValid", isValid)
}

const onFileCountChanged = (newValue: number) => {
  fileCount.value = newValue
  emit("update:hasButton", newValue == 0)
  writeModelParameters()
}

onMounted(() => {
  readModelParameters(asset.value)
})

watch(
  () => props.asset.type,
  () => {
    readModelParameters(props.asset)
  }
)
watch(
  () => props.asset,
  (newAsset) => {
    if (newAsset.id !== asset.value.id) {
      asset.value = newAsset
      readModelParameters(asset.value)
    }
  }
)
</script>

<style scoped></style>
