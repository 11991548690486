<template>
  <AssetMetadataTable class="mt-8" :title="title" :metadata="details" />
</template>

<script setup lang="ts">
import { computed } from "vue"
import type { Asset } from "@common/models/asset"
import { formatGeneralAssetTypeMetadata, userFacingStringValue } from "./assetMetadata.utils"
import AssetMetadataTable from "./AssetMetadataTable.vue"

const { asset } = defineProps<{ asset: Asset }>()

const title = computed(() => `${userFacingStringValue(asset.kind as string)} Info`)

const details = computed(() => {
  const metadata = Object.entries(asset).reduce(
    (acc, [key, value]) => {
      acc[key] = value
      return acc
    },
    {} as Record<string, unknown>
  )
  return formatGeneralAssetTypeMetadata(metadata)
})
</script>
