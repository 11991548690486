<template>
  <div class="w-full rounded-lg border border-neutral-30 px-6">
    <div>
      <section class="border-bottom relative -mx-6 rounded-t-lg bg-highlight-pastelazure px-6 py-8">
        <div class="flex items-start">
          <h3 class="text-subheading-1">Procurement options</h3>
          <ToolTip class="ph-no-capture inline-block">
            Your procurement will select EACs from the marketplace according to the filters below. Use the last option to prioritize selections by
            carbon impact or price.
          </ToolTip>
        </div>
        <button class="pointer-events absolute right-4 top-4 p-2 hover:opacity-50" @click="handleCloseProcurementOptions">
          <CloseIcon icon-classes="fill-black" />
        </button>
        <form class="mt-4" @submit.prevent="getListingProcurement">
          <div class="text-body-2 flex flex-wrap items-center justify-start gap-2">
            Procure up to
            <div class="flex shrink basis-[235px] items-center gap-2">
              <WcInputNumber
                v-model.number="marketStore.procurementQuantity"
                class="basis-full"
                name="quantity"
                :min="1"
                :max="50000"
                @input="handleQuantityChange" />
              <WcDropdown
                v-model="marketStore.procurementQuantityUnit"
                class="basis-32"
                name="priceFunction"
                :options="quantityUnitOptions"
                :show-clear="false"
                inset-label="Price Unit" />
            </div>
            of
            <SelectResourceType v-model="marketStore.listingFilters.deviceCategory" class="basis-12" name="deviceCategory" />
            EACs in
            <SelectUsState v-model="marketStore.listingFilters.state" class="basis-12" name="state" />
            from
            <SelectDispatchDate v-model="marketStore.listingFilters.quarter" class="basis-12" name="quarter" />
            selected by
            <SelectListingSort v-model="marketStore.procurementSort" class="basis-[180px]" />
          </div>
          <div class="mt-8 flex flex-wrap gap-4">
            <WcButton text="Update" type="submit" :is-disabled="!canClickUpdateProcurement" class="flex-1 md:flex-initial" />
            <WcButton
              text="Revert changes"
              variant="secondary"
              :is-disabled="!canClickUpdateProcurement"
              class="flex-1 md:flex-initial"
              @click="revertProcurementParameters" />
          </div>
        </form>
      </section>
      <section class="pb-12">
        <h3 class="text-subheading-large-bold mt-8">Your procurement</h3>
        <InlineError v-if="hasError" class="mx-auto max-w-main-content" error-message="There was a problem loading EAC listings. Please try again." />
        <InlineLoading v-if="isLoading" />
        <div v-else>
          <div v-if="isProcurementAvailableWithVolume" class="text-body-1 mt-8">
            Here is a breakdown of the EACs in your procurement. To change them, adjust the procurement options above.
          </div>
          <div v-else class="text-body-2 mt-8 text-error">
            There are no EACs available from this story that match your search filters. Please select different filters or enter a larger procurement
            volume.
            <button class="underline hover:text-blue-40" @click="openCalendarDialog">Contact us</button> to pre-order future EACs.
          </div>
          <div class="wc-homepage-grid mt-8">
            <div class="col-span-12 mb-4 md:col-span-4">
              <h3 class="text-subheading-1 mb-4">Total quantity</h3>
              <div class="text-body-1" data-cy="procurement-quantity">
                <span v-if="marketStore.procurementQuantityUnit.value === 'electricity'">{{ procurementQuantity }} ({{ procurementCarbon }})</span>
                <span v-else>{{ procurementCarbon }} ({{ procurementQuantity }})</span>
              </div>
              <div class="text-body-2 mt-2 text-sagetone md:max-w-[360px]">
                EACs are sold in batches, according to the asset that generated the EACs and the quarter in which they were generated. This is the
                total volume of EAC batches that matched your search criteria. If you are looking for more EACs, adjust your filters, enter a larger
                volume, or contact us.
              </div>
            </div>
            <div class="col-span-12 max-h-[320px] md:col-span-8 md:max-h-[400px]">
              <div class="mb-4 flex items-start justify-between">
                <div class="flex items-start">
                  <h3 class="text-subheading-1">Matching EACs</h3>
                  <ToolTip class="ph-no-capture inline-block"> Your procurement will consist of EACs generated by these assets </ToolTip>
                </div>
                <div class="-mt-4">
                  <WcViewModeButton v-model="selectedMatchingEacsView" size="small" />
                </div>
              </div>
              <div
                v-if="selectedMatchingEacsView === ViewMode.table"
                class="h-full max-h-[320px] overflow-scroll border border-neutral-30 md:max-h-[400px]">
                <WcTable :data="assetsData" :columns="assetColumns" :sortable="false" row-id-field="id" class="w-full" td-class="py-2 text-body-3" />
              </div>
              <div v-else class="relative h-[320px] md:col-span-4 md:h-[400px]">
                <WcMap :locations="assetLocations" />
                <div class="text-body-1 absolute left-6 top-6 rounded-lg bg-white p-4 text-blue-70">
                  <strong>{{ assets.length }}</strong> assets
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mt-6 border-t border-neutral-30 py-6 md:py-12">
        <div class="items-top flex flex-wrap justify-between">
          <div class="mb-4 shrink-0">
            <h3 class="text-subheading-1 mb-4">Total price</h3>
            <div class="font-bold">{{ procurementPrice }}</div>
          </div>
          <div class="w-full sm:w-auto sm:text-right">
            <WcButton
              text="Continue to Checkout"
              :is-disabled="!isProcurementAvailableWithVolume"
              class="w-full sm:w-auto"
              @click="handleClickCheckout" />
            <div class="mt-2 md:mt-4">
              <button class="underline hover:text-blue-40" @click="openContactForm">Contact us</button> to arrange enterprise payment
            </div>
          </div>
        </div>
      </section>
    </div>
    <ContactUsForm
      ref="contactUsForm"
      client:load
      :form-context="`Procurement view for Story ${props.story.id}: ${props.story.name}`"
      title="Contact us to purchase EACs"
      success-message="We'll contact you shortly to arrange your procurement." />
    <CalendarDialog
      ref="calendarDialog"
      client:load
      :form-context="`Procurement view for Story ${props.story.id}: ${props.story.name}`"
      title="Let's talk!"
      prompt-text="Schedule a call with our team to discuss pre-ordering EACs and explore how we can work together." />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, defineEmits } from "vue"
import { useRouter } from "vue-router"
import posthog from "posthog-js"
import formatCurrencyInPenniesFactory from "@common/utils/formatCurrencyInPenniesFactory"
import { MarketplaceStory } from "@common/models/story"
import { ASSET_KIND, AssetKind } from "@common/models/asset"
import type { ProcurementAsset } from "@common/models/listing"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import { Procurement } from "@common/models/listing"
import { useListingService } from "@/services/service-container"
import { useMarketStore } from "./market.state"
import { WcButton } from "@/components/button"
import CalendarDialog from "@/components/CalendarDialog.vue"
import CloseIcon from "@/components/icon/CloseIcon.vue"
import ContactUsForm from "@/components/ContactUsForm.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import InlineError from "@/components/ui/InlineError.vue"
import SelectListingSort from "@/modules/market/components/filter/SelectListingSort.vue"
import SelectResourceType from "@/modules/market/components/filter/SelectResourceType.vue"
import SelectDispatchDate from "@/modules/market/components/filter/SelectDispatchDate.vue"
import SelectUsState from "@/modules/market/components/filter/SelectUsState.vue"
import ToolTip from "@/components/ui/ToolTip.vue"
import { ViewMode, WcDropdown, WcInputNumber, WcInputNumberInputEvent, WcViewModeButton } from "@/components/input"
import WcMap from "@/components/WcMap/WcMap.vue"
import { Location } from "@/components/WcMap/WcMap.utils"
import WcTable from "@/components/WcTable/WcTable.vue"

const router = useRouter()
const listingService = useListingService()
const marketStore = useMarketStore()
const formatCurrencyInPennies = formatCurrencyInPenniesFactory()

const props = defineProps<{
  story: MarketplaceStory
}>()
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(true)

const quantityUnitOptions = [
  { label: "MWh", value: "electricity" },
  { label: "tCO2", value: "carbon" },
]
const handleQuantityChange = async ({ value }: WcInputNumberInputEvent) => {
  if (typeof value == "number") {
    marketStore.procurementQuantity = value
  }
}

const assetColumns = [
  { key: "id", label: "WEATS ID" },
  { key: "resourceType", label: "Resource Type" },
  { key: "energy", label: "Energy" },
  { key: "carbonValue", label: "Carbon Value" },
  { key: "price", label: "Price" },
]
const assets = ref<ProcurementAsset[]>([] as ProcurementAsset[])
const assetsData = computed(() => {
  return assets.value.map((asset) => ({
    id: asset.id,
    resourceType: ASSET_KIND[asset.kind as AssetKind],
    energy: getFormattedEacQuantity(asset.totalEacs || 0, EacMeasurementParameter.Electricity, 2),
    carbonValue: getFormattedEacQuantity(asset.totalGco2 || 0, EacMeasurementParameter.CarbonDioxide, 2),
    price: formatCurrencyInPennies(asset.pricePenniesUsd),
  }))
})
const assetLocations = computed<Location[]>(() => {
  return assets.value.reduce((acc, asset) => {
    const { coordinates } = asset
    return [
      ...acc,
      {
        ...coordinates,
        metadata: asset,
      },
    ]
  }, [] as Location[])
})
const procurement = ref<Procurement | null>(null)
const procurementPrice = computed(() => formatCurrencyInPennies(procurement.value?.pricePenniesUsd || 0))
const procurementQuantity = computed(() => getFormattedEacQuantity(procurement.value?.totalEacs || 0, EacMeasurementParameter.Electricity, 2))
const procurementCarbon = computed(() => getFormattedEacQuantity(procurement.value?.totalGco2 || 0, EacMeasurementParameter.CarbonDioxide, 2))
const isProcurementAvailableWithVolume = computed(() => !!procurement.value?.totalEacs)

const getSelectedProcurementFilters = () => {
  return {
    ...marketStore.listingFilters,
    storyId: props.story.id,
    desiredQuantity: marketStore.procurementQuantity,
    quantityUnits: marketStore.procurementQuantityUnit,
    sort: marketStore.procurementSort,
  }
}
const currentProcurementParameters = ref<any>({})
const revertProcurementParameters = () => {
  marketStore.setListingFilters({
    deviceCategory: currentProcurementParameters.value.deviceCategory,
    state: currentProcurementParameters.value.state,
    quarter: currentProcurementParameters.value.quarter,
  })
  marketStore.procurementQuantity = currentProcurementParameters.value.desiredQuantity
  marketStore.procurementQuantityUnit = currentProcurementParameters.value.quantityUnits
  marketStore.procurementSort = currentProcurementParameters.value.sort
}

// This is hideous, but it's done this way, instead of using computed(), because computed() doesn't react to updates to marketStore.listingFilters
const canClickUpdateProcurement = ref<boolean>(false)
const updateCanClickUpdateProcurement = () => {
  const selectedProcurementParameters = getSelectedProcurementFilters()
  canClickUpdateProcurement.value = JSON.stringify(selectedProcurementParameters) !== JSON.stringify(currentProcurementParameters.value)
}
marketStore.$subscribe(() => {
  updateCanClickUpdateProcurement()
})

const getListingProcurement = async () => {
  try {
    isLoading.value = true
    hasError.value = false
    currentProcurementParameters.value = getSelectedProcurementFilters()
    const data = await listingService.getListingProcurement({
      ...marketStore.listingFilterParams,
      storyId: props.story.id,
      desiredQuantity: marketStore.procurementQuantity * 1000000,
      quantityUnits: marketStore.procurementQuantityUnit.value,
      sort: marketStore.procurementSort,
    })
    assets.value = data.devices
    procurement.value = data.procurement
    updateCanClickUpdateProcurement()
  } catch (error: any) {
    hasError.value = true
    console.error("There was an error loading the procurement.", error)
  }
  isLoading.value = false
}

// Initial load on mount
onMounted(async () => {
  getListingProcurement()
})

const contactUsForm = ref()
const openContactForm = () => {
  posthog.capture("Clicked on Contact Us from the Procurement View")
  contactUsForm.value.openContactForm()
}

const calendarDialog = ref()
const openCalendarDialog = () => {
  posthog.capture("Clicked on the pre-order Contact us button from the Procurement View")
  calendarDialog.value.openCalendarDialog()
}

const handleClickCheckout = () => {
  if (!procurement.value) return
  marketStore.setCheckout({
    procurement: procurement.value,
    story: props.story,
  })
  router.push({ name: "wc-listing-checkout" })
}

const emit = defineEmits(["closeProcurementOptions"])

const handleCloseProcurementOptions = () => {
  emit("closeProcurementOptions")
}

const selectedMatchingEacsView = ref<ViewMode>(ViewMode.table)
</script>
