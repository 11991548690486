import { Account, Coordinates } from "./models"
import type { AssetKind } from "./asset"

export class Listing {
  averageCarbonIntensityKgco2PerMwh!: number
  category!: string
  dateRange!: {
    lower: string
    upper: string
  }
  hoursOfDayUtc!: number[]
  id!: number
  pricePenniesUsd!: number
  source!: string
  timeOfWeek!: string
  totalEacs!: number
  isAvailable!: boolean
  provider!: string
  locations!: Coordinates[];
  [key: string]: any
}

export class ListingPurchase {
  id!: number
  status!: string
  listing!: Listing
  toAccount!: Account
  createdTime!: string
}

export class Procurement {
  listingIds!: number[]
  pricePenniesUsd!: number
  totalEacs!: number
  totalGco2!: number
}

export interface ProcurementAsset {
  id: number
  kind: AssetKind
  coordinates: Coordinates
  pricePenniesUsd: number
  totalEacs: number
  totalGco2: number
}

export interface ProcurementResponse {
  devices: ProcurementAsset[]
  procurement: Procurement
}

export function formatDateRange(dateRange: Listing["dateRange"]) {
  const { lower, upper } = dateRange
  if (lower === upper) {
    return lower
  }

  const upperDate = new Date(upper)
  upperDate.setDate(upperDate.getDate() - 1)
  const upperExclusive = upperDate.toISOString().split("T")[0]

  if (lower === upperExclusive) {
    return lower
  }

  return `${lower} - ${upperExclusive}`
}

// Transform UTC hours to the corresponding hours in the local time.
function adjustHoursToLocalTZ(hoursArray: number[]) {
  const newHoursArray = hoursArray.map((hour) => {
    const date = new Date()
    date.setUTCHours(hour)
    return date.getHours()
  })
  return newHoursArray
}

export function formatHoursOfDay(hoursArray: number[]) {
  if (!hoursArray || hoursArray.length === 0) {
    return ""
  }

  hoursArray = adjustHoursToLocalTZ(hoursArray)

  hoursArray.sort((a, b) => a - b)

  const ranges = []
  let start = hoursArray[0],
    end = start
  for (let i = 1; i < hoursArray.length; i++) {
    if (hoursArray[i] === end + 1) {
      end = hoursArray[i]
    } else {
      ranges.push(start === end ? `${format12Hour(start)}` : `${format12Hour(start)}-${format12Hour(end)}`)
      start = hoursArray[i]
      end = start
    }
  }
  ranges.push(start === end ? `${format12Hour(start)}` : `${format12Hour(start)}-${format12Hour(end)}`)

  return ranges.join(", ")
}

function format12Hour(hour: number) {
  if (hour === 0 || hour === 24) {
    return "12AM"
  } else if (hour < 12) {
    return `${hour}AM`
  } else if (hour === 12) {
    return "12PM"
  } else if (hour > 12 && hour < 24) {
    return `${hour - 12}PM`
  } else {
    return ""
  }
}

export class ListingQuarter {
  year: number
  quarter: number
  constructor(year: number, quarter: number) {
    if (quarter < 1 || quarter > 4) {
      throw new Error("Invalid quarter")
    }
    this.year = year
    this.quarter = quarter
  }
  toString() {
    return `${this.year}-Q${this.quarter}`
  }
  public static getLatestQuarter() {
    const date = new Date()
    const month = date.getMonth()
    const quarter = Math.floor(month / 3) + 1
    return new ListingQuarter(date.getFullYear(), quarter)
  }
  public static getAllQuartersBetween(firstQuarter: ListingQuarter, lastQuarter: ListingQuarter) {
    const quarters = []
    let currentQuarter = firstQuarter
    while (currentQuarter.year < lastQuarter.year || (currentQuarter.year === lastQuarter.year && currentQuarter.quarter <= lastQuarter.quarter)) {
      quarters.push(currentQuarter)
      currentQuarter = new ListingQuarter(currentQuarter.year, currentQuarter.quarter)
      if (currentQuarter.quarter === 4) {
        currentQuarter.year++
        currentQuarter.quarter = 1
      } else {
        currentQuarter.quarter++
      }
    }
    return quarters
  }
  public static getAllQuartersUpToCurrent(firstQuarter: ListingQuarter) {
    const latestQuarter = ListingQuarter.getLatestQuarter()
    return ListingQuarter.getAllQuartersBetween(firstQuarter, latestQuarter)
  }
}
