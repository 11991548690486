import mapboxgl from "mapbox-gl"
import type { Feature, GeoJsonProperties, Geometry } from "geojson"
import colors from "@common/colors"

// This is a public access token included in our web bundle, but scoped to wattcarbon domains
// The token is stored in the GitHub repository and injected at build time
export const MAPBOX_ACCESS_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN || import.meta.env.PUBLIC_MAPBOX_ACCESS_TOKEN

export type Location = {
  color?: string
  latitude: string
  longitude: string
  metadata?: Record<string, any>
  strokeColor?: string
}

type ColorCluster = {
  color?: string
  strokeColor?: string
}

export enum ClusterMode {
  donut = "donut",
  stacked = "stacked",
}

export function boundsToCenterCoordinate(bounds: mapboxgl.LngLatBounds) {
  const centerLat = (bounds.getSouthWest().lat - bounds.getNorthEast().lat) / 2 + bounds.getNorthEast().lat
  const centerLng = (bounds.getSouthWest().lng - bounds.getNorthEast().lng) / 2 + bounds.getNorthEast().lng
  return new mapboxgl.LngLat(centerLng, centerLat)
}

export function locationsToBounds(locations: Location[]) {
  if (locations.length === 0) {
    // When there are no locations, show the US (instead of using the default null island)
    return new mapboxgl.LngLatBounds(new mapboxgl.LngLat(-70, 44), new mapboxgl.LngLat(-124, 34))
  }

  // Initialize Lat/Lng at the extremes
  let lowestLng = 180
  let lowestLat = 90
  let highestLng = -180
  let highestLat = -90

  locations.forEach(({ latitude: latitudeProp, longitude: longitudeProp }) => {
    const latitude = Number(latitudeProp)
    const longitude = Number(longitudeProp)
    if (longitude < lowestLng) {
      lowestLng = longitude
    }
    if (longitude > highestLng) {
      highestLng = longitude
    }
    if (latitude < lowestLat) {
      lowestLat = latitude
    }
    if (latitude > highestLat) {
      highestLat = latitude
    }
  })

  return new mapboxgl.LngLatBounds(
    new mapboxgl.LngLat(lowestLng - 0.5, lowestLat - 0.5), // southwestern corner of the bounds plus margin
    new mapboxgl.LngLat(highestLng + 0.5, highestLat + 0.5) // northeastern corner of the bounds plus margin
  )
}

export function locationToFeature({ color, latitude, longitude, metadata, strokeColor }: Location): Feature<Geometry, GeoJsonProperties> {
  return {
    type: "Feature",
    properties: {
      color: color ?? colors.highlight.DEFAULT,
      metadata,
      strokeColor: strokeColor ?? color ?? colors.blue["40"],
    },
    geometry: {
      type: "Point",
      coordinates: [Number(longitude), Number(latitude)],
    },
  }
}

// Group locations by point when clustering
export function locationsToColorClusters(locations: Location[]) {
  const colorClusters: Record<string, ColorCluster> = {}

  locations.forEach(({ color, strokeColor }) => {
    if (color && !colorClusters[color]) {
      colorClusters[color] = { color, strokeColor: strokeColor || color }
    }
  })

  return Object.values(colorClusters)
}

// Adds properties by color that will track the number of points for each cluster
export function makeClusterProperties(colorClusters: ColorCluster[]) {
  return colorClusters.reduce((acc, { color }, index) => {
    const matchTerm = ["==", ["get", "color"], color]
    return {
      ...acc,
      [index]: ["+", ["case", matchTerm, 1, 0]],
    }
  }, {})
}

// The following logic for drawing custom markers to represent clusters is
// based on this Mapbox example:
// https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/

const createStackChart = (properties: Record<string, any>, colorClusters: ColorCluster[]) => {
  const colorsWithCounts = colorClusters
    .map(({ color, strokeColor }, index) => {
      return {
        color,
        strokeColor,
        count: properties[index],
      }
    })
    .filter(({ count }) => count > 0)
  const numColors = colorsWithCounts.length
  let total = 0
  for (const { count } of colorsWithCounts) {
    total += count
  }
  const perCircleOffset = 2
  const totalOffset = perCircleOffset * numColors
  const r = total < 15 ? 24 : total <= 40 ? 32 : 40
  const w = (r + totalOffset) * 2
  const htmlStart = `<div>
        <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="display: block" class="cursor-pointer">`
  const opacity = numColors === 1 ? 0.7 : 1

  const circles = colorsWithCounts.toReversed().map(({ color, strokeColor }, index) => {
    const offset = (numColors - index - 1) * perCircleOffset
    return `<circle stroke-width="2px" cx="${r + offset}" cy="${r + offset}" r="${
      r - 1
    }" fill="${color}" stroke="${strokeColor}" fill-opacity="${opacity}" />`
  })

  const htmlEnd = `<text dominant-baseline="central" transform="translate(${r}, ${r})">
            ${total.toLocaleString()}
        </text>
        </svg>
        </div>`

  const html = htmlStart + circles.join("\n") + htmlEnd
  const element = document.createElement("div")
  element.innerHTML = html
  return element.firstChild
}

const createDonutChart = (properties: Record<string, any>, colorClusters: ColorCluster[]) => {
  const offsets = []
  const counts = colorClusters.map((_color: any, index: number) => {
    return properties[index]
  })
  let total = 0
  for (const count of counts) {
    offsets.push(total)
    total += count
  }
  const r = total >= 40 ? 40 : total >= 15 ? 32 : 24
  const r0 = Math.round(r * 0.5)
  const w = r * 2

  let html = `<div>
        <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="display: block" class="cursor-pointer">`

  for (let i = 0; i < counts.length; i++) {
    html += donutSegment(offsets[i] / total, (offsets[i] + counts[i]) / total, r, r0, colorClusters[i].color || "")
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
        <text dominant-baseline="central" transform="translate(${r}, ${r})">
            ${total.toLocaleString()}
        </text>
        </svg>
        </div>`

  const element = document.createElement("div")
  element.innerHTML = html
  return element.firstChild
}

function donutSegment(start: number, end: number, r: number, r0: number, color: string) {
  if (end - start === 1) end -= 0.00001
  const a0 = 2 * Math.PI * (start - 0.25)
  const a1 = 2 * Math.PI * (end - 0.25)
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0)
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1)
  const largeArc = end - start > 0.5 ? 1 : 0

  return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0} A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${
    r + r0 * x1
  } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}" fill="${color}" />`
}

// Adds custom markers for any clusters on the map, expressing the distribution
// of colors represented by a cluster based on `clusterMode`
//
// Caches and tracks marker elements in `markersCache` and `markersOnScreen`
// for better performance as the user interacts with the map
export function customizeClusterMarkers(props: {
  sourceId: string
  map: mapboxgl.Map
  colorClusters: ColorCluster[]
  markersCache: Record<string, any>
  markersOnScreen: Record<string, any>
  clusterMode: ClusterMode
}) {
  const { map, colorClusters, markersCache, markersOnScreen, sourceId, clusterMode } = props

  const newMarkers = {} as Record<string, any>
  const features = map.querySourceFeatures(sourceId)

  // Create and add an HTML marker for each cluster on the screen
  // (if not already created)
  for (const feature of features) {
    const properties = feature.properties

    // Skip non-cluster points
    if (!properties?.cluster) {
      continue
    }

    const id = properties.cluster_id

    // Create a new cluster element if it's not already in the cache
    let marker = markersCache[id]
    if (!marker) {
      const coords = (feature.geometry as any).coordinates
      const element = clusterMode === ClusterMode.stacked ? createStackChart(properties, colorClusters) : createDonutChart(properties, colorClusters)
      marker = markersCache[id] = new mapboxgl.Marker({
        element: element as any,
      }).setLngLat(coords)
    }
    newMarkers[id] = marker

    if (!markersOnScreen[id]) {
      marker.addTo(map)
    }
  }
  // Remove any previously added markers that are no longer visible
  for (const id in markersOnScreen) {
    if (!newMarkers[id]) {
      markersOnScreen[id].remove()
    }
  }
  return {
    markersCache,
    markersOnScreen: newMarkers,
  }
}
