<template>
  <form class="mt-10" @submit.prevent="submitPurchaserInformation">
    <h4 class="text-subheading-1 text-blue-70">Email address</h4>
    <div class="text-body-2 mt-2">{{ props.emailAddress }}</div>
    <h4 class="text-subheading-1 mt-8 text-blue-70">Purchasing Entity</h4>
    <AccountSelector ref="accountSelector" v-model="selectedAccount" :email-address="props.emailAddress || ''"></AccountSelector>
    <WcButton
      text="Continue"
      type="submit"
      :is-disabled="accountSelector?.canSubmit === false"
      data-cy="continue-purchaser-information-button"
      class="w-full"
      button-classes="ph-no-capture" />
  </form>
</template>
<script setup lang="ts">
import { ref } from "vue"
import { useMainStore } from "@/store"
import { Account, AccountType } from "@common/models/models"
import { WcButton } from "@/components/button"
import AccountSelector from "@/modules/accounts/components/AccountSelector.vue"

const store = useMainStore()

const props = defineProps({
  emailAddress: { type: String, required: true },
})
const emit = defineEmits(["submit"])

const accountSelector = ref<InstanceType<typeof AccountSelector> | null>(null)
const individualAccount = store.accounts.filter((account) => account.type === AccountType.individual)[0]
const selectedAccount = ref<Account>(store.account || individualAccount)

const submitPurchaserInformation = async () => {
  if (accountSelector.value) {
    const newAccount = await accountSelector.value.handleSubmit()
    if (newAccount) {
      emit("submit", newAccount)
    }
  }
}
</script>
