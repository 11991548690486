<template>
  <WcMultiselect
    v-model="model"
    class="max-w-[200px]"
    :name="name"
    :options="options"
    header="Location"
    all-selected-label="Any location"
    scroll-height="400px">
  </WcMultiselect>
</template>

<script setup lang="ts">
import { defineModel } from "vue"
import type { SelectOption } from "@/components/input"
import { WcMultiselect } from "@/components/input"
import { US_STATE_NAMES_BY_ABBREVIATION } from "@common/models/asset"

// TODO: This could be a shared constant or fetched from API
const options = Object.entries(US_STATE_NAMES_BY_ABBREVIATION).map(([value, label]) => ({ label, value })) as SelectOption<string>[]

const model = defineModel<string[] | null>()
defineProps<{ name: string }>()
</script>
