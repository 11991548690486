<template>
  <AppPageSection>
    <form class="text-body-2" @submit.prevent="handleFormSubmit">
      <div>
        <h4 class="text-subheading-1">Account</h4>
        <label class="col-span-4">
          Email Address
          <div class="mt-2">{{ email }}</div>
        </label>
        <AccountSelector
          v-model="selectedAccount"
          :email-address="email || ''"
          :include-submit-button="true"
          @update:is-editing="handleUpdateIsEditingAccount">
          <template #accountTypeSelectionLabel>
            <div class="text-body-2 text-blue-70">I am registering assets as a:</div>
          </template>
        </AccountSelector>
      </div>

      <div :class="{ 'pointer-events-none opacity-30': isEditingAccount }">
        <div class="border-t border-neutral-30">
          <h4 class="text-subheading-large-bold">WEATS Registration Attestation</h4>
          <p class="text-body-2">
            In order to register an account with and one or more renewable generation assets, renewable thermal assets, and/or energy demand
            management assets (each, a “<strong>Qualifying Asset</strong>”) on the WattCarbon Energy Attribute Tracking System
            (“<strong>WEATS</strong>”) and begin generating energy attribute certificates (“<strong>EACs</strong>”) from such asset(s), please select
            the option that describes your ownership of and rights in relation to such asset(s) and agree to the terms of this attestation (this
            “<strong>Attestation</strong>”) by clicking “SUBMIT ASSET FOR REVIEW” at the bottom of this page.
          </p>
        </div>

        <div class="mt-6 border-t border-neutral-30">
          <h4 class="text-subheading-1">Registration</h4>
          <div class="text-body-2 text-blue-70">Select the option below that applies to this assignment of registration rights.</div>
          <div>
            <div v-for="(option, index) in REGISTRATION_RIGHTS" :key="option.name" class="mt-2 flex items-start">
              <input :id="`registration-option-${index}`" v-model="registrationRights" type="radio" name="registrationOption" :value="option" />
              <label :for="`registration-option-${index}`" class="text-body-2 cursor-pointer">{{ option.description }}</label>
            </div>
          </div>
        </div>

        <div class="mt-4 border-t border-neutral-30">
          <h4 class="text-subheading-1">Attestation</h4>
          <AssetRegistrationTerms class="my-4" />
          <div class="mt-4 bg-highlight-pastelazure p-4">
            <label class="col-span-8">
              Enter your full name to sign this agreement
              <input v-model="signature" type="text" placeholder="Enter your full name" />
            </label>
          </div>
        </div>
        <div class="mt-4 flex w-full justify-start border-t border-neutral-30 bg-white py-4">
          <WcButton type="submit" :is-disabled="!isDataValid" text="Submit asset for review" />
        </div>
      </div>
    </form>
  </AppPageSection>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { useRouter } from "vue-router"
import { useAssetService } from "@/services/service-container"
import { Account, AccountType } from "@common/models/models"
import { useMainStore } from "@/store"
import { AppPageSection } from "@/components/layout"
import { WcButton } from "@/components/button"
import AssetRegistrationTerms from "./AssetRegistrationTerms.vue"
import AccountSelector from "@/modules/accounts/components/AccountSelector.vue"
import { REGISTRATION_RIGHTS } from "@/modules/asset/asset.service"

const router = useRouter()
const store = useMainStore()
const assetService = useAssetService()

const email = computed(() => store.auth.user?.username)
const userIndividualAccount = store.accounts.filter((account) => account.type === AccountType.individual)[0]
const selectedAccount = ref<Account>(store.account || userIndividualAccount)
const isEditingAccount = ref(false)
const handleUpdateIsEditingAccount = (isEditing: boolean) => {
  isEditingAccount.value = isEditing
}

const registrationRights = ref()
const signature = ref("")

const isDataValid = computed(() => {
  return !!registrationRights.value && !!selectedAccount.value && !!signature.value
})

const handleFormSubmit = async () => {
  if (!isDataValid.value) return
  const attestation = {
    identifier: email.value,
    registrationRights: registrationRights.value.name,
    signature: signature.value,
  }
  await assetService.submitAssetRegistration(attestation)
  router.push({ name: "wc-register-asset-confirmation" })
}
</script>

<style scoped>
label {
  @apply block;
}

input {
  @apply mt-2 block w-full;
}

input[type="radio"] {
  @apply w-auto mr-3 mt-0 h-6 cursor-pointer;
}

select {
  @apply mt-2 block w-full cursor-pointer;
}

h4 {
  @apply mt-8 mb-3;
}

p {
  @apply my-2;
}
</style>
