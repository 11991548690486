<template>
  <Dropdown
    v-model="model"
    option-label="label"
    :options="options"
    :placeholder="insetLabel"
    :name="name"
    class="w-full"
    :class="inputClass"
    :panel-class="inputClass"
    :editable="editable"
    scroll-height="700px"
    :show-clear="!required">
    <template v-if="insetLabel" #header>
      <h4 class="text-subheading-1 mx-5 mb-1 mt-5">{{ insetLabel }}</h4>
    </template>
  </Dropdown>
</template>

<script setup lang="ts" generic="T">
import Dropdown from "primevue/dropdown"
import type { SelectOption } from "./WcDropdown"
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"

const model = defineModel<SelectOption<T> | null>()
const props = defineProps<{
  insetLabel?: string
  required?: boolean
  options: SelectOption<T>[]
  name: string
  editable?: boolean
  size?: InputSize
}>()

const inputClass = useInputClass(props)
</script>
