<template>
  <div v-if="icon === 'spinner'" class="ml-[-12px] mr-[-4px] h-[20px] w-[48px]">
    <WcLoadingSpinner :color="spinnerColor" />
  </div>
  <Icon v-else :icon="icon" aria-hidden="true" class="relative top-0.5" :aria-label="icon" />
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { Icon } from "@iconify/vue"
import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"
import type { ButtonColor, ButtonVariant } from "./WcButton"

const props = defineProps<{ color: ButtonColor; icon: string; variant: ButtonVariant }>()

const spinnerColor = computed(() => {
  if (["outlined", "text"].includes(props.variant) && ["primary", "secondary"].includes(props.color)) {
    return "neutral"
  }
  return "light"
})
</script>
