import BaseFetcher from "@/services/base-fetcher"
import { getEnvironment } from "@/environment"
import { useMainStore } from "@/store"

const { API_BASE_URL } = getEnvironment()

export default class ApiFetcher extends BaseFetcher {
  key: string

  constructor() {
    // the exact URL is determined by where we're running (e.g. localhost ->
    // localhost, beta -> beta-api, etc.) but is fixed for a given invocation
    super(API_BASE_URL)

    this.key = ""
  }

  getDomain = () => {
    return this.domain
  }
  getAuthorization = () => {
    const token = useMainStore().auth.token
    return { Authorization: `Bearer ${token}` }
  }
  getHeaders = () => {
    const token = useMainStore().auth.token
    if (token) {
      return { headers: { Authorization: `Bearer ${token}` } }
    }
    return {}
  }

  getError(error: any): string {
    if (error?.response?.status == 401) {
      const store = useMainStore()
      store.auth.isAuthenticated = false
    }

    return super.getError(error)
  }
}
