<template>
  <AppPage>
    <AppPageHeader :backlink-route="{ name: 'wc-accounts-manage' }" backlink-text="Back to Account"> Account Details </AppPageHeader>
    <AppPageContent>
      <AppPageSection v-if="account">
        <div class="ph-no-capture flex flex-col gap-2.5">
          <div class="text-subheading-1">Name</div>
          <div v-if="!isEditingName" class="text-body-2 relative">{{ account.name }}</div>
          <div v-else>
            <input
              v-model="account.name"
              type="text"
              maxlength="1000"
              class="w-1/4 rounded-md border-sagetone py-2 pl-5 pr-[3.75rem]"
              @click="captureNameEditInputEvent" />
          </div>
          <button v-if="!isEditingName" class="text-body-2 ph-no-capture mb-12 w-fit text-blue-70 underline" @click="editName()">Edit</button>
          <WcButton v-else class="mb-12 w-fit" text="Update Name" @click="updateAccountName" />
        </div>

        <div class="mb-12 flex flex-col gap-2.5">
          <span class="text-subheading-1">Contact Info</span>
          <div class="text-body-2 ph-no-capture flex flex-col gap-1">
            <div v-if="isEditingContactInfo">
              <form @submit.prevent="updateContactInfo">
                <TextInput v-model="account.contact_info.email" class="mb-4 w-1/3" label="Email" @click="captureEmailInputEvent" />
                <TextInput
                  v-model="account.contact_info.street_1"
                  class="mb-4 w-1/3"
                  label="Street Address"
                  required
                  @click="captureStreetAddressInput" />
                <TextInput v-model="account.contact_info.street_2" class="mb-4 w-1/3" label="Address Line 2" @click="captureAddressLineTwoInput" />
                <TextInput v-model="account.contact_info.city" class="mb-4 w-1/3" label="City" required @click="captureCityInput" />
                <TextInput
                  v-model="account.contact_info.state_province"
                  class="mb-4 w-1/3"
                  label="State/Province"
                  required
                  @click="captureStateProvinceInput" />
                <TextInput
                  v-model="account.contact_info.postal_code"
                  class="mb-4 w-1/3"
                  label="Postal Code"
                  required
                  @click="capturePostalCodeInput" />
                <TextInput v-model="account.contact_info.country" class="mb-4 w-1/3" label="Country" @click="captureCountryInput" />
                <WcButton class="mb-12 w-fit" text="Update Contact Info" type="submit" />
              </form>
            </div>
            <div v-else>
              <!-- Using city since it can't be undefined on the backend -->
              <div v-if="!!account.contact_info.city" class="flex flex-col">
                <span>{{ account?.contact_info?.street_1 }}</span>
                <span>{{ account?.contact_info?.street_2 }}</span>
                <span
                  >{{ account?.contact_info?.city }}, {{ account?.contact_info?.state_province }} {{ account?.contact_info?.postal_code }}
                  {{ account?.contact_info?.country }}</span
                >
              </div>
              <span v-else class="text-body-2 mb-12">{{ "No address provided" }}</span>
            </div>
          </div>
          <button v-if="!isEditingContactInfo" class="text-body-2 ph-no-capture w-fit text-blue-70 underline" @click="editContactInfo">Edit</button>
        </div>
        <div class="flex flex-col gap-2.5">
          <span class="text-subheading-1">Account ID</span>
          <span class="text-body-2 mb-12">{{ account.id }}</span>
        </div>
      </AppPageSection>
      <AppPageSection>
        <AppPageSectionHeader>API Key</AppPageSectionHeader>
        <div class="border-wc-black mb-4 mt-7 w-full border"></div>
        <div class="ph-no-capture flex justify-between">
          <span v-if="!isShowingAPIKey" class="font-mono">{{ "**********************" }}</span>
          <span v-else class="text-body-3">{{ account.api_key }}</span>
          <WcButton v-if="!isShowingAPIKey" button-classes="ph-no-capture" variant="secondary" text="Show Key" @click="toggleAPIKey" />
          <WcButton v-else text="Hide Key" variant="secondary" @click="toggleAPIKey" />
        </div>
      </AppPageSection>
      <AppPageSection class="text-body-2">
        <span>For support with your account, contact <a class="" href="mailto:support@wattcarbon.com">support@wattcarbon.com</a></span>
      </AppPageSection>
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import posthog from "posthog-js"
import { TYPE, useToast } from "vue-toastification"
import { WcButton } from "@/components/button"
import { AppPage, AppPageContent, AppPageHeader, AppPageSection, AppPageSectionHeader } from "@/components/layout"
import TextInput from "@/components/ui/TextInput.vue"
import { Account, AccountContactInfo } from "@common/models/models"
import { useAccountsService } from "@/services/service-container"

const accountsService = useAccountsService()
const route = useRoute()
const router = useRouter()

const toast = useToast()

const accountId = parseInt(route.params.id as string)

const isEditingName = ref<boolean>(false)
const isEditingContactInfo = ref<boolean>(false)

// PostHog Events
const captureEditNameEvent = () => posthog.capture("Clicked on the 'Name' edit link - Account Details View")
const captureEditContactInfoEvent = () => posthog.capture("Clicked on the 'Contact Info' edit link - Account Details View")
const captureShowKeyEvent = () => posthog.capture("Clicked on the 'Show Key' button - Account Details View")
const captureHideKeyEvent = () => posthog.capture("Clicked on the 'Hide Key' button - Account Details View")
const captureNameEditInputEvent = () => posthog.capture("Clicked on the 'Name' input - Account Details View")
const captureUpdateNameEvent = () => posthog.capture("Submitted the 'Update Name' form - Account Details View")
const captureUpdateNameEventError = () => posthog.capture("Error when submitting the 'Update Name' form - Account Details View")
const captureEmailInputEvent = () => posthog.capture("Clicked on the 'Email' input - Account Details View")
const captureStreetAddressInput = () => posthog.capture("Clicked on the 'Street Address' input - Account Details View")
const captureAddressLineTwoInput = () => posthog.capture("Clicked on the 'Address Line 2' input - Account Details View")
const captureCityInput = () => posthog.capture("Clicked on the 'City' input - Account Details View")
const captureStateProvinceInput = () => posthog.capture("Clicked on the 'State/Province' input - Account Details View")
const capturePostalCodeInput = () => posthog.capture("Clicked on the 'Postal Code' input - Account Details View")
const captureCountryInput = () => posthog.capture("Clicked on the 'Country' input - Account Details View")
const captureUpdateContactInfoEvent = () => posthog.capture("Submitted the 'Update Contact Info' form - Account Details View")
const captureUpdateContactInfoEventError = () => posthog.capture("Error when submitting the 'Update Contact Info' form - Account Details View")

const editName = () => {
  isEditingName.value = true
  captureEditNameEvent()
}
const editContactInfo = () => {
  isEditingContactInfo.value = true
  captureEditContactInfoEvent()
}

const isShowingAPIKey = ref<boolean>(false)

const toggleAPIKey = () => {
  if (isShowingAPIKey.value) {
    isShowingAPIKey.value = false
    captureHideKeyEvent()
  } else {
    isShowingAPIKey.value = true
    captureShowKeyEvent()
  }
}

const updateAccountName = async () => {
  const toastId = toast("Updating…", { id: "WcContactInfoUpdate", type: TYPE.DEFAULT, timeout: false })

  try {
    await accountsService.updateAccountName(account.value, account.value.name)
    isEditingName.value = false
    toast.update(toastId, { content: "Account name updated!", options: { type: TYPE.SUCCESS, timeout: 3000 } })
    captureUpdateNameEvent()
  } catch (error) {
    console.error(error)
    toast.update(toastId, { content: "Unable to update account name.", options: { type: TYPE.ERROR, timeout: 3000 } })
    captureUpdateNameEventError()
  }
}

const updateContactInfo = async () => {
  const updateContact = Object.assign(new Account(), account.value)
  const toastId = toast("Updating…", { id: "WcContactInfoUpdate", type: TYPE.DEFAULT, timeout: false })

  try {
    updateContact.contact_info = { ...account.value, ...account.value.contact_info }
    await accountsService.updateAccountContact(updateContact)
    isEditingContactInfo.value = false
    toast.update(toastId, { content: "Contact info updated!", options: { type: TYPE.SUCCESS, timeout: 3000 } })
    captureUpdateContactInfoEvent()
  } catch (error) {
    console.error(error)
    toast.update(toastId, { content: "Unable to update contact info.", options: { type: TYPE.ERROR, timeout: 3000 } })
    captureUpdateContactInfoEventError()
  }
}

const account = ref<Account>(new Account())

try {
  account.value = await accountsService.getAccount(accountId)
  if (account.value.contact_info === null) {
    account.value.contact_info = new AccountContactInfo()
  }
} catch (error: any) {
  if (error?.message === "Account not found") {
    router.push({ name: "wc-404" })
  }
}
</script>
