<template>
  <Dialog ref="dialog" class="p-dialog-maximized manage-asset-group-assets-modal" modal :visible="isOpen" :closable="false" :draggable="false">
    <template #header>
      <div class="flex w-full flex-wrap items-center justify-between gap-3 text-white">
        <div class="flex items-center gap-6">
          <WcButtonIcon class="!top-0 size-7 cursor-pointer" icon="wc-carbon:close" color="green" variant="tertiary" @click="closeModal" />
          <div class="flex items-center gap-2.5">
            <span class="text-body-1">Edit Asset Group</span>
            <Icon class="!top-0 size-5" icon="wc-carbon:chevron-right" />
            <span class="text-subheading-1">Add Assets</span>
          </div>
        </div>
        <div class="flex gap-3">
          <WcButton text="Exit" size="medium" variant="secondary" dark-mode @click="closeModal" />
        </div>
      </div>
    </template>
    <div v-if="assetGroup">
      <div class="mb-8 flex items-baseline justify-between gap-4 border-b border-divider-extralight pb-3">
        <div class="flex items-baseline gap-2">
          <h2 class="text-subheading-large-bold">{{ assetGroup.name }}</h2>
          <span class="text-body-2 inline">({{ assetGroup.deviceIds.length }})</span>
        </div>
        <WcCTA
          class="shrink-0"
          icon-position="right"
          :icon="showAddedAssets ? 'wc-carbon:chevron-up' : 'wc-carbon:chevron-down'"
          text="Show added assets"
          size="medium"
          weight="heavy"
          @click="showAddedAssets = !showAddedAssets" />
      </div>
      <div v-if="showAddedAssets" class="mb-9 mt-4 border-b-[3px] border-divider-dark pb-9">
        <h3 class="text-subheading-1 mb-6">Added Assets</h3>
        <div class="max-h-[500px] overflow-x-scroll">
          <AssetsTable
            :assets="addedAssets"
            allow-multiple-selection
            :selected-row-ids="selectedAddedAssetIds"
            show-kind
            table-class="w-full"
            @rows-selected="handleAddedRowsSelected" />
        </div>
        <PaginationButtons class="mt-3" :page-info="addedAssetsPageInfo" :is-disabled="isLoading" @load-items="loadAddedAssets" />
        <div class="mt-8 flex h-16 w-full flex-wrap items-center justify-between gap-2 bg-darktone-lightbackground px-5 py-3">
          <div class="text-body-2 shrink-0">
            <div class="flex items-baseline gap-2">
              <span class="text-subheading-large-bold">{{ selectedAddedAssetIds.length }}</span>
              <span class="text-subheading-1">assets selected</span>
            </div>
          </div>
          <div class="flex items-center gap-2">
            <WcButton
              text="Cancel"
              size="medium"
              variant="secondary"
              :is-disabled="selectedAddedAssetIds.length === 0"
              @click="handleAddedClearSelection" />
            <WcButton text="Remove Assets" size="medium" :is-disabled="selectedAddedAssetIds.length === 0" @click="removeSelectedAssets" />
          </div>
        </div>
      </div>
      <h3 v-if="showAddedAssets" class="text-subheading-1 mb-6 block">Unassigned Assets</h3>
      <div class="w-full">
        <section class="flex flex-wrap gap-2 py-2">
          <WcDropdown
            v-model="filterAssetStatus"
            class="basis-40"
            name="filterAssetStatus"
            :options="statusOptions"
            inset-label="Status"
            size="small" />
          <WcCalendarRange
            v-model="filterAssetCreatedDateRange"
            class="shrink-0 basis-40"
            :class="{ 'basis-56': (filterAssetCreatedDateRange || [])[1] != null }"
            name="filterAssetCreatedDateRange"
            inset-label="Date created"
            size="small" />
          <WcInputText
            v-model="filterAssetLocationKeyword"
            icon="wc-carbon:search"
            class="shrink-0 basis-48"
            name="filterAssetLocationKeyword"
            inset-label="Filter by location"
            size="small" />
          <!-- TODO: Show these filters when supported (or remove if we decide not to use them)-->
          <WcCalendarRange
            v-if="false"
            v-model="filterAssetLastUpdateDateRange"
            class="shrink-0 basis-40"
            :class="{ 'basis-56': (filterAssetLastUpdateDateRange || [])[1] != null }"
            name="filterAssetLastUpdateDateRange"
            inset-label="Last update"
            size="small" />
          <WcCalendarRange
            v-if="false"
            v-model="filterAssetEacProductionDateRange"
            class="shrink-0 basis-40"
            :class="{ 'basis-56': (filterAssetEacProductionDateRange || [])[1] != null }"
            name="filterAssetEacProductionDateRange"
            inset-label="EAC production"
            size="small" />
          <WcCTA
            class="shrink-0 basis-52 pl-1"
            icon-position="right"
            icon="wc-carbon:close-outline"
            text="Clear Filters"
            size="small"
            @click="handleClearFilters" />
        </section>

        <div class="mt-4 overflow-x-scroll pb-3" :class="{ 'max-h-[calc(100vh-28rem)] ': !showAddedAssets, 'max-h-[500px]': showAddedAssets }">
          <AssetsTable
            :assets="availableAssets"
            allow-multiple-selection
            :selected-row-ids="selectedAvailableAssetIds"
            show-group
            show-kind
            table-class="w-full"
            @rows-selected="handleAvailableRowsSelected" />
        </div>
        <PaginationButtons class="mt-3" :page-info="availableAssetsPageInfo" :is-disabled="isLoading" @load-items="loadAvailableAssets" />
        <EmptyState v-if="!isLoading && !hasError && availableAssets.length === 0" title="No assets available to add">
          <p v-if="assetGroup.deviceIds.length === 0" class="text-body-2 mt-2">Upload one or more assets to add them to a group.</p>
          <p v-if="assetGroup.deviceIds.length > 0" class="text-body-2 mt-2">All assets matching your filters have been added to this group</p>
        </EmptyState>
        <InlineLoading v-if="isLoading && availableAssets.length === 0" />
        <InlineError v-if="hasError" error-message="There was a problem loading assets. Please try again." />
        <div
          v-if="availableAssets.length > 0"
          class="mt-8 flex h-16 w-full flex-wrap items-center justify-between gap-2 bg-darktone-lightbackground px-5 py-3">
          <div class="text-body-2 shrink-0">
            <div class="flex items-baseline gap-2">
              <span class="text-subheading-large-bold">{{ selectedAvailableAssetIds.length }}</span>
              <span class="text-subheading-1">assets selected</span>
            </div>
          </div>
          <div class="flex items-center gap-2">
            <WcButton
              text="Cancel"
              size="medium"
              variant="secondary"
              :is-disabled="selectedAvailableAssetIds.length === 0"
              @click="handleAvailableClearSelection" />
            <WcButton text="Add Assets" size="medium" :is-disabled="selectedAvailableAssetIds.length === 0" @click="addSelectedAssets" />
          </div>
        </div>
      </div>
    </div>
    <InlineLoading v-if="assetGroup == null && isLoading" />
    <InlineError v-if="assetGroup == null && hasError" error-message="There was a problem loading the asset group. Please try again." />
  </Dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import { useToast } from "vue-toastification"
import { Icon } from "@iconify/vue"
import { format } from "date-fns"
import Dialog from "primevue/dialog"
import type { Asset, AssetGroup, AssetStatus } from "@common/models/asset"
import { ASSET_STATUS } from "@common/models/asset"
import { WcButton, WcCTA } from "@/components/button"
import WcButtonIcon from "@/components/button/WcButtonIcon.vue"
import type { SelectOption } from "@/components/input"
import { WcCalendarRange, WcDropdown, WcInputText } from "@/components/input"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService } from "@/services/service-container"
import { debounce } from "@/utils/debounce"
import AssetsTable from "./AssetsTable.vue"

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))

const assetService = useAssetService()
const toast = useToast()

const props = defineProps<{ assetGroupId: number }>()

const emit = defineEmits(["asset-group-assets-updated"])

const isOpen = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const hasError = ref<boolean>(false)
const showAddedAssets = ref<boolean>(false)
const dialog = ref()
const assetGroup = ref<AssetGroup | null>(null)
const availableAssets = ref<Asset[]>([])
const availableAssetsPageInfo = ref<PageInfo>()
const addedAssets = ref<Asset[]>([])
const addedAssetsPageInfo = ref<PageInfo>()

const filterAssetCreatedDateRange = ref<Date[] | null | undefined>(null)
const filterAssetLastUpdateDateRange = ref<Date[] | null | undefined>(null)
const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetEacProductionDateRange = ref<Date[] | null | undefined>(null)
const filterAssetLocationKeyword = ref<string | null | undefined>(null)

const selectedAvailableAssetIds = ref<number[]>([])
const selectedAddedAssetIds = ref<number[]>([])

const handleUpdateIsOpen = (newValue: boolean) => {
  isOpen.value = newValue
  if (!newValue) {
    resetModal()
  }
}

const resetModal = () => {
  assetGroup.value = null
  addedAssets.value = []
  availableAssets.value = []
  selectedAddedAssetIds.value = []
  selectedAvailableAssetIds.value = []
  showAddedAssets.value = false
}

const loadAssetGroup = async () => {
  try {
    assetGroup.value = await assetService.getAssetGroup(props.assetGroupId)
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading this asset group", error)
  }
  isLoading.value = false
}

const loadAvailableAssets = async (url?: string) => {
  isLoading.value = true
  try {
    const createdDateStart = (filterAssetCreatedDateRange.value ?? [])[0]
    const createdDateEnd = (filterAssetCreatedDateRange.value ?? [])[1]

    const lastUpdateStart = (filterAssetLastUpdateDateRange.value ?? [])[0]
    const lastUpdateEnd = (filterAssetLastUpdateDateRange.value ?? [])[1]

    const eacProductionStart = (filterAssetEacProductionDateRange.value ?? [])[0]
    const eacProductionEnd = (filterAssetEacProductionDateRange.value ?? [])[1]

    const filters = {
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetLocationKeyword.value && { location: filterAssetLocationKeyword.value }),
      ...(createdDateStart && { createdDateStart: format(createdDateStart, "yyyy-MM-dd") }),
      ...(createdDateEnd && { createdDateEnd: format(createdDateEnd, "yyyy-MM-dd") }),
      ...(lastUpdateStart && { lastUpdateStart: format(lastUpdateStart, "yyyy-MM-dd") }),
      ...(lastUpdateEnd && { lastUpdateEnd: format(lastUpdateEnd, "yyyy-MM-dd") }),
      ...(eacProductionStart && { eacProductionStart: format(eacProductionStart, "yyyy-MM-dd") }),
      ...(eacProductionEnd && { eacProductionEnd: format(eacProductionEnd, "yyyy-MM-dd") }),
    }
    const result = await assetService.listAssets({ url, excludedGroupId: props.assetGroupId, ...filters })
    availableAssets.value = result.data
    availableAssetsPageInfo.value = result.pageInfo
    selectedAvailableAssetIds.value = []
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading available assets", error)
  }
  isLoading.value = false
}

const loadAddedAssets = async (url?: string) => {
  isLoading.value = true
  try {
    const result = await assetService.listAssets({ url, groupId: props.assetGroupId })
    addedAssets.value = result.data
    addedAssetsPageInfo.value = result.pageInfo
    selectedAddedAssetIds.value = []
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading added assets", error)
  }
  isLoading.value = false
}

const loadData = () => {
  loadAssetGroup()
  loadAvailableAssets()
  loadAddedAssets()
}

watch(
  [props.assetGroupId, isOpen],
  () => {
    if (props.assetGroupId && isOpen.value) {
      loadData()
    }
  },
  { immediate: true }
)

watch(
  [filterAssetCreatedDateRange, filterAssetLastUpdateDateRange, filterAssetStatus, filterAssetEacProductionDateRange, filterAssetLocationKeyword],
  debounce(() => {
    loadAvailableAssets()
  })
)

watch([showAddedAssets], () => {
  if (showAddedAssets.value) {
    loadAddedAssets()
  }
})

const addSelectedAssets = async () => {
  if (!props.assetGroupId || selectedAvailableAssetIds.value.length === 0) {
    return
  }
  try {
    await assetService.addAssetsToAssetGroup(props.assetGroupId, selectedAvailableAssetIds.value)
    emit("asset-group-assets-updated")
    selectedAvailableAssetIds.value = []
    loadData()
  } catch (error) {
    console.error(error)
    toast.error("There was a problem adding assets to the group. Please try again.")
  }
}
const removeSelectedAssets = async () => {
  if (!props.assetGroupId || selectedAddedAssetIds.value.length === 0) {
    return
  }
  try {
    await assetService.removeAssetsFromAssetGroup(props.assetGroupId, selectedAddedAssetIds.value)
    selectedAddedAssetIds.value = []
    isLoading.value = true
    loadData()
  } catch (error) {
    console.error(error)
    toast.error("There was a problem removing assets from the group. Please try again.")
  }
}

const handleClearFilters = () => {
  filterAssetCreatedDateRange.value = null
  filterAssetLastUpdateDateRange.value = null
  filterAssetStatus.value = null
  filterAssetEacProductionDateRange.value = null
  filterAssetLocationKeyword.value = null
}

const handleAvailableRowsSelected = (selectedRowIDs: number[]) => {
  selectedAvailableAssetIds.value = selectedRowIDs
}

const handleAvailableClearSelection = () => {
  selectedAvailableAssetIds.value = []
}

const handleAddedRowsSelected = (selectedRowIDs: number[]) => {
  selectedAddedAssetIds.value = selectedRowIDs
}

const handleAddedClearSelection = () => {
  selectedAddedAssetIds.value = []
}

const openModal = () => {
  handleUpdateIsOpen(true)
}
const closeModal = () => handleUpdateIsOpen(false)
defineExpose({ openModal, closeModal })
</script>

<style lang="scss">
.manage-asset-group-assets-modal {
  .p-dialog-header {
    padding: 12px 60px;
    background-color: #000000;
  }
  .p-dialog-content {
    padding: 48px 60px;
  }
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    border-radius: 0;
  }
}
</style>
