<template>
  <section class="page disable-select">
    <h2 v-if="!error">Connecting...</h2>
  </section>
</template>

<script lang="ts" setup>
import { onActivated, onMounted, ref } from "vue"
import { useMainStore } from "@/store"
import { useAuthStore } from "@/modules/auth/auth.state"
import { useAuthService } from "@/services/service-container"

import { push, getQueryParam } from "./auth.utils"

const error = ref("")
const service = useAuthService()
const store = useMainStore()
const authStore = useAuthStore()
const redirectToLogin = async (token: string) => {
  authStore.resetErrors()
  try {
    await service.login(token)
    if (getQueryParam("l")) {
      const path = decodeURIComponent(getQueryParam("l") as string)
      await push(path, path)
      return
    }
    await push({ name: "wc-landing" }, "/")
  } catch (e) {
    console.log(e)
    authStore.addError("Your link has expired.")
    authStore.addError("Please get a new password link.")
    await push({ name: "wc-signin" }, "/signin")
  }
}

const redirect = () => {
  if (store.auth.isAuthenticated) {
    if (getQueryParam("l")) {
      const path = getQueryParam("l") as string
      push({ path }, path)
      return
    }
    push({ name: "wc-landing" }, "/")
    return
  }

  if (getQueryParam("t")) {
    redirectToLogin(getQueryParam("t") as string)
    return
  }
  push("/", "/")
}

onMounted(() => {
  redirect()
})

onActivated(() => {
  redirect()
})
</script>

<style scoped lang="scss">
.login-card {
  width: 400px;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
