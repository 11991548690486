<template>
  <AppPageSection>
    <AppPageSectionHeader class="pb-9"> EAC Inventory </AppPageSectionHeader>
    <div v-if="scopes && scopes[1] && scopes[2] && scopes[3]">
      <div class="text-subheading-3 inventory-header-row grid grid-cols-8">
        <EacInventoryHeaderCell />
        <EacInventoryHeaderCell>Consumed EACs</EacInventoryHeaderCell>
        <!-- TODO: Replace these placeholders with the actual tooltip copy -->
        <EacInventoryHeaderCell>Avoided Emissions</EacInventoryHeaderCell>
        <EacInventoryHeaderCell>Purchased</EacInventoryHeaderCell>
        <EacInventoryHeaderCell>Sold</EacInventoryHeaderCell>
        <EacInventoryHeaderCell
          description="Net balance is measured in gCO2e for Scope 1 and Scope 3 and in MWh for Scope 2. This represents the balance of your emissions: incurred emissions - avoided emissions. A positive value means on net you emitted more carbon than you avoided."
          >Net Balance</EacInventoryHeaderCell
        >
        <EacInventoryHeaderCell>Retired</EacInventoryHeaderCell>
        <EacInventoryHeaderCell>Available To Sell</EacInventoryHeaderCell>
      </div>
      <div class="text-body-3 inventory-row grid grid-cols-8">
        <EacInventoryCell class="text-subheading-3">
          Scope 1
          <img width="20" height="30" class="-mb-9 pt-1.5" src="@/static/img/icons/mini/scope1.svg" />
        </EacInventoryCell>
        <EacInventoryCell
          :value="scopes[1].consumption"
          :unit="GHG_EMISSIONS"
          description="These are locational Scope 1 emissions, e.g. burned fossil fuels." />
        <EacInventoryCell
          :value="scopes[1].generation"
          :unit="GHG_EMISSIONS"
          description="Locational Scope 1 avoided emissions are generated from interventions on your property, eg. heat pumps, electrification, energy efficiency, etc." />
        <EacInventoryCell description="Market-based Scope 1 EACs may not be purchased." />
        <EacInventoryCell description="Scope 1 EACs cannot be sold as Scope 1. They can only be sold as Scope 3, after they are retired." />
        <EacInventoryCell
          class="text-subheading-3"
          :value="netEmissionsForScope(1)"
          :unit="GHG_EMISSIONS"
          description="This is the sum total of Scope 1 emissions and avoided emissions, both locational and market-based." />
        <EacInventoryCell
          :value="scopes[1].retired"
          :unit="GHG_EMISSIONS"
          description="Retired Scope 1 EACs may be counted towards sustainability goals. Upon retirement, separate Scope 3 EACs are automatically created" />
        <EacInventoryCell
          description="Scope 1 EACs may not be sold as Scope 1. However, they can be sold as Scope 3 after the Scope 1 EACs are retired." />
      </div>
      <div class="text-body-3 inventory-row grid grid-cols-8">
        <EacInventoryCell class="text-subheading-3">
          Scope 2
          <img width="37" height="22" class="-mb-9 pt-3.5" src="@/static/img/icons/mini/scope2.svg" />
        </EacInventoryCell>
        <EacInventoryCell
          :value="scopes[2].consumption"
          :unit="ELECTRICITY"
          description='These are locational emissions from using the grid&apos;s "dirty" electricity.' />
        <EacInventoryCell
          :value="scopes[2].generation"
          :unit="ELECTRICITY"
          description="E.g. solar and storage installs on property under control or operation. Scope 2 locational emissions reductions have to stay with the original owner, but can be retired to generate Scope 3 credits." />
        <EacInventoryCell
          :value="scopes[2].purchased"
          :unit="ELECTRICITY"
          description="Scope 2 EACs from solar, wind, or batteries may be purchased on the OpenEAC Exchange or imported as RECs from other marketplaces." />
        <EacInventoryCell
          :value="scopes[2].sold"
          :unit="ELECTRICITY"
          description="Scope 2 solar-derived EACs may be sold as Scope 2 market EACs, but then cannot be retired by the seller." />
        <EacInventoryCell
          class="text-subheading-3"
          :value="netEmissionsForScope(2)"
          :unit="ELECTRICITY"
          description="This is the sum total of Scope 2 incurred emissions and avoided emissions, both locational and market- based." />
        <EacInventoryCell
          :value="scopes[2].retired"
          :unit="ELECTRICITY"
          description="Eg. Retired RECs or Scope 2 Market EACs may be retired towards sustainability goals." />
        <EacInventoryCell
          :value="scopes[2].available"
          :unit="ELECTRICITY"
          description="Scope 2 solar-derived EACs may be sold as Scope 2 market EACs, but then cannot be retired by the seller or count them as avoided emissions in seller's carbon accounting." />
      </div>
      <div class="text-body-3 inventory-row grid grid-cols-8">
        <EacInventoryCell class="text-subheading-3">
          Scope 3
          <img width="40" height="24" class="-mb-9 pt-3" src="@/static/img/icons/mini/scope3.svg" />
        </EacInventoryCell>
        <EacInventoryCell
          :value="scopes[3].consumption"
          :unit="GHG_EMISSIONS"
          description="Total Scope 1 and Scope 2 emissions of your supply chain." />
        <EacInventoryCell
          :value="scopes[3].generation"
          :unit="GHG_EMISSIONS"
          description="Total Scope 1 and Scope
2 locational avoided emissions of the supply chain. This number is the total retired Scope 1 and 
2 locational emissions." />
        <EacInventoryCell
          :value="scopes[3].purchased"
          :unit="GHG_EMISSIONS"
          description="Scope 3 EACs may be purchased from other entities on the OpenEAC Exchange or on other marketplaces and imported." />
        <EacInventoryCell
          :value="scopes[3].sold"
          :unit="GHG_EMISSIONS"
          description="Both Scope 1 locational avoided and Scope 2 market EACs, once retired, automatically generate Scope 3 EACs that may be sold separately." />
        <EacInventoryCell
          class="text-subheading-3"
          :value="netEmissionsForScope(3)"
          :unit="GHG_EMISSIONS"
          description="This is the sum total of Scope 3 incurred emissions and avoided emissions, both locational and market-based." />
        <EacInventoryCell
          :value="scopes[3].retired"
          :unit="GHG_EMISSIONS"
          description="Scope 3 EACs can only be purchased/transferred and retired by another entity." />
        <EacInventoryCell
          :value="scopes[3].available"
          :unit="GHG_EMISSIONS"
          description="Both Scope 1 locational avoided and Scope 2 market EACs, once retired, automatically generate Scope 3 EACs that may be sold separately." />
      </div>
    </div>
  </AppPageSection>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import { EacMeasurementParameter } from "@common/models/order"
import { AppPageSection, AppPageSectionHeader } from "@/components/layout"
import { useRegistryService } from "@/services/service-container"
import type { ScopeSummary } from "@/services/api/registry.service"
import EacInventoryHeaderCell from "./components/EacInventoryHeaderCell.vue"
import EacInventoryCell from "./components/EacInventoryCell.vue"

const ELECTRICITY = EacMeasurementParameter.Electricity
const GHG_EMISSIONS = EacMeasurementParameter.GhgEmissions

const registryService = useRegistryService()

const scopes = ref<Record<number, ScopeSummary>>({})

const loadScopesInventory = async () => {
  try {
    const result = await registryService.getAllTimeBalanceScoped()
    scopes.value = result.scopes
  } catch (error) {
    console.error("There was an error loading inventory data", error)
  }
}

onMounted(async () => {
  await loadScopesInventory()
})

const netEmissionsForScope = (scope: number) => {
  const scopeData = scopes.value[scope]
  if (!scopeData) return 0
  return scopeData.consumption - scopeData.generation + scopeData.purchased - scopeData.sold
}
</script>

<style lang="scss">
// Round the corners on the top-left, top-right, bottom-left, and bottom-right cells
.inventory-header-row > div:first-child {
  @apply rounded-tl-xl;
}
.inventory-header-row > div:last-child {
  @apply rounded-tr-xl;
}
.inventory-row:last-of-type > div:first-child {
  @apply rounded-bl-xl;
}
.inventory-row:last-of-type > div:last-child {
  @apply rounded-br-xl;
}

// Add dashed borders between cells in the table
.inventory-row:not(:last-of-type) > div {
  @apply border-b border-neutral-30 border-dashed;
}
.inventory-row,
.inventory-header-row {
  > div:not(:first-of-type) {
    @apply border-l border-neutral-30 border-dashed;
  }
}

// Separate the Retired and Available to Sell columns from the rest of the table with whitespace
.inventory-row,
.inventory-header-row {
  > div:nth-child(6) {
    @apply border-l-highlight-yellow border-l-2 mr-1;
    border-left-style: solid;
  }
  > div:nth-child(7) {
    @apply border-l-0;
    @apply ml-1;
  }
}
</style>
