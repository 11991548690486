export const mapSiteAsset = (a: SiteAsset) => {
  if (!a.modelSummary?.totals) {
    a.modelSummary = {
      totals: {
        carbonFreePercent: "0",
        carbonAvgTonnesCo2e: "0",
        carbonSumTonnesCo2e: "0",
        energyAvgMwh: "0",
        energySumMwh: "0",
        nValuesAggregated: "1",
        fuelMix: {},
      },
    }
  } else {
    a.modelSummary.totals = {
      carbonFreePercent: a.modelSummary.totals["carbon_free_percent"] ?? "0",
      carbonAvgTonnesCo2e: a.modelSummary.totals["carbon_avg_tonnes_co2e"] ?? "0",
      carbonSumTonnesCo2e: a.modelSummary.totals["carbon_sum_tonnes_co2e"] ?? "0",
      energyAvgMwh: a.modelSummary.totals["energy_avg_mwh"] ?? "0",
      energySumMwh: a.modelSummary.totals["energy_sum_mwh"] ?? "0",
      nValuesAggregated: a.modelSummary.totals["n_values_aggregated"] ?? "1",
      fuelMix: a.modelSummary.totals["fuel_mix"] ?? {},
    }
  }
  return a
}

export enum GridPurpose {
  MarketBasedProduction = "market_based_production",
  LocationalProduction = "locational_production",
  Consumption = "consumption",
}

export class SiteAssetBase {
  [key: string]: any
  id = 0
  siteId = 0
  name = ""
  // text = ""
  type = ""
  gridPurpose = GridPurpose.Consumption
  providerId = 0
  provider = ""
  customId = ""
  custom = ""
  modelParameters = {} as { [key: string]: string }
  fuelSource = "electricity"
  dataSource = "cached"
}

export class SiteAssetRequest extends SiteAssetBase {}

export class SiteAssetResponse extends SiteAssetBase {
  createdTime = new Date().toISOString()
  updatedTime = new Date().toISOString()
}
export class SiteAsset extends SiteAssetBase {
  public static GetEmpty(siteId: number): SiteAsset {
    const siteAsset = new SiteAsset()
    siteAsset.siteId = siteId
    siteAsset.type = ""
    siteAsset.modelParameters = {}
    return siteAsset
  }
}
