<template>
  <main class="bg-image flex h-full flex-col justify-center bg-white">
    <section class="flex w-full items-center justify-center">
      <slot />
    </section>
  </main>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.bg-image {
  background-image: url("../../static/img/wattcarbon-logo_grey-128.svg");
  background-position: right bottom; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
}
</style>
