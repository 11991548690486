<template>
  <InlineLoading v-if="isLoading" />
  <InlineError v-else-if="hasError" error-message="There was a problem loading this page. Please try again." />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"

import { loadStory } from "./story-loader"

import type { LoadableStory, StoryMetaData } from "./story.utils"

const props = defineProps<{ storyId: number }>()

const emit = defineEmits<{
  (e: "load", story: LoadableStory, metaData: StoryMetaData): void
  (e: "error", err: any): void
  (e: "not-found"): void
}>()

const isLoading = ref<boolean>(true)
const hasError = ref<boolean>(false)

onMounted(async () => {
  try {
    const loadedData = await loadStory(props.storyId)

    if (loadedData?.loadedStory) {
      emit("load", loadedData.loadedStory, loadedData.metaData)
    }
  } catch (error: any) {
    if (error?.message === "Story not found") {
      emit("not-found")
    } else {
      hasError.value = true
      console.error("There was an error loading the story", error)
      emit("error", error)
    }
  }

  isLoading.value = false
})
</script>
