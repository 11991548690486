<template>
  <div>
    <h4 class="text-subheading-1 my-4">Documents</h4>
    <div v-if="documents.length > 0">
      <div v-for="document in documents" :key="document.id" class="my-2">
        <a class="text-body-1-link inline max-w-48 cursor-pointer" @click="() => handleRowClicked(document)">{{ document.name }}</a>
        ({{ ASSET_DOCUMENT_TYPE[document.type] ?? document.type }})
      </div>
    </div>
    <div v-else>None</div>
  </div>
</template>

<script setup lang="ts">
import { ASSET_DOCUMENT_TYPE, AssetDocument } from "@common/models/asset"

const { documents } = defineProps<{ documents: AssetDocument[] }>()

const handleRowClicked = (document: AssetDocument) => {
  window.open(document.downloadUrl, "_blank")
}
</script>
