<template>
  <AppPage>
    <AppPageHeader :backlink-route="{ name: 'wc-assets' }" backlink-text="Back to Assets">Submit assets for review</AppPageHeader>
    <AppPageContent>
      <AssetSummaryPanel v-if="asset.status > 0" :asset="asset" />
      <AssetRegistrationForm :is-editing="true" />
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import AssetSummaryPanel from "./components/AssetSummaryPanel.vue"
import AssetRegistrationForm from "./components/AssetRegistrationForm.vue"

// TODO: Define better types here once this asset ref is populated
const asset = ref<any>({})
</script>
