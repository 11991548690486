<template>
  <div class="h-[6.75rem] bg-neutral-10 p-4">
    <EacInventoryTooltip v-if="description" class="-mr-3 -mt-3">
      {{ description }}
    </EacInventoryTooltip>
    <div class="h-full content-center">
      <slot>
        <div v-if="value != undefined && unit" :title="value.toLocaleString()">
          {{ formattedValue }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { EacMeasurementParameter, getFormattedEacQuantityForMillions } from "@common/models/order"
import EacInventoryTooltip from "./EacInventoryTooltip.vue"
const props = defineProps<{ description?: string; value?: number; unit?: EacMeasurementParameter }>()

const formattedValue = computed(() => {
  const { unit = EacMeasurementParameter.Electricity, value = 0 } = props || {}
  const formatted = getFormattedEacQuantityForMillions(value, unit, value === 0 ? 0 : 2)
  if (value > 0 && parseFloat(formatted.quantity) === 0) {
    return `~${formatted}`
  }
  return formatted
})
</script>
