<template>
  <WcDropdown v-model="selectedOption" name="sort" required :options="listingSortOptions" :show-clear="false" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import WcDropdown from "@/components/input/WcDropdown.vue"
import type { SelectOption } from "@/components/input/WcDropdown"

const listingSortOptions = [
  { label: "highest carbon impact per unit of energy", value: "carbon_per_energy" },
  { label: "highest carbon impact per dollar", value: "carbon_per_dollar" },
  { label: "highest energy per dollar", value: "energy_per_dollar" },
]

const model = defineModel<string>()
const selectedOption = ref<SelectOption<string> | undefined>(getListingSortOption(model.value) ?? undefined)
watch(model, (newValue) => {
  selectedOption.value = getListingSortOption(newValue) ?? undefined
})
watch(selectedOption, (newValue) => {
  model.value = newValue?.value
})

function getListingSortOption(value: string | undefined) {
  return listingSortOptions.find((option) => option.value === value)
}
</script>
