<template>
  <InputNumber
    v-model="model"
    class="w-full"
    :class="inputClass"
    :currency="currency"
    input-class="w-full"
    :locale="locale"
    :mode="mode"
    :name="name"
    :placeholder="insetLabel"
    :min="min"
    :max="max"
    @input="(event) => $emit('input', event)" />
</template>

<script setup lang="ts">
import { computed } from "vue"
import InputNumber from "primevue/inputnumber"
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"
import type { WcInputNumberInputEvent } from "./WcInputNumber"

defineEmits<{
  (e: "input", event: WcInputNumberInputEvent): void
}>()

const model = defineModel<number | null>()
const props = withDefaults(
  defineProps<{
    mode?: "currency" | "decimal"
    insetLabel?: string
    name: string
    min?: number
    max?: number
    size?: InputSize
  }>(),
  {
    mode: "decimal",
  }
)
// Set these based on specified mode
const currency = computed(() => (props.mode === "currency" ? "USD" : undefined))
const locale = computed(() => (props.mode === "currency" ? "en-US" : undefined))

const inputClass = useInputClass(props)
</script>
