import { useAssetService, useListingService, useStoryService } from "@/services/service-container"

import { createMetaData } from "@/modules/market/story.utils"

import type { PublicAsset } from "@common/models/asset"
import type { LoadableStory, StoryMetaData } from "@/modules/market/story.utils"

export interface LoadedStoryData {
  loadedStory: LoadableStory
  metaData: StoryMetaData
}

export async function loadStory(storyId: number): Promise<LoadedStoryData | undefined> {
  const assetService = useAssetService()
  const storyService = useStoryService()
  const listingService = useListingService()

  try {
    // Search listings for the story to get the total EACs and CO2 available.
    // If there are no listings, then fetch the story directly.
    const data = await listingService.getAllListings({ storyId })
    let loadedStory: LoadableStory | undefined = data.stories.find((s) => s.id === storyId)
    let publicAssets: PublicAsset[] | undefined

    const isAvailableToOrder = loadedStory !== undefined

    if (!loadedStory) {
      await Promise.all([storyService.getStory(storyId), assetService.listAssetsPublic({ storyId })]).then(
        ([storyServiceResult, assetServiceResult]) => {
          loadedStory = storyServiceResult
          publicAssets = assetServiceResult.data
        }
      )
    }

    if (loadedStory) {
      const metaData = createMetaData(loadedStory, isAvailableToOrder, publicAssets)
      return { loadedStory, metaData }
    }
  } catch (error: any) {
    console.log(error)
    throw error
  }
}
