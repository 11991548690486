<template>
  <div>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        :class="props.iconClasses"
        d="M8.5328 25.3328L7 23.8L14.6 16.1664L7 8.5328L8.5328 7L16.1664 14.6L23.8 7L25.3328 8.5328L17.7328 16.1664L25.3328 23.8L23.8 25.3328L16.1664 17.7328L8.5328 25.3328Z" />
    </svg>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  iconClasses: { type: String, default: "fill-black" }, // e.g. "fill-black"
})
</script>
