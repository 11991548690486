import colors from "./colors"
import { GridPurpose } from "./models/siteAsset"

export { colors }

export const breakpoints = {
  // From quasar max values
  sm: 600,
  md: 1024,
  lg: 1440,
  xl: 1920,
  max: 1920,
}
export const gModelDict = {
  summary: {
    name: "summary",
    type: "summary",
    label: "Summary",
    text: "Summary",
    icon: "wc-ic:baseline-select-all",
    dataSource: "real_time",
    dataSourceLabel: "Summary",
    tags: [],
    params: ["annualUsageMwh", "areaSqft"],
  },
  single_family_detached: {
    name: "single_family_detached",
    type: "single_family_detached",
    label: "Single Family Detached",
    text: "A housing unit that provides living space for one household and is detached from any other housing unit.",
    icon: "wc-ic:baseline-house",
    tags: ["residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  single_family_attached: {
    name: "single_family_attached",
    type: "single_family_attached",
    label: "Single Family Attached",
    text: "A housing unit that provides living space for one family but is connected side-by-side with at least one other housing unit by a wall (e.g., townhouse, row house, or duplex.).",
    icon: "wc-ic:baseline-holiday-village",
    tags: ["residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  multi_family_2_4: {
    name: "multi_family_2_4",
    type: "multi_family_2_4",
    label: "Multi Family 2-4",
    text: "A building that includes two to four separate living quarters with individual kitchen facilities.",
    icon: "wc-ic:baseline-home-work",
    tags: ["residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  multi_family_5: {
    name: "multi_family_5",
    type: "multi_family_5",
    label: "Multi Family 5+",
    text: "A building that includes five or more separate living quarters with individual kitchen facilities.",
    icon: "wc-ic:baseline-apartment",
    tags: ["residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  mobile_home: {
    name: "mobile_home",
    type: "mobile_home",
    label: "Mobile Home",
    text: "A housing unit built off-site on a movable chassis and moved to a home site.",
    icon: "wc-ic:baseline-rv-hookup",
    tags: ["residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  small_office: {
    name: "small_office",
    type: "small_office",
    label: "Small Office",
    text: "A single-storey office building, usually under 10,000 sq.ft.",
    icon: "wc-ic:baseline-corporate-fare",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  medium_office: {
    name: "medium_office",
    type: "medium_office",
    label: "Medium Office",
    text: "A low-rise office building with four floors or under.",
    icon: "wc-ic:baseline-business",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  large_office: {
    name: "large_office",
    type: "large_office",
    label: "Large Office",
    text: "A mid- or high-rise office building with over four floors.",
    icon: "wc-ic:baseline-location-city",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  small_hotel: {
    id: 12,
    name: "small_hotel",
    type: "small_hotel",
    label: "Small Hotel",
    text: "Short term lodging facility with under 50 rooms usually in one or two storeys (e.g. motels, inns, boutique hotels, hostels).",
    icon: "wc-ic:baseline-night-shelter",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  large_hotel: {
    id: 13,
    name: "large_hotel",
    type: "large_hotel",
    label: "Large Hotel",
    text: "Large short-term lodging facility, typically with more than 50 rooms.",
    icon: "wc-ic:baseline-hotel",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  retail_stripmall: {
    id: 14,
    name: "retail_stripmall",
    type: "retail_stripmall",
    label: "Retail Stripmall",
    text: "Shopping center comprised of multiple connected establishments, each with a separate exterior entrance.",
    icon: "wc-ic:baseline-store",
    tags: ["commercial", "retail"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  retail_standalone: {
    id: 15,
    name: "retail_standalone",
    type: "retail_standalone",
    label: "Retail Standalone",
    text: "Buildings used for the sale and display of goods other than food. E.g. retail stores, vehicle dealerships, liquor stores, galleries.",
    icon: "wc-ic:baseline-storefront",
    tags: ["commercial", "retail"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  quick_service_restaurant: {
    id: 16,
    name: "quick_service_restaurant",
    type: "quick_service_restaurant",
    label: "Quick Service Restaurant",
    text: "Restaurants that offer limited menus or fast food, and rarely provide table service or serve alcohol. They may or may not have seating for customers.",
    icon: "wc-ic:baseline-food-bank",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  full_service_restaurant: {
    name: "full_service_restaurant",
    type: "full_service_restaurant",
    label: "Full Service Restaurant",
    text: "Restaurants that offer menus with a wide range of choices, serve alcohol and primarily serve dine-in customers.",
    icon: "wc-ic:baseline-restaurant",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  warehouse: {
    name: "warehouse",
    type: "warehouse",
    label: "Warehouse",
    text: "Building used primarily for short or long-term storage of goods, manufactured products, merchandise, and raw materials. E.g. Non-refrigerated warehouses, distribution or shipping centers, other storage buildings, and public rental storage units.",
    icon: "wc-ic:baseline-warehouse",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  outpatient: {
    name: "outpatient",
    type: "outpatient",
    label: "Outpatient",
    text: "Buildings used as diagnostic and treatment facilities for outpatient care. E.g. medical offices, dental clinics, veterinarians.",
    icon: "wc-ic:baseline-medication",
    tags: ["commercial", "public"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  hospital: {
    name: "hospital",
    type: "hospital",
    label: "Hospital",
    text: "Buildings used as diagnostic and treatment facilities for inpatient care.",
    icon: "wc-ic:baseline-local-hospital",
    tags: ["commercial", "public"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  primary_school: {
    name: "primary_school",
    type: "primary_school",
    label: "Primary School",
    text: "Buildings used for academic classroom instruction for elementary school-age students.",
    icon: "wc-ic:baseline-cottage",
    tags: ["commercial", "public"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  secondary_school: {
    name: "secondary_school",
    type: "secondary_school",
    label: "Secondary School",
    text: "Buildings used for academic classroom instruction for secondary school-age students.",
    icon: "wc-ic:baseline-school",
    tags: ["commercial", "public"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  solar: {
    name: "solar",
    type: "solar",
    gridPurpose: GridPurpose.MarketBasedProduction,
    label: "Solar REC",
    text: "A solar panel or a group of solar panels made of semiconductor materials and used to generate electricity from solar energy.",
    icon: "wc-ic:baseline-solar-power",
    tags: ["market_based_production"],
    dataSource: "real_time",
    params: ["nameplateCapacityKw", "annualEnergyGeneratedMwh"],
  },
  wind: {
    name: "wind",
    type: "wind",
    gridPurpose: GridPurpose.MarketBasedProduction,
    label: "Wind REC",
    text: "A wind turbine or a wind farm that converts the kinetic energy of wind into electrical energy.",
    icon: "wc-ic:baseline-wind-power",
    tags: ["market_based_production"],
    dataSource: "cached",
    params: ["nameplateCapacityMw", "annualEnergyGeneratedMwh"],
  },
  solar_locational: {
    name: "solar_locational",
    type: "solar",
    gridPurpose: GridPurpose.LocationalProduction,
    label: "Solar Locational",
    text: "A solar panel or a group of solar panels made of semiconductor materials and used to generate electricity from solar energy.",
    icon: "wc-ic:baseline-solar-power",
    tags: ["locational_production"],
    dataSource: "real_time",
    params: ["nameplateCapacityKw", "annualEnergyGeneratedMwh"],
  },
  custom: {
    name: "custom",
    type: "custom",
    label: "Custom",
    text: "Any energy resource for which you have hourly energy use data.",
    icon: "wc-ic:baseline-flash-on",
    tags: ["commercial", "residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: [],
  },
} as { [key: string]: any }
