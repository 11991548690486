import Supplier from "./SupplierView.vue"
import { RouteRecordRaw } from "vue-router"
import AppLayout from "@/components/layout/AppLayout.vue"
import { AppPortal } from "@common/models/models"
export const suppliersRoutes: Array<RouteRecordRaw> = [
  {
    path: "/suppliers/:id/:slug",
    name: "wc-supplier",
    component: Supplier,
    meta: { layout: AppLayout, appPortal: AppPortal.Enterprise, title: "Supplier Spotlight" },
  },
]
