import type { AxiosResponse } from "axios"
import { parseLinkHeader } from "@web3-storage/parse-link-header"

export const parseLinkHeaderRelUrl = (rel: string, header: string | undefined) => {
  if (!header) return undefined

  const parsed = parseLinkHeader(header)

  if (!parsed || !parsed[rel]) {
    return undefined
  }
  return parsed[rel]["url"]
}

export const parsePaginationInfo = (response: AxiosResponse) => {
  return {
    nextPageUrl: parseLinkHeaderRelUrl("next", response.headers["link"]),
    prevPageUrl: parseLinkHeaderRelUrl("prev", response.headers["link"]),
  }
}
