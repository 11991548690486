const black = "#000D0F"
const white = "#FFFFFF"

const blue = {
  DEFAULT: "#068DA9", // 50
  0: white,
  10: "#0BD4FF", // Lake Blue
  20: "#09BBE1",
  30: "#08ACCE",
  40: "#079DBC",
  50: "#068DA9",
  60: "#057E98",
  70: "#046F85",
  80: "#036073",
  90: "#003E4A", // Sherpa Blue
  99: "#00232C", // Dark Sherpa Blue
}

const neutral = {
  DEFAULT: "#99A5A8", // 50
  0: white,
  10: "#FAFAFB",
  20: "#E5EAEB",
  30: "#CDD7DA",
  40: "#B4BEC0",
  50: "#99A5A8",
  60: "#7E898C",
  70: "#586469",
  80: "#404A4E",
  90: "#1A1F21",
  99: black,
}

const darkTone = {
  DEFAULT: blue[90],
  main: blue[90],
  dark: blue[99],
  lightbackground: neutral[10],
}

const sageTone = {
  DEFAULT: "#6C8C93",
  main: "#6C8C93",
  dark: "#5B7A80",
  light: "#B0C8CB",
  lightbackground: "#E9F0F1",
  hover: "#436166",
  darkbackgroundhover: "#DDE8E9",
}

const carbon = "#363f50"
const energy = blue[10]

export default {
  // Primary brand colors
  transparent: "transparent",
  black,
  white,
  blue,
  neutral,
  darktone: darkTone,
  sagetone: sageTone,

  // Text colors
  primary: black,
  secondary: darkTone.main,
  disabled: neutral[50],
  hint: sageTone.dark,

  // Other special colors
  carbon,
  energy,

  // Status colors
  info: {
    DEFAULT: "#87CAFF",
    main: "#87CAFF",
    dark: "#1576C7",
    light: "#C7E5FD",
    lightbg: "#F3FAFF",
  },
  success: {
    DEFAULT: "#81D8A5",
    main: "#81D8A5",
    dark: "#147854",
    light: "#CCF6E2",
    lightbg: "#F5FDF8",
  },
  warning: {
    DEFAULT: "#FAB35F",
    main: "#FAB35F",
    dark: "#965214",
    light: "#FFE1BD",
    lightbg: "#FFF7EF",
  },
  error: {
    DEFAULT: "#DE0000",
    main: "#DE0000",
    dark: "#AD0000",
    light: "#F8CCCC",
    lightbg: "#FFF1F2",
  },

  divider: {
    dark: blue[99],
    light: neutral[40],
    extralight: "#E5EAEB",
    sagedark: sageTone.dark,
    sagelight: sageTone.light,
    sageextralight: sageTone.lightbackground,
  },

  background: {
    dark: blue[99],
    light: neutral[10],
    sagedark: sageTone.dark,
    sagelight: sageTone.lightbackground,
  },

  highlight: {
    DEFAULT: blue[10],
    neonblue: blue[10],
    electricblue: "#85EBFF",
    frostblue: "#C0F5FF",
    pastelazure: "#F1FDFF",
    yellow: {
      DEFAULT: "#F1F514",
      hover: "#E7ED05",
    },
  },

  // Tertiary colors: For data visualization. Use sparingly in UI
  lilac: {
    DEFAULT: "#8170E8",
    100: "#ECEAFC",
    200: "#D9D4F8",
    300: "#C0B7F3",
    400: "#A79BEF",
    500: "#8170E8",
  },
  orange: {
    DEFAULT: "#FF7E4D",
    100: "#FFECE4",
    200: "#FFD8CA",
    300: "#FFBEA6",
    400: "#FFA582",
    500: "#FF7E4D",
  },
  perkygreen: {
    DEFAULT: "#41907A",
    100: "#E3EEEB",
    200: "#C6DED7",
    300: "#A0C7BC",
    400: "#7AB1A2",
    500: "#41907A",
  },
  cornyellow: {
    DEFAULT: "#F8B600",
    100: "#FEF4D9",
    200: "#FDE9B3",
    300: "#FBDA80",
    400: "#FACC4D",
    500: "#F8B600",
  },
  neonred: {
    DEFAULT: "#FF4D59",
    100: "#FFE4E6",
    200: "#FFCACD",
    300: "#FFA6AC",
    400: "#FF828B",
    500: "#FF4D59",
  },
  mauveblue: {
    DEFAULT: "#353CD9",
    100: "#E1E2F9",
    200: "#C2C5F4",
    300: "#9A9DEC",
    400: "#7277E4",
    500: "#353CD9",
  },
  electricpurple: {
    DEFAULT: "#B600F8",
    100: "#F4D9FE",
    200: "#E9B3FD",
    300: "#DA80FB",
    400: "#CC4DFA",
    500: "#B600F8",
  },
  umepurple: {
    DEFAULT: "#904156",
    100: "#EEE3E6",
    200: "#DEC6CC",
    300: "#C7A0AA",
    400: "#B17A89",
    500: "#904156",
  },
  maygreen: {
    DEFAULT: "#499041",
    100: "#E4EEE3",
    200: "#C8DEC6",
    300: "#A4C7A0",
    400: "#80B17A",
    500: "#499041",
  },

  // Legacy graph colors
  graph: {
    text: "#686F7C",
    background: neutral[10],
    grid: "#757575",
    carbonBased: carbon,
    carbonBasedLight: "#EAEBED",
    carbonFree: "#F1F514",
    carbonFreeLight: "#F1F514",
    carbonFreeLocational: "#FFDD82",
    carbonFreeLocationalLight: white,
    carbonFreeMarket: "#FFAE0B",
    carbonFreeMarketLight: "#FFEAB3",
    carbonIntensity: "#FF4D59",
    carbon,
    energy,
  },
}
