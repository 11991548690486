import { useMainStore } from "@/store"
import { RouteLocationNormalized } from "vue-router"

export const authorizedRoute = async (to: RouteLocationNormalized) => {
  const store = useMainStore()
  if (store.isLoggedIn) {
    return true
  }
  return { name: "wc-signin", query: { l: to.fullPath } }
}

export const adminRoute = (to: RouteLocationNormalized) => {
  const store = useMainStore()
  if (store.isAdmin) {
    return true
  }
  return { name: "wc-signin", query: { l: to.fullPath } }
}
