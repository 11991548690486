<template>
  <div :class="inputClass">
    <input ref="fileInput" type="file" :accept="accept" class="w-full" :name="name" :required="required" @change="onChangeFile" />
    <div v-if="!!error" class="text-error">
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"
const DEFAULT_MAX_FILE_SIZE = 10_000_000

const model = defineModel<FileList | null>()
const props = withDefaults(
  defineProps<{
    accept?: string
    maxFileSize?: number
    name: string
    required?: boolean
    size?: InputSize
  }>(),
  {
    maxFileSize: DEFAULT_MAX_FILE_SIZE,
  }
)
const emit = defineEmits(["update:modelValue"])

const fileInput = ref<HTMLInputElement | null>(null)
const error = ref<string>("")

const inputClass = useInputClass(props)

// Clear fileInput when the parent resets the value
watch(
  () => model.value,
  (value) => {
    if (value === null && fileInput.value) {
      fileInput.value!.value = ""
    }
  }
)

const onChangeFile = (payload: Event) => {
  const target = payload.target as HTMLInputElement
  const files = Array.from(target.files || [])

  const hasOversizedFiles = files.some((file: File) => {
    if (file.size > props.maxFileSize) {
      return true
    }
  })

  if (hasOversizedFiles) {
    fileInput.value!.value = ""
    error.value = `Files must be less than ${Math.floor(props.maxFileSize / 1024 / 1024)} MB`
  } else {
    error.value = ""
    emit("update:modelValue", target.files)
  }
}
</script>
