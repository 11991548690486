<template>
  <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
    <router-link
      v-for="group in assetGroups"
      :key="group.id"
      :to="{ name: 'wc-asset-groups-asset-group', params: { assetGroupId: group.id } }"
      class="flex flex-col rounded-lg border border-neutral-30 p-4">
      <div class="mb-5 flex justify-between gap-2">
        <h3 class="text-subheading-1">{{ group.name }}</h3>
        <WcButtonIcon
          class="top-1 size-[17px] shrink-0 cursor-pointer"
          icon="wc-carbon:edit"
          color="green"
          variant="tertiary"
          @click="(event) => showEditAssetGroupModal(group.id, event)" />
      </div>
      <div class="mb-8 grid grow grid-cols-3 gap-4">
        <div>
          <div class="text-subheading-large-bold">{{ group.deviceIds.length }}</div>
          <div class="text-body-3">Total Assets</div>
        </div>
        <div>
          <div class="text-subheading-large-bold">X.XXm</div>
          <div class="text-body-3">Total EACs</div>
        </div>
      </div>
      <div class="text-caption grid grow-0 grid-cols-2 gap-0 text-hint">
        <div>Created: {{ formatDate(group.createdTime) }}</div>
        <div>Last updated: {{ formatDate(group.updatedTime) }}</div>
      </div>
    </router-link>
  </div>
  <EmptyState v-if="assetGroups.length === 0" title="No assets groups">
    <p class="text-body-2 mt-2">
      <a class="text-hyperlink" @click="showCreateAssetGroupModal">Create one or more groups</a> to organize your assets and track metrics across
      segments of your portfolio.
    </p>
  </EmptyState>
  <AddAssetGroupModal ref="addAssetGroupModal" @asset-group-added="handleAssetGroupAdded" />
  <EditAssetGroupModal ref="editAssetGroupModal" @asset-group-updated="handleAssetGroupUpdated" />
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useRouter } from "vue-router"
import WcButtonIcon from "@/components/button/WcButtonIcon.vue"
import EmptyState from "@/components/ui/EmptyState.vue"
import type { AssetGroup } from "@common/models/asset"
import AddAssetGroupModal from "./AddAssetGroupModal.vue"
import EditAssetGroupModal from "./EditAssetGroupModal.vue"

const router = useRouter()

defineProps<{ assetGroups: AssetGroup[] }>()

const emit = defineEmits<{
  "row-delete-clicked": [rowId: number, event: MouseEvent]
  "asset-group-updated": [assetGroup: AssetGroup | null]
}>()

const addAssetGroupModal = ref()
const editAssetGroupModal = ref()

const formatter = new Intl.DateTimeFormat("en", { dateStyle: "short" })
const formatDate = (date: string) => formatter.format(new Date(date))

const showCreateAssetGroupModal = () => {
  addAssetGroupModal.value.openModal()
}

const showEditAssetGroupModal = (assetGroupId: number, event: MouseEvent) => {
  event.preventDefault()

  editAssetGroupModal.value.openModal(assetGroupId)
}

const handleAssetGroupUpdated = (addedGroup: AssetGroup | null) => {
  emit("asset-group-updated", addedGroup)
}

const handleAssetGroupAdded = (addedGroup: AssetGroup) => {
  router.push({ name: "wc-asset-groups-asset-group", params: { assetGroupId: addedGroup.id } })
}
</script>
