<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select id="tabs" name="tabs" class="border-gray-300 block w-full rounded-md focus:border-blue-90 focus:ring-blue-90">
        <option v-for="type in modelTypes" :key="type.type" :selected="type.type === tab">{{ type.label }}</option>
      </select>
    </div>
    <div class="m-1 flex rounded-md bg-neutral-20 p-2">
      <div class="whitespace-nowrap px-3 py-2 text-sm font-bold">Asset Type</div>
      <div class="tabs flex w-full items-center justify-evenly space-x-4">
        <div
          v-for="(type, index) in modelTypes"
          :key="index"
          class="cursor-pointer"
          :class="[type.type === tab ? 'active' : '', 'rounded-md px-3 py-2 text-sm font-medium']"
          :aria-current="type.type === tab ? 'page' : undefined"
          :data-cy="'tab-assetType-' + type.type"
          @click="tab = type.type">
          {{ type.label }}
        </div>
      </div>
    </div>
  </div>
  <section v-for="(type, index) in modelTypes" :key="index" data-cy="asset-type-group" class="p-2">
    <section v-if="type.type === tab" class="model-items flex flex-wrap gap-2">
      <div
        v-for="item in models.filter((m) => m.tags && m.tags.indexOf(type.type) > -1)"
        :key="item.name"
        class="flex size-20 cursor-pointer flex-col content-center items-center justify-center gap-2 bg-blue-40 hover:border hover:border-blue-40 hover:bg-highlight-pastelazure"
        :title="item.text"
        :data-cy="'asset-type-' + item.type"
        @click="selectModel(item)">
        <Icon :icon="item.icon" class="size-8" />
        <label class="text-center text-xs"> {{ item.label }}</label>
      </div>
    </section>
  </section>
</template>

<script lang="ts" setup>
import { PropType, ref } from "vue"
import { Icon } from "@iconify/vue"
import { SiteAsset } from "@common/models/siteAsset"
import { gModelDict } from "@common/global"

const props = defineProps({
  asset: { type: Object as PropType<SiteAsset>, required: true },
})
const emit = defineEmits(["update:asset"])

const tab = ref("commercial")

const modelTypes = [
  { type: "commercial", label: "Commercial", isActive: true },
  { type: "residential", label: "Residential", isActive: false },
  { type: "market_based_production", label: "Market Energy", isActive: false },
  { type: "locational_production", label: "Locational Energy", isActive: false },
]

const models = Object.values(gModelDict)
const activeModel = ref({})
const selectModel = async (model: any) => {
  activeModel.value = model
  emit("update:asset", {
    ...props.asset,
    type: model.name,
    gridPurpose: model.gridPurpose,
  })
}
</script>
