<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g class="fill-[currentColor]">
      <path :class="props.iconClasses" d="M12 15.3751L6 9.3751L7.4 7.9751L12 12.5751L16.6 7.9751L18 9.3751L12 15.3751Z" />
    </g>
  </svg>
</template>
<script setup lang="ts">
const props = defineProps({
  iconClasses: { type: String },
})
</script>
