import { computed } from "vue"
import type { InputSize } from "./input"

const inputClassBySize = {
  xsmall: "input-size-xsmall",
  small: "input-size-small",
  medium: "input-size-medium",
  large: "input-size-large",
}

const DEFAULT_SIZE = "large"

const useInputClass = (props: { size?: InputSize }) => {
  return computed(() => inputClassBySize[props.size ?? DEFAULT_SIZE])
}

export default useInputClass
