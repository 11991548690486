<template>
  <div v-if="!manageCookies" class="www-override z-50 rounded border border-neutral-30 bg-white text-center">
    <h3 class="text-heading-5 www-margins">This website uses cookies</h3>
    <div class="flex flex-col justify-between">
      <div class="text-body-2 relative text-center">WattCarbon uses cookies that are essential for our site to work.</div>
      <div class="text-body-2 relative mb-8 text-center">
        Read WattCarbon's <a class="underline" :href="`${WWW_BASE_URL}/cookie-policy`" target="_blank">cookie policy</a> for more details.
      </div>
      <div class="md-no-wrap button-wrap flex flex-row justify-center gap-2 sm:flex-wrap">
        <WcButton class="button-width" text="Accept All" button-classes="ph-no-capture" @click="acceptCookies" />
        <WcButton class="button-width" text="Manage Cookies" variant="secondary" button-classes="ph-no-capture" @click="toggleCookieCopy" />
      </div>
    </div>
  </div>
  <div v-else class="www-override www-max-height z-50 rounded border border-neutral-30 bg-white text-left">
    <h3 class="text-heading-5 www-margins">Manage cookies</h3>
    <div class="flex flex-col justify-between">
      <div class="www-mb-9 relative flex flex-col">
        <span class="text-subheading-1 www-mb-1 font-bold">Essential cookies</span>
        <span class="text-body-2"
          >These cookies are necessary for the website to function and cannot be switched off in our systems. They may enable the website to provide
          enhanced functionality and personalization based on your interaction with the website. They are usually only set in response to actions made
          by you which amount to a request for services, such as setting your privacy preferences or filling in forms.</span
        >
      </div>
      <div class="relative mb-6 flex flex-col">
        <span class="text-subheading-1 www-mb-1 font-bold">Analytics cookies</span>
        <span class="text-body-2"
          >These cookies allow us to track analytics by counting visits and traffic sources so we can measure and improve the performance of our
          website. They may be set by us or by third party providers to help us to know which pages are the most and least popular and see how
          visitors move around the website.</span
        >
      </div>
      <div class="text-body-2 relative mb-8 flex flex-col text-blue-70">
        <a class="underline" :href="`${WWW_BASE_URL}/cookie-policy`" target="_blank">Read our cookie policy</a>
      </div>
      <div class="button-wrap flex flex-row justify-center gap-2">
        <WcButton class="button-width" text="Accept All" button-classes="ph-no-capture" @click="acceptCookies" />
        <WcButton
          class="button-width"
          text="Accept Only Essential"
          variant="secondary"
          button-classes="ph-no-capture"
          @click="acceptEssentialCookies" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue"

import { getEnvironment } from "@/environment"
import { gtmService } from "@/services/gtm.service"
import { linkedInInsightsService } from "@/services/linkedInInsights.service"
import { WcButton } from "@/components/button"
import { posthog } from "posthog-js"

const manageCookies = ref<boolean>(false)
const toggleCookieCopy = () => (manageCookies.value = true)
const emit = defineEmits(["closeCard"])

const { WWW_BASE_URL, BASE_DOMAIN } = getEnvironment()

const acceptCookies = () => {
  // Create cookie
  document.cookie = `wattcarboncookies=true;domain=${BASE_DOMAIN};path=/;SameSite=strict;max-age=31536000;secure`
  posthog.opt_in_capturing()
  gtmService.grantConsent()
  linkedInInsightsService.grantConsent()
  emit("closeCard")
}
const acceptEssentialCookies = () => {
  document.cookie = `wattcarboncookies=false;domain=${BASE_DOMAIN};path=/;SameSite=strict;max-age=31536000;secure`
  posthog.opt_out_capturing()
  emit("closeCard")
}
</script>

<style scoped lang="scss">
.font-bold {
  font-weight: 700;
}
// Added this since www's style broke
.www-override {
  position: fixed;
  right: 36px;
  bottom: 36px;
  // width: 33.333333%;
  width: 540px;
  padding: 24px;
  // max-width: 572px;
  // min-width: 304px;

  @media only screen and (max-width: 680px) {
    width: 304px;
    padding: 16px;
    right: 8px;
    bottom: 8px;
  }
}
.www-margins {
  margin-top: 8px;
  margin-bottom: 16px;
  @media only screen and (max-width: 680px) {
    margin: 0 0 8px 0;
  }
}

.www-max-height {
  @media only screen and (max-width: 680px) {
    max-height: calc(100vh - 8px);
    overflow: scroll;
  }
}

// 1.5
.www-mb-1 {
  margin-bottom: 6px;
}

.www-mb-9 {
  margin-bottom: 36px;
}

.button-wrap {
  @media only screen and (max-width: 680px) {
    flex-wrap: wrap;
  }
}

.button-width {
  @media only screen and (max-width: 680px) {
    flex-grow: 1;
  }
}
</style>
