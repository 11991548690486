<template>
  <div v-if="store.isVisible" ref="container">
    <ProcurementModule v-if="story" :story="story as MarketplaceStory" @close-procurement-options="store.hide" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import type { Ref } from "vue"

import ProcurementModule from "@/modules/market/ProcurementModule.vue"
import type { LoadableStory } from "@/modules/market/story.utils"
import type { MarketplaceStory } from "@common/models/story"

import { useProcurementStore } from "./procurement-store"

defineProps<{
  story: LoadableStory
}>()

const container: Ref<HTMLElement | undefined> = ref<HTMLElement | undefined>()

const store = useProcurementStore()

onMounted(() => {
  store.setContainerRef(container)
})
</script>
