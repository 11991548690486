<template>
  <Dialog
    modal
    :visible="isOpen"
    close-on-escape
    closable
    dismissable-mask
    :class="{
      'modal-small': size === 'small' || !size,
      'modal-medium': size === 'medium',
      'modal-large': size === 'large',
    }"
    :draggable="false"
    :style="{ width }"
    @update:visible="$emit('update:isOpen', $event)">
    <template #header>
      <div class="text-heading-4 pr-8 text-black">{{ header }}</div>
    </template>
    <slot name="panel"></slot>
    <template v-if="$slots.footer" #footer>
      <div class="footer-buttons ph-no-capture flex w-full flex-wrap justify-between gap-4 pt-4 sm:justify-end">
        <slot name="footer"></slot>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed } from "vue"
import Dialog from "primevue/dialog"

defineEmits(["update:isOpen"])

defineSlots<{
  footer?: () => any
  panel?: () => any
}>()

type Props = {
  header: string
  isOpen: boolean
  size?: "small" | "medium" | "large" | "xlarge"
}
const props = withDefaults(defineProps<Props>(), {
  size: "medium",
})

const width = computed(() => {
  switch (props.size) {
    case "xlarge":
      return "1080px"
    case "large":
      return "780px"
    case "medium":
      return "620px"
    case "small":
    default:
      return "540px"
  }
})
</script>

<style lang="scss">
.p-dialog {
  border-radius: 0.5rem;
}
.p-dialog-header {
  border-radius: 0.5rem 0.5rem 0 0;
}
.p-dialog-footer {
  border-radius: 0 0 0.5rem 0.5rem;
}
.p-dialog .p-dialog-content {
  @apply pb-4;
}
.p-dialog.modal-small {
  .p-dialog-footer {
    .footer-buttons {
      @apply justify-between;
    }
  }
}
.p-dialog.modal-medium {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    @apply px-[20px];
    @apply sm:px-[40px];
  }
}
.p-dialog.modal-large,
.p-dialog.modal-xlarge {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    @apply px-[20px];
    @apply sm:px-[60px];
  }
}
.p-dialog-header {
  @apply pt-[36px];
}
.p-dialog-footer {
  @apply pb-[36px];
}
.p-dialog-header-icons {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.p-dialog-header-icon > svg {
  height: 1.5rem;
  width: 1.5rem;
}
</style>
