<template>
  <WcModal :is-open="isOpen" size="xlarge" header="Upload Assets" @update:is-open="handleUpdateIsOpen">
    <template #panel>
      <UploadAssetsViewForm v-if="!showAssetReview" :account-id="accountId" is-admin-context @show-asset-review="handleShowAssetReview" />
      <ReviewAssetUploadViewForm v-if="showAssetReview" @show-uploaded-assets="handleShowUploadedAssets" @show-asset-upload="handleShowAssetUpload" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { ref } from "vue"
import WcModal from "@/components/WcModal.vue"
import UploadAssetsViewForm from "@/modules/asset/UploadAssetsViewForm.vue"
import ReviewAssetUploadViewForm from "@/modules/asset/ReviewAssetUploadViewForm.vue"

defineProps<{ accountId?: number }>()

const isOpen = ref(false)
const showAssetReview = ref(false)

const resetForm = () => {
  showAssetReview.value = false
}

const handleUpdateIsOpen = (updatedIsOpen: boolean) => {
  resetForm()
  isOpen.value = updatedIsOpen
}

const handleShowAssetReview = () => {
  showAssetReview.value = true
}

const handleShowUploadedAssets = () => {
  resetForm()
  location.reload()
}

const handleShowAssetUpload = () => {
  showAssetReview.value = false
}

const openModal = () => handleUpdateIsOpen(true)
const closeModal = () => handleUpdateIsOpen(false)

defineExpose({ openModal, closeModal })
</script>
