<template>
  <div>
    <h5 class="text-subheading-2 mb-3">
      {{ props.title }}
      <br v-if="props.subtitle" />
      {{ props.subtitle }}
    </h5>
    <div :class="{ 'animate-pulse': loading }">
      <div class="mb-1 text-2xl" :class="figureClass">
        <span data-cy="stat-value">{{ props.figure }}</span>
        <span v-if="props.unit === Unit.tCO2" class="ml-2" :class="unitClass">tCO<sub>2</sub></span>
        <span v-if="props.unit === Unit.MWh" class="ml-2" :class="unitClass">MWh</span>
      </div>
      <div class="text-body-2 flex gap-4">
        <div v-for="(marginalStat, i) in props.marginalStats" :key="i" :class="marginalStat.class">
          <Icon
            v-if="marginalStat.directionChange && marginalStat.directionChange > 0"
            icon="wc-carbon:arrow-up"
            class="-mt-0.5 mr-0.5 inline size-4"
            aria-hidden="true"
            aria-label="Increase" />
          <Icon
            v-else-if="marginalStat.directionChange && marginalStat.directionChange < 0"
            icon="wc-carbon:arrow-down"
            class="-mt-0.5 mr-0.5 inline size-4"
            aria-hidden="true"
            aria-label="Decrease" />
          <span>
            {{ marginalStat.text }}
            <span v-if="marginalStat.unit === Unit.tCO2perMWh">tCO<sub>2</sub>/MWh</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Icon } from "@iconify/vue"

const Unit = {
  MWh: "MWh",
  tCO2: "tCO2",
  tCO2perMWh: "tCO2perMWh",
}

type MarginalStat = {
  text: string
  unit?: "tCO2perMWh"
  directionChange?: number
  class: string
}

interface Props {
  title: string
  subtitle?: string
  figure: string
  figureClass?: string
  unit?: keyof typeof Unit
  unitClass?: string
  marginalStats: Array<MarginalStat>
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
})
</script>
