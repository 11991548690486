<template>
  <AppPage>
    <AppPageHeader :backlink-route="{ name: 'wc-assets' }" backlink-text="Back to Assets">Upload asset metadata to WEATS</AppPageHeader>
    <AppPageContent>
      <div class="max-w-[780px]">
        <UploadAssetsViewForm @show-asset-review="handleShowAssetReview" />
      </div>
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import UploadAssetsViewForm from "./UploadAssetsViewForm.vue"

const router = useRouter()

const handleShowAssetReview = () => {
  router.push({ name: "wc-assets-review-upload" })
}
</script>
