import { RouteRecordRaw } from "vue-router"

import PageNotFound from "./PageNotFound.vue"

export const errorRoutes: Array<RouteRecordRaw> = [
  {
    path: "/404",
    name: "wc-404",
    component: PageNotFound,
  },
  {
    path: "/:catchAll(.*)",
    name: "wc-404-catchall",
    component: PageNotFound,
  },
]
