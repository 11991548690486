import { HtmlRenderer, Node } from "commonmark"

const reUnsafeProtocol = /^javascript:|vbscript:|file:|data:/i
const reSafeDataProtocol = /^data:image\/(?:png|gif|jpeg|webp)/i

// Copied from https://github.com/commonmark/commonmark.js/blob/master/lib/render/html.js#L9
const isPotentiallyUnsafeUrl = (url: string) => {
  return reUnsafeProtocol.test(url) && !reSafeDataProtocol.test(url)
}

// These are available but aren't declared in the @types/commonmark definitions
declare module "commonmark" {
  interface HtmlRenderer {
    attrs(node: Node): [string, string][]
    esc(str: string): string
    // Helper function to produce an HTML tag.
    tag(name: string, attrs?: [string, string][]): void
  }
}

export class WcCommonmarkHtmlRenderer extends HtmlRenderer {
  // Copied from https://github.com/commonmark/commonmark.js/blob/master/lib/render/html.js#L64
  // and adapted to allow for target="_blank" links
  link(node: Node, entering: boolean) {
    const attrs = this.attrs(node)
    if (entering) {
      if (node.destination && !isPotentiallyUnsafeUrl(node.destination)) {
        attrs.push(["href", this.esc(node.destination)])

        // Add target="_blank" and rel="noopener" to links
        attrs.push(["target", "_blank"])
        attrs.push(["rel", "noopener"])
      }
      if (node.title) {
        attrs.push(["title", this.esc(node.title)])
      }
      this.tag("a", attrs)
    } else {
      this.tag("/a", attrs)
    }
  }
}
