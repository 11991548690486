import { defineStore } from "pinia"
import { startOfMonth, endOfMonth, addMonths } from "date-fns"
import { Order } from "@common/models/order"
const currentMonth = startOfMonth(new Date())
export const useCarbonAccountingDashboardStore = defineStore("dashboard", {
  state: () => {
    return {
      assetsDateRange: {
        startDate: addMonths(currentMonth, -12),
        endDate: endOfMonth(addMonths(currentMonth, -1)),
      },
      orders: new Array<Order>(),
    }
  },
})
