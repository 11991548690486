<template>
  <WcButton v-if="!store.isVisible" text="Custom Order" v-bind="$props" @click="openCustomOrderModal" />
</template>

<script setup lang="ts">
import posthog from "posthog-js"

import { WcButton } from "@/components/button"

import { useCustomOrderStore } from "./custom-order-store"

const store = useCustomOrderStore()

const openCustomOrderModal = () => {
  posthog.capture(`Clicked on "Custom Order" from the Story View`)
  store.show()
}
</script>
