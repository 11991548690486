<template>
  <StoryCustomOrderModal v-if="!!story" ref="customOrderModal" :story="story" :form-context="`Story page ${story.id}: ${story?.name}`" />
</template>

<script setup lang="ts">
import { ref } from "vue"
import StoryCustomOrderModal from "@/modules/market/StoryCustomOrderModal.vue"
import type { LoadableStory } from "@/modules/market/story.utils"

import { useCustomOrderStore } from "./custom-order-store"

defineProps<{
  story: LoadableStory
}>()

const store = useCustomOrderStore()

const customOrderModal = ref<InstanceType<typeof StoryCustomOrderModal>>()

store.$subscribe(() => {
  if (store.isVisible) {
    customOrderModal.value?.openModal()
  } else {
    customOrderModal.value?.closeModal()
  }
})
</script>
