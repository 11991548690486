import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@common/models/models"
import PortfolioView from "@/modules/portfolio/PortfolioView.vue"
import AppLayout from "@/components/layout/AppLayout.vue"
import PortfolioConfirmationView from "@/modules/portfolio/PortfolioConfirmationView.vue"

export const portfolioRoutes: Array<RouteRecordRaw> = [
  {
    path: "/marketplace/portfolios/:id",
    name: "wc-portfolio",
    component: PortfolioView,
    meta: { layout: AppLayout, appPortal: AppPortal.Enterprise },
  },
  {
    path: "/marketplace/portfolios/confirmation",
    name: "wc-portfolio-confirmation-legacy",
    redirect: (to) => {
      const orderId = Number(to.query.id)
      if (isNaN(orderId)) {
        return { name: "wc-portfolio-404" }
      }
      delete to.query.id

      return {
        name: "wc-portfolio-confirmation",
        params: {
          id: orderId,
        },
      }
    },
  },
  {
    path: "/marketplace/portfolios/confirmation/:id",
    name: "wc-portfolio-confirmation",
    component: PortfolioConfirmationView,
    meta: { layout: AppLayout, appPortal: AppPortal.Enterprise },
  },
]
