import papaparse from "papaparse"

export const parseCsvFileHeadings = (fileData: File, options?: { delimiter?: string; skipLines?: number }): Promise<string[]> => {
  // Note: This skips the first N *rows* rather than lines. There can be a
  // difference if the CSV has multi-line cells. If this becomes a problem,
  // we can switch to using skipFirstNLines once
  // https://github.com/mholt/PapaParse/issues/1045 is addressed.
  const skipLines = options?.skipLines ?? 0
  return new Promise((resolve, reject) => {
    papaparse.parse(fileData, {
      header: false,
      preview: skipLines + 1, // Only parse the first N rows
      error: reject,
      delimiter: options?.delimiter ?? ",",
      complete: (results) => resolve((results.data[skipLines] ?? []) as string[]),
    })
  })
}

export const parseCsvFile = (fileData: File): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    papaparse.parse(fileData, {
      header: true,
      error: reject,
      complete: (results) => {
        // Strip off the __parsed_extra field which contains extra row cells that extend beyond what the header defines
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const data = (results.data as any[]).map(({ __parsed_extra, ...row }) => row)
        resolve(data)
      },
    })
  })
}

export const createCsvBlob = (data: any[]): Blob => {
  const csv = papaparse.unparse(data)
  return new Blob([csv], { type: "text/csv;charset=utf-8;" })
}
