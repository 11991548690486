<template>
  <form v-if="supplier.accountId !== undefined || account.type == 'organization'" class="flex flex-col gap-2" @submit.prevent="saveSupplier()">
    <div class="flex flex-row justify-between">
      <label class="text-body-2 flex flex-row-reverse items-center justify-end gap-2">
        Visible to public
        <input v-model="supplier.isListedOnMarketplace" type="checkbox" :disabled="supplier.accountId == undefined" />
      </label>

      <router-link
        v-if="supplier.accountId !== undefined"
        :to="{ name: 'wc-supplier', params: { id: supplier.accountId, slug: supplier.slug } }"
        class="flex items-center gap-2 rounded-md bg-blue-90 p-2 text-white"
        target="_blank">
        <Icon icon="wc-carbon:view" class="mr-2" />
        View Supplier Spotlight
      </router-link>
    </div>

    <label>
      Display Name
      <input v-model="supplier.displayName" type="text" required />
    </label>

    <label>
      Category
      <select v-model="supplier.projectCategories[0]" class="text-body-2 block w-full rounded-md border border-blue-90 p-2" required>
        <option value="renewables">Renewable Energy</option>
        <option value="electrification">Electrification</option>
        <option value="demand_response">Demand Response</option>
      </select>
    </label>

    <label>
      Operating Locations
      <input v-model="supplier.operatingLocations" type="text" placeholder="West Virginia, Texas, California" required />
    </label>

    <label>
      About This Company
      <textarea v-model="supplier.aboutThisCompany" required rows="10" />
    </label>

    <label>
      <span>Climate Impacts (format with <a href="https://commonmark.org/help/" target="_blank" class="cursor-help underline">Commonmark</a>)</span>
      <textarea v-model="supplier.climateImpacts" required rows="10" />
    </label>

    <label>
      <span>Community Impact (format with <a href="https://commonmark.org/help/" target="_blank" class="cursor-help underline">Commonmark</a>)</span>
      <textarea v-model="supplier.coBenefits" rows="10" />
    </label>

    <WcButton text="Save" type="submit" icon="wc-carbon:save" class="w-fit" />
  </form>

  <p v-else>Individual-type accounts cannot be made into suppliers. Please make an Organization-type account.</p>

  <section v-if="supplier.accountId !== undefined">
    <header class="mt-4 rounded-md bg-neutral-20 p-4">Hero Images</header>

    <div class="mt-2 flex max-w-4xl flex-row gap-2 overflow-x-scroll pb-4">
      <form v-for="image in supplier.images" :key="image.id" class="flex w-[240px] flex-col justify-end gap-2" @submit.prevent="updateImage">
        <input type="hidden" name="imageId" :value="image.id" />

        <img :src="image.url" class="max-h-[240px]" />

        <input type="text" name="description" :value="image.description" required />

        <div class="flex gap-4">
          <button type="submit" class="flex items-center gap-1">
            <Icon icon="wc-carbon:save" />
            Update
          </button>

          <button type="button" class="flex items-center gap-1 text-error" @click.prevent="removeImage(image)">
            <Icon icon="wc-carbon:delete" />
            Remove
          </button>
        </div>
      </form>

      <form class="flex max-w-[240px] flex-col justify-end gap-2" @submit.prevent="uploadImage">
        <input name="image" type="file" accept="image/png, image/jpg, image/jpeg" required />

        <label>
          Description
          <input type="text" name="description" required />
        </label>

        <button type="submit" class="flex items-center gap-1">
          <Icon icon="wc-carbon:upload" />
          Add image
        </button>
      </form>
    </div>
  </section>

  <section v-if="supplier.accountId !== undefined">
    <header class="mt-4 rounded-md bg-neutral-20 p-4">Highlight Projects</header>

    <div class="flex max-w-4xl flex-row gap-2 overflow-x-scroll">
      <form v-for="project in supplier.highlightProjects" :key="project.id" class="w-80" @submit.prevent="updateProject(project)">
        <input type="hidden" name="id" :value="project.id" />

        <label>
          Name
          <input v-model="project.name" type="text" required />
        </label>

        <label>
          Location
          <input v-model="project.subtitle" type="text" required />
        </label>

        <label>
          Description
          <textarea v-model="project.description" rows="10" required />
        </label>

        <div class="flex gap-4">
          <button type="submit" class="flex items-center gap-1">
            <Icon icon="wc-carbon:save" />
            Update
          </button>

          <button type="button" class="flex items-center gap-1 text-error" @click.prevent="removeProject(project)">
            <Icon icon="wc-carbon:delete" />
            Remove
          </button>
        </div>
      </form>

      <form v-if="supplier.highlightProjects.length < 3" class="w-80" @submit.prevent="addProject">
        <label>
          Name
          <input type="text" name="projectName" required />
        </label>

        <label>
          Location
          <input type="text" name="subtitle" required />
        </label>

        <label>
          Description
          <textarea name="description" rows="10" required />
        </label>

        <button type="submit" class="flex items-center gap-1">
          <Icon icon="wc-carbon:save" />
          Add New Project
        </button>
      </form>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed } from "vue"
import _ from "lodash"
import { Icon } from "@iconify/vue"
import { HeroImage, HighlightProject, Supplier } from "@common/models/supplier"
import { WcButton } from "@/components/button"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { useAdminSuppliersStore } from "@/modules/admin/adminSuppliers.state"

const props = defineProps<{
  accountId: number
}>()

const adminAccountsStore = useAdminAccountsStore()
const adminSuppliersStore = useAdminSuppliersStore()

const account = computed(() => adminAccountsStore.getAccountById(props.accountId)!)
const supplier = computed(() => {
  const supplier = adminSuppliersStore.getSupplierByAccountId(props.accountId)
  if (supplier) {
    return _.cloneDeep(supplier)
  } else {
    return new Supplier()
  }
})

const saveSupplier = async () => {
  await adminSuppliersStore.upsertSupplier(props.accountId, supplier.value)
}

const uploadImage = async (e: Event) => {
  const formEl = e.target! as HTMLFormElement
  await adminSuppliersStore.addSupplierImage(props.accountId, formEl.image.files[0], formEl.description.value)
  formEl.reset()
}

const updateImage = async (e: Event) => {
  const formEl = e.target! as HTMLFormElement
  await adminSuppliersStore.updateSupplierImage(props.accountId, formEl.imageId.value, formEl.description.value)
}

const removeImage = async (image: HeroImage) => {
  if (confirm("Remove image?")) {
    await adminSuppliersStore.removeSupplierImage(props.accountId, image.id)
  }
}

const addProject = async (e: Event) => {
  const formEl = e.target! as HTMLFormElement
  await adminSuppliersStore.addSupplierProject(props.accountId, {
    name: formEl.projectName.value,
    subtitle: formEl.subtitle.value,
    description: formEl.description.value,
  } as HighlightProject)
  formEl.reset()
}

const updateProject = async (project: HighlightProject) => {
  await adminSuppliersStore.updateSupplierProject(props.accountId, project)
}

const removeProject = async (project: HighlightProject) => {
  if (confirm("Remove project?")) {
    await adminSuppliersStore.removeSupplierProject(props.accountId, project.id)
  }
}
</script>

<style scoped lang="scss">
input[type="text"],
textarea {
  @apply invalid:border-error;
}

textarea {
  @apply p-2;
}

label span {
  padding: 0;
}
</style>
