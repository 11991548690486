<template>
  <WcDropdown v-model="selectedTimeZone" :options="timeZoneOptions" :inset-label="insetLabel" :required="required" :name="name" :size="size" />
</template>

<script setup lang="ts">
import { computed } from "vue"
import type { InputSize } from "@/components/input"
import type { SelectOption } from "./WcDropdown"
import WcDropdown from "./WcDropdown.vue"

const US_TIME_ZONE_OPTIONS = [
  { label: "Etc/GMT+4 (UTC-4)", value: "Etc/Gmt+4" },
  { label: "US/Eastern (UTC-4/UTC-5)", value: "US/Eastern" },
  { label: "Etc/GMT+5 (UTC-5)", value: "Etc/Gmt+5" },
  { label: "US/Central (UTC-5/UTC-6)", value: "US/Central" },
  { label: "Etc/GMT+6 (UTC-6)", value: "Etc/Gmt+6" },
  { label: "US/Mountain (UTC-6/UTC-7)", value: "US/Mountain" },
  { label: "US/Arizona (UTC-7)", value: "US/Arizona" },
  { label: "US/Pacific (UTC-7/UTC-8)", value: "US/Pacific" },
  { label: "Etc/GMT+8 (UTC-8)", value: "Etc/Gmt+8" },
  { label: "US/Alaska (UTC-8/UTC-9)", value: "US/Alaska" },
  { label: "Etc/GMT+9 (UTC-9)", value: "Etc/Gmt+9" },
  { label: "US/Hawaii (UTC-10)", value: "US/Hawaii" },
]

const props = withDefaults(
  defineProps<{
    additionalOptions?: SelectOption<string>[]
    insetLabel?: string
    required?: boolean
    name: string
    size?: InputSize
  }>(),
  {
    required: false,
  }
)

const selectedTimeZone = defineModel<SelectOption<string> | null>()

const timeZoneOptions = computed(() => {
  return [...(props.additionalOptions ?? []), ...US_TIME_ZONE_OPTIONS]
})
</script>
