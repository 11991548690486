<template>
  <WcMultiselect
    v-model="model"
    class="max-w-[200px]"
    :name="name"
    :options="groupedListingQuarterOptions"
    header="Dispatch Date"
    all-selected-label="Any date"
    scroll-height="400px">
    <template #value>
      {{ selectedListingQuartersFormatted || "Any date" }}
    </template>
  </WcMultiselect>
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue"
import { WcMultiselect } from "@/components/input"
import { ListingQuarter } from "@common/models/listing"
import _ from "lodash"

// Set up options for the dropdown
const FIRST_QUARTER = new ListingQuarter(2023, 1)
const listingQuarters = ListingQuarter.getAllQuartersUpToCurrent(FIRST_QUARTER)
const listingQuartersGroupedByYear = _.groupBy(listingQuarters, (quarter: ListingQuarter) => quarter.year)
const groupedListingQuarterOptions = Object.entries(listingQuartersGroupedByYear).map(([year, quarters]) => ({
  label: year,
  items: quarters?.map((quarter) => ({
    label: `Q${quarter.quarter}`,
    value: quarter,
  })),
}))

const model = defineModel<ListingQuarter[] | null>()
defineProps<{ name: string }>()

// Format the selected options for display in the MultiSelect button
const selectedListingQuartersFormatted = computed(() => {
  if (!model.value) return null
  const groupedByYear = _.groupBy(model.value, (q) => q.year)
  return Object.entries(groupedByYear)
    .map(([year, quarters]) => {
      if (quarters?.length === 4) return year.toString() // All quarters selected
      const quarterStrings = quarters?.map((quarter) => `Q${quarter.quarter}`).join(", ")
      return `${year} (${quarterStrings})`
    })
    .join(", ")
})
</script>
