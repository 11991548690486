import { defineStore } from "pinia"
import { Site } from "@common/models/site"
import { SiteAsset } from "@common/models/siteAsset"

export const useAccountingStore = defineStore("accountingView", {
  state: () => {
    return {
      sites: new Array<Site>(),
      selectedSiteIds: new Array<number>(),
      selectedAssetIds: new Array<number>(),
      selectedDateFilter: "last12Months", // TODO: find a more error-proof way of setting this, possibly using the DateFilter type declared in AccountingView.vue
    }
  },
  getters: {
    isSiteSelected: (state) => {
      return (siteId: number) => state.selectedSiteIds.includes(siteId)
    },
    isAssetSelected: (state) => {
      return (assetId: number) => state.selectedAssetIds.includes(assetId)
    },
  },
  actions: {
    setSelectedDateFilter(selectedDateFilter: string) {
      this.selectedDateFilter = selectedDateFilter
    },
    resetSelectedDateFilter() {
      this.selectedDateFilter = "last12Months"
    },
    selectDateFilter(dateFilter: string) {
      this.selectedDateFilter = dateFilter
    },
    resetSites() {
      this.selectedSiteIds = new Array<number>()
      this.selectedAssetIds = new Array<number>()
    },
    setSites(selectedSiteIds: number[]) {
      this.selectedSiteIds = selectedSiteIds
    },
    setAssets(selectedAssetIds: number[]) {
      this.selectedAssetIds = selectedAssetIds
    },
    toggleSite(site: Site) {
      const index = this.selectedSiteIds.indexOf(site.id)
      if (index > -1) {
        this.selectedSiteIds.splice(index, 1)
        site.assets.map((a: SiteAsset) => a.id).forEach((id: number) => this.unselectAsset(id))
      } else {
        this.selectedSiteIds.push(site.id)
        site.assets.map((a: SiteAsset) => a.id).forEach((id: number) => this.toggleAsset(id))
      }
    },
    unselectAsset(assetId: number) {
      const index = this.selectedAssetIds.indexOf(assetId)
      if (index > -1) {
        this.selectedAssetIds.splice(index, 1)
      }
    },
    toggleAsset(assetId: number) {
      const index = this.selectedAssetIds.indexOf(assetId)
      if (index > -1) {
        this.selectedAssetIds.splice(index, 1)
      } else {
        this.selectedAssetIds.push(assetId)
      }
    },
  },
})
