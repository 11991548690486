<template>
  <div>
    <div class="text-overline-1 mb-3">
      <slot name="label"></slot>
    </div>
    <div class="text-body-1">
      <slot name="content"></slot>
    </div>
  </div>
</template>
